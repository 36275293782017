import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import  'chartjs-plugin-datalabels';
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/components.js";
//import Typography from '@material-ui/core/Typography';
//import styled, { keyframes } from 'styled-components';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
//import replaceAllInserter from 'string.prototype.replaceall'
//import taxasconsorcio from 'images/taxasconsorcio.png';
//import SmoothImage from 'react-smooth-image';
//import Check from '@material-ui/icons/Check';
//import Close from '@material-ui/icons/Close';
//import Success from "components/Typography/Success.js";
//import CardBody from "components/Card/CardBody.js";
//import Card from "components/Card/Card.js";
//import pricingStyle from "assets/jss/material-kit-react/views/sectionsSections/pricingStyle.js";
//import ListItem from "@material-ui/core/ListItem";
//import List from "@material-ui/core/List";


//import CardFooter from "components/Card/CardFooter.js";
//import Button from "components/CustomButtons/Button.js";
//import NavPills from "components/NavPills/NavPills.js";
//import Danger from "components/Typography/Danger.js";



// @material-ui/icons
//import Favorite from "@material-ui/icons/Favorite";
// core components



// sections for this page






Chart.register(ChartDataLabels);

const useStyles = makeStyles(styles);
//const useStyles2 = makeStyles(pricingStyle);

/*
//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

  */

/*
const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;

*/

export default function ChartRendaFixa({rfResults = {}, value5, value20, value22}){

    const classes = useStyles(styles)
    //const classes2 = useStyles2(pricingStyle)
   
   
    //State do array do labels
    const [chartLabels, setChartLabels] = useState([]);
    const [cdbData, setCdbData] = useState([]);
    const [lcData, setLcData] = useState([]);
    const [tesouroPrefixadoData, setTesouroPrefixadoData] = useState([]);
    const [tesouroSelicData, setTesouroSelicData] = useState([]);
    const [tesouroIPCAData, setTesouroIPCAData] = useState([]);
    const [poupancaData, setPoupancaData] = useState([]);

    /*
    
   // Função para gerar o array de labels com incrementos de 12 até o value5
   const generateLabels = useCallback((maxValue) => {
    let labels = [];
    for (let i = 0; i <= maxValue; i += 12) {
        labels.push(i); // Mantenha os labels numéricos
    }
    return labels;
    }, []);

*/

const valoresRef = useRef({  
    Cdi: 0,
    rentCdi: 0,
    rentLC: 0,
    iPre: 0,
    selic: 0,
    iPlus: 0,
    IPCA: 0,
    iPoupanca: 0,
    tr: 0,
})

  // Atualizar estados quando rfResults mudar
  useEffect(() => {
    valoresRef.current = {
        ...valoresRef.current,
        ...rfResults
    };
  }, [rfResults]);

 

   // Função para calcular as taxas de retorno de todos os produtos financeiros
   const calculateTaxas = useCallback((n) => {

    const {  
        Cdi,
        rentCdi,
        rentLC,
        iPre,
        selic,
        iPlus,
        IPCA,
        iPoupanca,
        tr,
    } = valoresRef.current;

    //Variáveis Input
    const vp = parseFloat(value20); // Valor presente
    const pmt = parseFloat(value22); // Aporte mensal
    const n2 = parseFloat(n * 21); // Número de dias úteis
    const n3 = parseFloat(n * 30); // Número de dias mensais
    //Variáveis InvestimentoTotal
    const investimentoTotal = vp + (pmt * n); // Valor total investido
             
    const calculadorIR = (n <= 6) ? parseFloat(22.5/100) : 
                        (n > 6 && n <= 12 ) ? parseFloat(20/100) : 
                        (n > 12 && n <= 24) ? parseFloat(17.5/100) :
                        (n > 24) ? parseFloat(15/100) :
                        parseFloat(0)


   
 
    //Variáveis Taxas CDI
    const CdiRF = Cdi
    console.log("CDI vamos ver:", CdiRF)
    const iCdiAno =  parseFloat(CdiRF/100)
    console.log("iCDIAno vamos ver:", iCdiAno)
    const iCdiDiario = (Math.pow((1 + iCdiAno), 1/252))-1
    //const iCdiMes = (Math.pow((1 + iCdiDiario), 22))-1
    console.log("iCdiDiario vamos ver:", iCdiDiario )
    

    //Variáveis Taxas CDB
    const rentCdiRF = rentCdi
    console.log("rentCdi   vamos ver:",rentCdiRF )
    const iCdbDiario = iCdiDiario * rentCdiRF
    console.log("iCdbDiario   vamos ver:", iCdbDiario)
    const iCdbMes = (Math.pow((1 + iCdbDiario), 22))-1
    console.log("iCdbMes   vamos ver:", iCdbMes)
    
    //Variáveis resultado CDB
    const resultadoCdb = ((vp*(Math.pow((1 + iCdbDiario), n2))) + pmt*((((Math.pow((1 + iCdbMes), n))-1)/iCdbMes)))
    console.log("resultadoCdb    vamos ver:", resultadoCdb )
    const rendimentoTotalCdb = resultadoCdb-investimentoTotal
    const irCdb = rendimentoTotalCdb * calculadorIR
    const rendimentoLiquidoCdb = rendimentoTotalCdb - irCdb
    const resultadoLiquidoCdb =  rendimentoLiquidoCdb + investimentoTotal
    const taxaRetornoCdb = ((resultadoLiquidoCdb/investimentoTotal)-1)*100
    console.log("resultadoLiquidoCdb  vamos ver:", resultadoLiquidoCdb)
    
    //Variáveis Taxas LC
    const rentLCRF = rentLC
    //const iLciMes = iCdiMes * rentLC
    const iLciDiario = iCdiDiario * rentLCRF
    const iLciMes =  (Math.pow((1 + iLciDiario), 22))-1
    //Variáveis resultado LC
    const resultadoLC = ((vp*(Math.pow((1 + iLciDiario), n2))) + pmt*((((Math.pow((1 + iLciMes), n))-1)/iLciMes)))
    //const rendimentoTotalLC = resultadoLC-investimentoTotal
    const taxaRetornoLC = ((resultadoLC/investimentoTotal)-1)*100



    //Variáveis Taxas Tesouro Direto Prefixado
    //const rentPre = 100/100
   const iPreRF = iPre
    const iPreDiaria = (Math.pow((1 + iPreRF), 1/252))-1
    const iPreMes = (Math.pow((1 + iPreDiaria), 22))-1
    
    //Variáveis resultado Selic
    const resultadoTesouroPrefixado = ((vp*(Math.pow((1 + iPreDiaria), n2))) + pmt*((((Math.pow((1 + iPreMes), n))-1)/iPreMes)))
    const rendimentoTotalTesouroPrefixado = resultadoTesouroPrefixado-investimentoTotal
    const irTesouroPrefixado = rendimentoTotalTesouroPrefixado * calculadorIR
    const rendimentoLiquidoTesouroPrefixado = rendimentoTotalTesouroPrefixado - irTesouroPrefixado
    const resultadoLiquidoTesouroPrefixado =  rendimentoLiquidoTesouroPrefixado + investimentoTotal
    const taxaRetornoTesouroPrefixado = ((resultadoLiquidoTesouroPrefixado/investimentoTotal)-1)*100

    //Variáveis Taxas Tesouro Direto Selic
    //const rentSelic = parseFloat(100/100)
    const selicRF = selic
    const selicNumber = selicRF/100
    const selicDia = (Math.pow((1 + selicNumber), 1/252))-1
    const selicMes = (Math.pow((1 + selicDia), 22))-1
    
    //Variáveis resultado Selic
    const resultadoTesouroSelic = ((vp*(Math.pow((1 + selicDia), n2))) + pmt*((((Math.pow((1 + selicMes), n))-1)/selicMes)))
    const rendimentoTotalTesouroSelic = resultadoTesouroSelic-investimentoTotal
    const irTesouroSelic = rendimentoTotalTesouroSelic * calculadorIR
    const rendimentoLiquidoTesouroSelic = rendimentoTotalTesouroSelic - irTesouroSelic
    const resultadoLiquidoTesouroSelic =  rendimentoLiquidoTesouroSelic + investimentoTotal
    const taxaRetornoTesouroSelic = ((resultadoLiquidoTesouroSelic/investimentoTotal)-1)*100

    //Variáveis Taxas Tesouro Direto IPCA+

 
    const iPlusRF = iPlus
    const IPCARF =  IPCA
    const IPCANumber = IPCARF/100
    const IPCADiario = (Math.pow((1 + IPCANumber), 1/360))-1
    const IPCAMes = (Math.pow((1 + IPCADiario), 30))-1
    const iPlusDiario = (Math.pow((1 + iPlusRF), 1/252))-1
    const iPlusMes = (Math.pow((1 + iPlusDiario), 22))-1
    //const IPCAiPLUSMes = IPCAMes+iPlusMes
    //const IPCAiPLUSDiario = IPCADiario+iPlusDiario
    //const IPCAiPLUS = IPCA + iPlus 

    //Variáveis resultado Selic
    const captizalicaoTesouroIPCAIPlus = ((vp*(Math.pow((1 + iPlusDiario), n2))) + pmt*((((Math.pow((1 + iPlusMes), n))-1)/iPlusMes)))
    const capitalizacaoTesouroIPCAIPCA = ((vp*(Math.pow((1 + IPCADiario ), n3))) + pmt*((((Math.pow((1 + IPCAMes  ), n))-1)/IPCAMes)))
    const resultadoTesouroIPCATotal = (captizalicaoTesouroIPCAIPlus + capitalizacaoTesouroIPCAIPCA)-investimentoTotal 
    const rendimentoTotalTesouroIPCA =  resultadoTesouroIPCATotal-investimentoTotal
    const irTesouroIPCA = rendimentoTotalTesouroIPCA * calculadorIR
    const rendimentoLiquidoTesouroIPCA = rendimentoTotalTesouroIPCA - irTesouroIPCA
    const resultadoLiquidoTesouroIPCA = rendimentoLiquidoTesouroIPCA + investimentoTotal
    const taxaRetornoTesouroIPCA = ((resultadoLiquidoTesouroIPCA/investimentoTotal)-1)*100


  
    // Calcular taxa de retorno Poupança
    //Poupanca = Taxa selic igual ou inferior que 8.5% = 70% da selic
    //           Taxa selic maior que 8.5%, fica 0.5% + TR
  


        const iPoupancaRF = iPoupanca
        const iPoupancaAno = (Math.pow((1 + iPoupancaRF), 12))-1
        const trRF = parseFloat(tr)
        const trNumber = trRF/100
        console.log("tr acumulada de:", tr)
        const iPoupancaValida = selicNumber >= (8.5/100) ? (iPoupancaAno + trNumber) : ((selicRF*parseFloat(0.7))/100)
        const iPoupancaValidaMes = (Math.pow((1 + iPoupancaValida), 1/12))-1
        //Variáveis resultado Poupanca
        const resultadoPoupanca = ((vp*(Math.pow((1 + iPoupancaValidaMes ), n))) + pmt*((((Math.pow((1 + iPoupancaValidaMes ), n))-1)/iPoupancaValidaMes )))
        //const rendimentoTotalPoupanca = resultadoPoupanca - investimentoTotal
        const taxaRetornoPoupanca = ((resultadoPoupanca / investimentoTotal) - 1) * 100;

    return {
        Cdi,
        taxaRetornoCdb,
        taxaRetornoLC,
        taxaRetornoTesouroPrefixado,
        taxaRetornoTesouroSelic,
        taxaRetornoTesouroIPCA,
        taxaRetornoPoupanca,
    };
    }, [value20, value22, value5]);
        
    useEffect(() => {
        // Definir rótulos do eixo X dinamicamente com base no valor do slider (ex: value5)
        const maxPeriod = value5; // valor do slider (3, 6, 12 meses, etc.)
        const newLabels = [];
        
        // Gerar os rótulos de 0 até o valor selecionado no slider
        for (let i = 0; i <= maxPeriod; i += 3) { // Incrementa de 3 em 3 meses
            //newLabels.push(i.toString());
            newLabels.push(`${i} meses`);
        }
        setChartLabels(newLabels);
    
        let newCdbData = [0];  // Inicia com valor 0 para o mês 0
        let newLcData = [0];
        let newTesouroPrefixadoData = [0];
        let newTesouroSelicData = [0];
        let newTesouroIPCAData = [0];
        let newPoupancaData = [0];
    
        // Adicionar os valores até o limite do período selecionado
        for (let i = 3; i <= maxPeriod; i += 3) {
            const {
                taxaRetornoCdb,
                taxaRetornoLC,
                taxaRetornoTesouroPrefixado,
                taxaRetornoTesouroSelic,
                taxaRetornoTesouroIPCA,
                taxaRetornoPoupanca,
            } = calculateTaxas(i);
    
            newCdbData.push(taxaRetornoCdb);
            newLcData.push(taxaRetornoLC);
            newTesouroPrefixadoData.push(taxaRetornoTesouroPrefixado);
            newTesouroSelicData.push(taxaRetornoTesouroSelic);
            newTesouroIPCAData.push(taxaRetornoTesouroIPCA);
            newPoupancaData.push(taxaRetornoPoupanca);
        }
    
        // Atualizar os dados do gráfico
        setCdbData(newCdbData);
        setLcData(newLcData);
        setTesouroPrefixadoData(newTesouroPrefixadoData);
        setTesouroSelicData(newTesouroSelicData);
        setTesouroIPCAData(newTesouroIPCAData);
        setPoupancaData(newPoupancaData);
    
    }, [value5, calculateTaxas]); // Recalcular sempre que o valor do slider mudar
    
    

     let chartfinal = {
          
            chartData: {
                labels: chartLabels, // Usa o state chartLabels
                
                labels1: ['CDB e LC', 'LCI e LCA','Tesouro Prefixado', 'Tesouro Selic', 'Tesouro IPCA+', 'Poupança'],
                            
                datasets: [
                            {   label: 'CDB e LC',
                                data: cdbData, // Usa os dados calculados e formatados para o CDB
                                fill: false,
                                tension: 0.5,
                                //ShowLine: true,
                                backgroundColor: 'rgba(76, 175, 80, 0.6)',
                                borderColor: 'rgba(76, 175, 80, 1)', 
                                //borderWidth: 0,
                                //borderRadius: 0,
                                //barPercentage:0.8,
                                hoverOffset: 8,
                                pointStyle: 'circle',
                                pointRadius: 12,
                                pointHoverRadius: 15
                            },
                            {   label: 'LCI e LCA',
                                data: lcData,
                                fill: false,
                                tension: 0.5,
                                //ShowLine: true,
                                backgroundColor: 'rgba(91, 141, 124, 0.6)',
                                borderColor: 'rgba(91, 141, 124, 1)', 
                                //borderWidth: 0,
                                //borderRadius: 0,
                                //barPercentage:0.8,
                                hoverOffset: 8,
                                pointStyle: 'circle',
                                pointRadius: 12,
                                pointHoverRadius: 15
                            },
                            {   label: 'Tesouro Prefixado ',
                                data: tesouroPrefixadoData,
                                fill: false,
                                tension: 0.5,
                                //ShowLine: true,
                                backgroundColor: 'rgba(0, 172, 193, 0.6)',
                                borderColor: 'rgba(0, 172, 193, 1)', 
                                //borderWidth: 0,
                                //borderRadius: 0,
                                //barPercentage:0.8,
                                hoverOffset: 8,
                                pointStyle: 'circle',
                                pointRadius: 12,
                                pointHoverRadius: 15
                            },
                            {   label: 'Tesouro Selic',
                                data: tesouroSelicData,
                                fill: false,
                                tension: 0.5,
                                //ShowLine: true,
                                backgroundColor: 'rgba(96, 213, 227, 0.6)',
                                borderColor: 'rgba(96, 213, 227, 1)', 
                                //borderWidth: 0,
                                //borderRadius: 0,
                                //barPercentage:0.8,
                                hoverOffset: 8,
                                pointStyle: 'circle',
                                pointRadius: 12,
                                pointHoverRadius: 15
                            },
                              { label: 'Tesouro IPCA+',
                                data: tesouroIPCAData,
                                fill: false,
                                tension: 0.5,
                                //ShowLine: true,
                                backgroundColor: 'rgba(173, 211, 215, 0.6)',
                                borderColor: 'rgba(173, 211, 215, 1)', 
                                //borderWidth: 0,
                                //borderRadius: 0,
                                //barPercentage:0.8,
                                hoverOffset: 8,
                                pointStyle: 'circle',
                                pointRadius: 12,
                                pointHoverRadius: 15
                            },
                            {   label: 'Poupança',
                                data: poupancaData,
                                fill: false,
                                tension: 0.5,
                                borderDash: [3, 3],
                                //ShowLine: true,
                                backgroundColor: 'rgba(153, 153, 153, 0.6)',
                                borderColor: 'rgba(153, 153, 153, 1)', 
                                //borderWidth: 0,
                                //borderRadius: 0,
                                //barPercentage:0.8,
                                hoverOffset: 8,
                                pointStyle: 'circle',
                                pointRadius: 12,
                                pointHoverRadius: 15
                            }                               
                        ]
            }   
    }
    //const plugins = { pluginsFinal: [ChartDataLabels] }
   
    const options = {
            type:'line',
            config: {
                
                plugins: {legend: {display: true}, datalabels:{font:{size:'8.5rem'}, color:"#fff", formatter: function(value, context){ return value.toFixed(0).replace(".", ",") + '%'}},
                    tooltip: {
                        backgroundColor:'rgba(153, 153, 153, 1)',
                        borderColor:'rgba(153, 153, 153, 1)',
                        borderWidth:'2',
                        callbacks: {
                            label: function(context) {
                                const label = chartfinal.chartData.datasets[context.datasetIndex].label;
                                const value = context.raw.toFixed(2).replace(".", ","); // O valor do ponto atual
                                return `${label}: ${value}%`;
                            },

                            title: function(context) {
                                if (context && context[0] && context[0].label) {
                                    console.log(context[0].label);
                                    const labelString = String(context[0].label);  // Convert label to string
                                    return `${labelString.replaceAll(',', ' ')}`;
                                } else {
                                    console.log('Label is undefined');
                                    return '';
                                }
                                
                            },
                            //footer: footer,
                            },
                            labelColor: function(context) {
                                return {
                                    borderColor: context.dataset.borderColor,
                                    backgroundColor: context.dataset.backgroundColor
                                };
                            },
                    },

                },
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: { animateScroll: true, duration:2000, easing:'easeInQuart', delay:50},
                animationDelay: true,
               
              /* scales: {  x: { grid: {display: false,} },
                         y: { ticks: { beginAtZero: true, display: false, callback: (value) => `${value}%`,  gridLines: { display: true, drawBorder: true } }} },


                         */
                scales: {
                x: { grid: { display: false } },
                y: {
                    ticks: { beginAtZero: true, display: true, callback: (label) => `${label}%` }
                }
                        }
            
                        }
    }


    
    return (   
       
      <GridContainer className={classes.container} style={{display: "flex", justifyContent: "center"}}>
          <GridItem>            
            <div style={{display: "flex", justifyContent: "center"}}>
                <div className={classes.chartSimulacaoRendaFixa}>
                    <Line data={chartfinal.chartData} options={options.config} ></Line>
                </div>
            </div>
          </GridItem>
      </GridContainer> 
      

    );
}


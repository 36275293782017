import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";
// nodejs library to set properties for components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//Typography
import Typography from '@material-ui/core/Typography';


//import Fittidegrade from 'views/Components/Sections/Fittidegrade.js';


//Rotas das imagens para mudar o switch
import { Switch, Route } from 'react-router-dom'

//Icons
//import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

import styled, { keyframes } from 'styled-components';
import {animateScroll as scroll} from 'react-scroll';
import * as Scroll from 'react-scroll'
//Badges dos cards
import Badge from "components/Badge/Badge.js";
//Politicas
import PoliticadeP from "./PoliticadeP"
import PoliticadeSeg from "./PoliticadeSeg"


//Logos SVG
import AppstoreSVG from "./AppstoreSVG.jsx";
import GoogleAppStoreSVG from "./GoogleAppStoreSVG.jsx";

//Link
import { Link } from 'react-router-dom'

//Imagens
import LogoContaDigital from 'images/logofitticontadigital.svg';
import LogoFittiConsorcios from 'images/logofitticonsorcios.svg';
import LogoFittiEmprestimos from 'images/logofittiemprestimos.svg';

import LogoFittiSeguros from 'images/logofittiseguros.svg';
import LogoFittiCambio from 'images/logofitticambio.svg';
import LogoFittiShopping from 'images/logofittishopping.svg';

import LogoFittiPagamentos from 'images/logofittipagamentos.svg';
import LogoFittiInvestimentos from 'images/logofittiinvestimentos.svg';
import LogoFittiSaude from 'images/logofittisaude.svg';
import LogoFittiValeBeneficios from 'images/logofittivalebeneficios.svg';



const useStyles = makeStyles(styles);
const FooterScreen = styled.div`
  height: "100%";
  width: "100%";
`;
const Fittideg = styled.div`
  background: linear-gradient(
    90deg,
    rgba(1, 100, 125, 1) 0%,
    rgba(149, 200, 63,1) 100%
  );
  height: 100%;
  width: 100%;
`;
//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
  animation-delay: 1.5s;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);
  animation: ${float} 6s ease-in-out infinite;
  `;


  

export default function Footer2(props) { 

  let LinkScroll = Scroll.Link;
  const classes = useStyles(styles);

    const phoneNumber = '31988980018';
    const handleOpenWhatsApp = () => {
      const url = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
      window.open(url, '_blank');
    };

    const handleOpenGooglePlay = () => {
      const url2 = `https://play.google.com/store/apps/details?id=com.br.GeraTech.Genial.NovaPlataforma`;
      window.open(url2, '_blank');
    };

    const handleOpenAppStore = () => {
      const url3 = `https://apps.apple.com/br/app/genial-investimentos/id1501881719`;
      window.open(url3, '_blank');
    };



  return (
    <FooterScreen>
      <Fittideg>
       <GridContainer className={classes.container}> 
        <GridItem xs={12} sm={8} md={4} style={{ marginTop:'50px'}}>
                  <div>
                  <Switch>
                  <Route path="/emprestimos">
                        <Link to="/emprestimos" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiEmprestimos}
                                style={{ width:'250px', align:"left", marginRight:'10px', marginBottom:'10px', cursor:"pointer"}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/seguros">
                        <Link to="/seguros" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiSeguros}
                                style={{ width:'250px', align:"left", marginRight:'10px', marginBottom:'10px', cursor:"pointer"}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/cambio">
                        <Link to="/cambio" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiCambio}
                                style={{ width:'250px', align:"left", marginRight:'10px', marginBottom:'10px', cursor:"pointer"}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/consorcios">
                            <Link to="/consorcios" onClick={() => scroll.scrollToTop()}>
                                <img
                                    src={LogoFittiConsorcios}
                                    style={{ width:'250px', align:"left", marginRight:'10px', marginBottom:'10px', cursor:"pointer"}} alt='Logo'>
                                </img>
                            </Link>
                    </Route>
                    <Route path="/shopping">
                        <Link to="/shopping" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiShopping}
                                style={{ width:'250px', align:"left", marginRight:'10px', marginBottom:'10px', cursor:"pointer"}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/pagamentos">
                        <Link to="/pagamentos" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiPagamentos}
                                style={{ width:'250px', align:"left", marginRight:'10px', marginBottom:'10px', cursor:"pointer"}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/investimentos">
                        <Link to="/investimentos" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiInvestimentos}
                                style={{ width:'250px', align:"left", marginRight:'10px', marginBottom:'10px', cursor:"pointer"}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/fittisaude">
                        <Link to="/fittisaude" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiSaude}
                                style={{ width:'250px', align:"left", marginRight:'10px', marginBottom:'10px', cursor:"pointer"}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                    <Route path="/fittivale">
                        <Link to="/fittivale" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiValeBeneficios}
                                style={{ width:'250px', align:"left", marginRight:'10px', marginBottom:'10px', cursor:"pointer"}} alt='Logo'>
                            </img>
                            
                        </Link>
                    </Route>
                      <Route path="/">
                        <Link to="topo" onClick={() => scroll.scrollToTop()}>
                            <img
                                src={LogoFittiInvestimentos}
                                style={{ width:'250px', align:"left", marginRight:'10px', marginBottom:'10px', cursor:"pointer"}} alt='Logo'>
                            </img>                          
                        </Link>
                    </Route>
                        <Route path="/contaDigital">
                            <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                                <img
                                    src={LogoContaDigital}
                                    style={{ width:'250px', align:"left", marginRight:'10px', marginBottom:'10px', cursor:"pointer"}} alt='Logo'>
                                </img>
                              </LinkScroll>
                        </Route>
                      </Switch>
                  </div>
                  
                  
                  <Switch>
                  <Route path="/emprestimos">
                          <Typography  className={classes.footerMiniText}  color="initial">
                              O Fitti Empréstimos é um serviço provido por Fitti Pagamentos, onde você encontra e contrata diversas modalidades de Empréstimos, através das nossas plataformas Web e Mobile. O Fitti Empréstimos atua em regime de correspondente bancário, juntamente á Instituições Financeiras parceiras e demais Instituições Financeiras fiscalizadas e autorizadas pelo Banco Central. 
                          </Typography>
                      </Route>
                      <Route path="/consorcios">
                          <Typography  className={classes.footerMiniText}  color="initial">
                              O Fitti Consórcios é um serviço provido por Fitti Pagamentos, onde você encontra e contrata diversas modalidades de Consórcios, através das nossas plataformas Web e Mobile. O Fitti Consórcios atua em regime de correspondente bancário, juntamente á Administradoras de Consórcios parceiras e demais Instituições Financeiras fiscalizadas e autorizadas pelo Banco Central. 
                          </Typography>
                      </Route>
                      <Route path="/investimentos">
                          <Typography  className={classes.footerMiniText}  color="initial">
                            O Fitti Investimentos atua em regime de Assessoria de Investimentos devidamente registrado na CVM - Comissão de Valores Mobiliários, na forma da Resolução CVM 178, atuando no mercado financeiro como preposto da GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILIARIOS S.A e como correspondente bancário de Instituições Financeiras Nacionais e Internacionais.
                          </Typography>
                        </Route>
                        <Route path="/contaDigital">
                          <Typography  className={classes.footerMiniText}  color="initial">
                              A Conta Digital Fitti é um serviço provido por Fitti Pagamentos, onde você encontrará diversos produtos como empréstimos, seguros, investimentos, câmbio, consórcios, shopping, meios de pagamentos, benefícios e serviços de saúde, além de poder realizar movimentações, transferências, compras e pagamentos. 
                          </Typography>
                        </Route>
                        <Route path="/">
                          <Typography  className={classes.footerMiniText}  color="initial">
                               O Fitti Investimentos atua em regime de Assessoria de Investimentos devidamente registrado na CVM - Comissão de Valores Mobiliários, na forma da Resolução CVM 178, atuando no mercado financeiro como preposto da GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILIARIOS S.A como correspondente bancário de Instituições Financeiras Nacionais e Internacionais.
                          </Typography>
                        </Route>
                      </Switch>
                  
                  <div style={{ marginTop:'20px'}}>
                    <List className={classes.listIconFoot}>
                      <ListItem className={classes.listItemIconFoot}>
                        <Tooltip
                          id="instagram-tooltip"
                          title="Siga-nos no Instagram."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button
                              color="transparent"
                              href="https://www.instagram.com/fittiinvestimentos/"
                              target="_blank"
                              className={classes.footerIcon}>
                              <i className={classes.socialIcons + " fab fa-instagram"} />
                          </Button>
                        </Tooltip>
                      </ListItem>
                      <ListItem className={classes.listItemIconFoot}>
                        <Tooltip
                          id="facebook-tooltip"
                          title="Siga-nos no Facebook."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button
                            color="transparent"
                            href="https://www.facebook.com/fittiinvestimentos"
                            target="_blank"
                            className={classes.footerIcon}>
                            <i className={classes.socialIcons  + " fab fa-facebook"} />
                          </Button>
                        </Tooltip>
                      </ListItem>
                      <ListItem className={classes.listItemIconFoot}>
                        <Tooltip
                          id="youtube-tooltip"
                          title="Siga-nos no Youtube."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button
                            color="transparent"
                            href="https://www.youtube.com/channel/UCx4v63fUvC5UWZW_8pq80gg"
                            target="_blank"
                            className={classes.footerIcon}>
                            <i className={classes.socialIcons  + " fab fa-youtube"} />
                          </Button>
                        </Tooltip>
                      </ListItem>
                      <ListItem className={classes.listItemIconFoot}>
                        <Tooltip
                          id="linkedin-tooltip"
                          title="Siga-nos no LinkedIn."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button
                            color="transparent"
                            href="https://www.linkedin.com/company/fittiinvestimentos/"
                            target="_blank"
                            className={classes.footerIcon}>
                            <i className={classes.socialIcons  + " fab fa-linkedin"} />
                          </Button>
                        </Tooltip>
                      </ListItem>
                      <ListItem className={classes.listItemIconFoot}>
                        <Tooltip
                          id="twitter-tooltip"
                          title="Siga-nos no Twitter."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button
                            color="transparent"
                            href="https://twitter.com/fittiinvest"
                            target="_blank"
                            className={classes.footerIcon}>
                            <i className={classes.socialIcons  + " fab fa-twitter"} />
                          </Button>
                        </Tooltip>
                      </ListItem>
                    </List>
                  </div>
                  <div style={{ marginTop:'20px'}}>
                    <List className={classes.listIconFoot2}>
                      <ListItem>
                        <Tooltip
                          id="GooglePlay-tooltip"
                          title="Baixe nosso App no App Store"
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button
                              color="transparent"
                              href="#"
                              target=""
                              onClick = {() => {handleOpenAppStore()}}
                             >
                         <AppstoreSVG></AppstoreSVG>
                          </Button>
                        </Tooltip>
                      </ListItem>
                      <ListItem className={classes.listIconFoot2}>
                        <Tooltip
                          id="App Store-tooltip"
                          title="Baixe nosso App no Google Play"
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button style={{paddingLeft:'10px'}}
                            color="transparent"
                            href="#"
                            onClick = {() => {handleOpenGooglePlay()}}
                            target=""
                            className={classes.IconApps}>
                            <GoogleAppStoreSVG className={classes.IconApps}></GoogleAppStoreSVG>                         
                             </Button>
                        </Tooltip>
                      </ListItem>
                    </List>
                  </div>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} style={{ marginTop:'50px', }}>
                <Animation1>
                  <Typography className={classes.footerTitleText}  color="initial"> 
                    <i className={classes.socialIcons  + " fa fa-caret-right"} />PRODUTOS
                  </Typography> 
                </Animation1>
                <List className={classes.listLinksFoot}>
                      <ListItem className={classes.listItemLinksFoot}>
                        <Tooltip
                          id="investimentos-tooltip"
                          title="Invista em renda fixa e renda variável."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Link style={{textDecoration: "none"}} to ="/investimentos">
                          <Button
                              color="transparent"
                              href="#"
                              className={classes.footerLinks}>
                               <span style={{marginRight:"5px"}}><Badge color="white">Fitti</Badge></span>Investimentos 
                          </Button>
                          </Link>
                        </Tooltip>
                      </ListItem>
                      <ListItem className={classes.listItemLinksFoot}>
                        <Tooltip
                          id="emprestimos-tooltip"
                          title="Solicite o seu Empréstimo."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Link style={{textDecoration: "none"}} to ="/emprestimos">
                          <Button
                              color="transparent"
                              href="#"
                              className={classes.footerLinks}>
                              <span style={{marginRight:"5px"}}><Badge color="white">Fitti</Badge></span>Empréstimos
                          </Button>
                          </Link>
                        </Tooltip>
                      </ListItem>
                        <ListItem className={classes.listItemLinksFoot}>
                        <Tooltip
                          id="seguros-tooltip"
                          title="Realize a cotação do seu seguro."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Link style={{textDecoration: "none"}} to ="/seguros">
                          <Button
                              color="transparent"
                              href="#"
                              className={classes.footerLinks}>
                              <span style={{marginRight:"5px"}}><Badge color="white">Fitti</Badge></span>Seguros
                          </Button>
                          </Link>
                        </Tooltip>
                      </ListItem>
                      <ListItem className={classes.listItemLinksFoot}>
                        <Tooltip
                          id="cambio-tooltip"
                          title="Realize a cotação do seu câmbio."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Link style={{textDecoration: "none"}} to ="/cambio">
                          <Button 
                              color="transparent"
                              href="#"
                              style={{display:"flex", justifyContent:"space-between" }}
                              className={classes.footerLinks}>
                              <span style={{marginRight:"5px"}}><Badge color="white">Fitti</Badge></span>Câmbio
                          </Button>
                          </Link>
                        </Tooltip>
                      </ListItem>
                      <ListItem className={classes.listItemLinksFoot}>
                        <Tooltip
                          id="consórcio-tooltip"
                          title="Adquira o seu consórcio."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Link style={{textDecoration: "none"}} to ="/consorcios">
                            <Button
                                color="transparent"
                                href="#"
                                className={classes.footerLinks} style={{display:"flex", justifyContent:"space-between" }}> 
                                <span style={{marginRight:"5px"}}><Badge color="white">Fitti</Badge></span>Consórcios
                            </Button>
                          </Link> 
                        </Tooltip>
                      </ListItem>
                      <ListItem className={classes.listItemLinksFoot}>
                        <Tooltip
                          id="shopping-tooltip"
                          title="Realize compras com cashback."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Link style={{textDecoration: "none"}} to ="/shopping">
                          <Button
                              color="transparent"
                              href="#"
                              className={classes.footerLinks}>
                              <span style={{marginRight:"5px"}}><Badge color="white">Fitti</Badge></span>Shopping
                          </Button>
                          </Link>
                        </Tooltip>
                      </ListItem>
                  </List>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} style={{ marginTop:'50px'}}>
                <Animation1>
                    <Typography className={classes.footerTitleText}  color="initial"> 
                      <i className={classes.socialIcons  + " fa fa-caret-right"} />AJUDA
                    </Typography> 
                </Animation1>
                <List className={classes.listLinksFoot}>
                      <ListItem className={classes.listItemFonesFoot}>
                          <Typography className={classes.titleFonesMin}> 
                            ATENDIMENTO COMERCIAL
                          </Typography>
                        <Tooltip
                          id="telefonar-tooltip"
                          title="Entre em contato através do nosso atendimento pelo Whatsapp."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button
                              color="transparent"
                              onClick={handleOpenWhatsApp}
                              className={classes.titleFonesMax}>
                              +31 9 8898-0018  <i className={classes.socialIcons  + " fab fa-whatsapp"} />
                          </Button>
                        </Tooltip> 
                        <Tooltip
                          id="telefonar-tooltip"
                          title="Telefonar para o nosso Comercial."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button
                              color="transparent"
                              href="tel:3136547462"
                              className={classes.titleFonesMax}>
                              +31 3654-7462
                          </Button>
                          </Tooltip> 
                      </ListItem>
                      <ListItem className={classes.listItemFonesFoot2}>
                        <Typography className={classes.titleFonesMin}> 
                              SAC
                        </Typography>
                        <Tooltip
                          id="telefonar-tooltip"
                          title="Entre em contato através do nosso atendimento pelo Whatsapp."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button
                              color="transparent"
                              onClick={handleOpenWhatsApp}
                              className={classes.titleFonesMax}>
                              +31 9 8898-0018  <i className={classes.socialIcons  + " fab fa-whatsapp"} />
                          </Button>
                        </Tooltip> 
                        <Tooltip
                          id="telefonar-tooltip"
                          title="Telefonar para o nosso SAC."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button
                              color="transparent"
                              href="tel:36547462"
                              className={classes.titleFonesMax}>
                              +31 3654-7462
                          </Button>
                        </Tooltip>
                        <Tooltip
                          id="enviaremail-tooltip"
                          title="Enviar email para o nosso SAC."
                          placement={window.innerWidth > 959 ? "top" : "left"}
                          classes={{ tooltip: classes.tooltip }}>
                          <Button
                              color="transparent"
                              href="Mailto:sac@fittiinvest.com.br"
                              className={classes.titleFonesMax}>
                              contato@fittiinvest.com.br
                          </Button>
                        </Tooltip>
                      </ListItem>
                  </List>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.container} style={{ marginTop:'50px'}}> 
        <GridItem xs={12} sm={12} md={8}>
                  <Typography  className={classes.footerMiniTextrodape}  color="initial">
                      © Fitti Investimentos - LTDA - CNPJ: 52.915.262/0001-80 e © Fitti Pagamentos - Eireli -  CNPJ: 24.341.342/0001-33 - Condomínio Edifício Parque Avenida, Av. Raja Gabáglia, 2000 - Torre 1 - Cidade Jardim, Belo Horizonte - MG, CEP: 30494-170
                  </Typography>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} className={classes.GridPolices}>
                  <Typography style={{display:"flex", justifyContent:"center", color:'white'}}>
                    <PoliticadeP></PoliticadeP>  |  <PoliticadeSeg></PoliticadeSeg> 
                  </Typography> 
        </GridItem>
      </GridContainer>
    </Fittideg>
  </FooterScreen>
  );
}
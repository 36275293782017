import React, {useEffect} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
import styled, { keyframes } from 'styled-components'
import personafitti from 'images/personafitti.png'
//import styles from "assets/jss/material-kit-react/views/components.js";
import { makeStyles } from "@material-ui/core/styles";
//import Slide from '@material-ui/core/Slide';
//import { Fade } from '@material-ui/core';
import SmoothImage from 'react-smooth-image';
import Typography from '@material-ui/core/Typography';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBodyPro.js";
//import CardFooter from "components/Card/CardFooterPro.js";
import Card from "components/Card/CardPro.js";
import styles from "assets/jss/material-kit-react/views/components.js";

// @material-ui icons
//import Favorite from "@material-ui/icons/Favorite";
//import Share from "@material-ui/icons/Share";
//import avatar from "assets/img/faces/avatar.jpg";
//import cardsStyle from "assets/jss/material-kit-react/views/componentsSections/sectionCards.js";

import ShoppingCart from "@material-ui/icons/ShoppingCart";
import AttachMoney from "@material-ui/icons/AttachMoney";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import BarChartIcon from '@material-ui/icons/BarChart';
//import PaymentIcon from '@material-ui/icons/Payment';
import BeenhereIcon from '@material-ui/icons/Beenhere';
//import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
//import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
//import FastfoodIcon from '@material-ui/icons/Fastfood';
import MemoryIcon from '@mui/icons-material/Memory' 
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings' 


/* const style = {
  ...cardsStyle
}; */

const useStyles = makeStyles(styles);

//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;


export default _=> {

  const classes = useStyles(styles)

//Animação de entrada dos itens com o scroll
useEffect(() => {
    Aos.init({duration: 1500});
  }, []); window.addEventListener('load', function() {
    Aos.refresh();
  });
  

return (
<div className={classes.sectionTop2}>
<GridContainer className={classes.container} >
        <div className={classes.container2}>
            <Animation1>
                  <Typography className={classes.titleanimatedbuttons2}>
                    Uma plataforma completa com diversos
                  </Typography>
                   <Typography className={classes.titleanimatedbuttons21}>
                      serviços para contratar
                  </Typography>  
            </Animation1>
        </div>
        <GridItem xs={12} sm={12} md={3} className={classes.griditemcard}>
         <div>
         
            <div  data-aos="fade-left">
              <SmoothImage containerStyles={{height:"250px", overflow:"visible", paddingBottom:"0" }} imageStyles={{position:"relative", width: "auto", height: "900px", overflow:"visible",  marginTop:"3%", marginBottom:"10%",  paddingBottom:"0" }}
                  src={personafitti} alt='Moça mexendo no celular' transitionTime={1.0}  transitionTimeFunction={"ease-in"}>
              </SmoothImage> 
            </div>
         
         </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} className={classes.griditemcard} >
        <div>
          <Typography className={classes.titleanimatedbuttons3}  data-aos="fade-left">
              Vários produtos e serviços <br/>
              em um só lugar.
          </Typography> 
          <Typography className={classes.titleanimatedbuttons4}  data-aos="fade-left">
              Serviços de A a Z.
          </Typography> 
        </div>
        </GridItem>
        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"flex-end"}}>
     <GridItem xs={12} sm={6} md={3} className={classes.griditemcard} /* data-aos="fade-up" */ >
        <Card color="graynovo"  className={classes.cardz}>
            <CardBody color>
                <BarChartIcon style={{ width:"auto", height:"40px", color:"#3f3f3f" }}>
                </BarChartIcon>
                <Typography style={{color:"#3f3f3f", fontSize:"1.4rem", fontWeight:"initial", marginTop:"8px"}}>
                     Investimentos
                </Typography> 
                <Typography style={{color:"#3f3f3f", fontSize:"0.8rem", fontWeight:"1", marginTop:"8px"}}>
                    Deseja aplicar o seu dinheiro em renda fixa ou renda variável? Através das nossas plataformas digitais (Web e pelo App), você poderá realizar os seus investimentos.
                </Typography> 
            </CardBody>    
         </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} className={classes.griditemcard} /* data-aos="fade-up" */ >
        <Card color="graynovo"  className={classes.cardz}>
            <CardBody color>
                <MemoryIcon style={{ width:"auto", height:"40px", color:"#3f3f3f" }}>
                </MemoryIcon>
                <Typography style={{color:"#3f3f3f", fontSize:"1.4rem", fontWeight:"initial", marginTop:"8px"}}>
                     Interligência Artificial
                </Typography> 
                <Typography style={{color:"#3f3f3f", fontSize:"0.8rem", fontWeight:"1", marginTop:"8px"}}>
                    Realize consultas e seja Assessorado com a IA. Utilize a nossa inteligência artificial para realizar consultas.
                </Typography> 
            </CardBody>    
         </Card>
        </GridItem> 
        <GridItem xs={12} sm={6} md={3} className={classes.griditemcard} /* data-aos="fade-up" */ >
        <Card color="graynovo"  className={classes.cardz}>
            <CardBody color>
                <DisplaySettingsIcon  style={{ width:"auto", height:"40px", color:"#3f3f3f"  }}>
                </DisplaySettingsIcon>
                <Typography style={{color:"#3f3f3f", fontSize:"1.4rem", fontWeight:"initial", marginTop:"8px"}}>
                     Simuladores
                </Typography> 
                <Typography style={{color:"#3f3f3f", fontSize:"0.8rem", fontWeight:"1", marginTop:"8px"}}>
                      Simule ganhos futuros e possibilidades passadas. Utilize nossos simuladores para obter informações relevantes para os seus investimentos.
                </Typography> 
            </CardBody>    
         </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} className={classes.griditemcard}>
        <Card color="graynovo"  className={classes.cardz}>
            <CardBody color>
                <MonetizationOnIcon style={{ width:"auto", height:"40px", color:"#3f3f3f"  }}>
                </MonetizationOnIcon>
                <Typography style={{color:"#3f3f3f", fontSize:"1.4rem", fontWeight:"initial", marginTop:"8px"}}>
                     Empréstimos
                </Typography> 
                <Typography style={{color:"#3f3f3f", fontSize:"0.8rem", fontWeight:"1", marginTop:"8px"}}>
                    Sabe aquela ajuda nos momentos de aperto? Através das nossas plataformas digitais (Web e pelo App), você poderá solicitar o seu empréstimo.
                </Typography> 
            </CardBody>    
         </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} className={classes.griditemcard}>
        <Card color="graynovo"  className={classes.cardz}>
            <CardBody color>
                <BeachAccessIcon style={{ width:"auto", height:"40px", color:"#3f3f3f" }}>
                </BeachAccessIcon>
                <Typography style={{color:"#3f3f3f", fontSize:"1.4rem", fontWeight:"initial", marginTop:"8px"}}>
                     Seguros
                </Typography> 
                <Typography style={{color:"#3f3f3f", fontSize:"0.8rem", fontWeight:"1", marginTop:"8px"}}>
                    Sabe aquele momento que a gente nunca espera que aconteça? E se...? Contrate o seu seguro rápido e fácil, através das nossas plataformas digitais (Web e pelo App).
                </Typography> 
            </CardBody>    
         </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} className={classes.griditemcard}>
        <Card color="graynovo" className={classes.cardz}>
            <CardBody color>
                <AttachMoney style={{ width:"auto", height:"40px", color:"#3f3f3f" }}>
                </AttachMoney>
                <Typography style={{color:"#3f3f3f", fontSize:"1.4rem", fontWeight:"initial", marginTop:"8px"}}>
                     Câmbio
                </Typography> 
                <Typography style={{color:"#3f3f3f", fontSize:"0.8rem", fontWeight:"1", marginTop:"8px"}}>
                    Planejando fazer aquela viagem internacional dos sonhos e precisa comprar moeda estrangeira? Compre mais de 20 moedas através das nossas plataformas digitais (Web e pelo App).
                </Typography> 
            </CardBody>    
         </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} className={classes.griditemcard}>
        <Card color="graynovo"  className={classes.cardz}>
            <CardBody color>
                <BeenhereIcon style={{ width:"auto", height:"40px", color:"#3f3f3f"  }}>
                </BeenhereIcon>
                <Typography style={{color:"#3f3f3f", fontSize:"1.4rem", fontWeight:"initial", marginTop:"8px"}}>
                     Consórcios
                </Typography> 
                <Typography style={{color:"#3f3f3f", fontSize:"0.8rem", fontWeight:"1", marginTop:"8px"}}>
                    Pensando em maneiras acessíveis de adquirir um bem móvel, imóvel ou serviço? Através das nossas plataformas digitais (Web e pelo App), você poderá contratar o seu consórcio.
                </Typography> 
            </CardBody>    
         </Card>
        </GridItem>
         <GridItem xs={12} sm={6} md={3} className={classes.griditemcard}>
        <Card color="graynovo"  className={classes.cardz}>
            <CardBody color>
                <ShoppingCart style={{ width:"auto", height:"40px", color:"#3f3f3f"  }}>
                </ShoppingCart>
                <Typography style={{color:"#3f3f3f", fontSize:"1.4rem", fontWeight:"initial", marginTop:"8px"}}>
                     Shopping
                </Typography> 
                <Typography style={{color:"#3f3f3f", fontSize:"0.8rem", fontWeight:"1", marginTop:"8px"}}>
                    Gostaria de realizar compras e ainda ganhar cashback? Através das nossas plataformas digitais (Web e pelo App), você poderá ganhar dinheiro de volta em cada compra que efetuar.
                </Typography> 
            </CardBody>    
         </Card>
        </GridItem>
      </div>
        
</GridContainer>
</div>
  );
}

import React, { Component, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import HorizontalScroll from "react-scroll-horizontal";
//import Button from "components/CustomButtons/Button.js";
//import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
//import Check from "@material-ui/icons/Check";
// core components
//import Table from "components/Tables/Table";

//import CardFooter from "components/Card/CardFooterPro.js";


//import style from "assets/jss/material-kit-react/views/componentsSections/contentAreas.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import style from "assets/jss/material-kit-react/views/components.js";
import Typography from '@material-ui/core/Typography';
//import styled, { keyframes } from 'styled-components';
//import Car from "@material-ui/icons/DirectionsCarOutlined";
//import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
//import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
//import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
//import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
//import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
//import ListItem from "@material-ui/core/ListItem";
//import List from "@material-ui/core/List";
//import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
//import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
//import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
//import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';

//import styles from "assets/jss/material-kit-react/views/components.js";
//import { makeStyles } from "@material-ui/core/styles";
//import Slide from '@material-ui/core/Slide';
//import EmprestimoCarHomeEquity from 'images/emprestimohomeequity.png'
//import EmprestimoHomeEquitySolo from 'images/homeEquitySolo.png'

import { Fade } from '@material-ui/core';
import SmoothImage from 'react-smooth-image';
import EmprestimoCarHomeEquity2 from 'images/homeEquitySolo.png'

//Botão de escolha Seleção
//import InputLabel from '@mui/material/InputLabel';
//import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
//import Select from '@mui/material/Select';
import Button from "components/CustomButtons/Button.js";

//import videoImac from 'videos/clipeImac.mp4';
//import ReactPlayer from 'react-player/lazy';

//import Check from '@material-ui/icons/Check';
//import Badge from "components/Badge/Badge.js";
import ChatBot from 'react-simple-chatbot';
import PropTypes from 'prop-types';
import Footer2 from "components/Footer/Footer2";
import PoliticadePobsSimulacao from "components/Footer/PoliticadePobsSimulacao.jsx"
import { ThemeProvider } from 'styled-components';



//import dayjs from 'dayjs';
//import TextField from '@mui/material/TextField';
//import Stack from '@mui/material/Stack';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
//import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

// react plugin for creating date-time-picker
import Datetime from "react-datetime";
// @material-ui/core components
import "react-datetime/css/react-datetime.css";
//import '../../../src/assets/scss/plugins/_plugin-react-datetime.scss'
//import CustomInput from 'material-kit-react/...;
import CustomInput2 from "components/CustomInput/CustomInput2";
import moment from "moment";
//import { listeners } from "gulp";


//import SyntaxHighlighter from 'react-syntax-highlighter/prism';
//import { prism } from 'react-syntax-highlighter/styles/prism';
// material-ui components

import FormControl1 from "@material-ui/core/FormControl";
import InputLabel1 from "@material-ui/core/InputLabel";
import Select1 from "@material-ui/core/Select";
import MenuItem1 from "@material-ui/core/MenuItem";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";

import styles1 from "assets/jss/material-kit-react/CustomSelectStylePro.js";

//import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
/*import props from './../Effects/Typewrite';
import { CustomInput } from 'components/CustomInput/CustomInput.js';



//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;

*/
const theme = {
    background: "#f5f8fb",
    fontFamily: "Roboto",
    headerBgColor: "#4caf50",
    headerFontColor: "#fff",
    headerFontSize: "16px",
    botBubbleColor: "#4caf50",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
    cursor: "pointer"
};


const useStyles = makeStyles(style);
const useStyles1 = makeStyles(styles1);



class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };
  }

  componentWillMount() {
    const { steps } = this.props;
    const { email } = steps;

    this.setState({ email });
  }

  render() {
    const { email } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <h3>Resumo do cadastro:</h3>
        <table>
          <tbody>
            <tr>
              <td>Email:</td>
              <td>{email.value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

Review.propTypes = {
  steps: PropTypes.object,
};

Review.defaultProps = {
  steps: undefined,
};


const ChatContext = React.createContext("");

  function FirstNameSaver(props) {
    return (
      <ChatContext.Consumer>
        {({ firstName, setFirstName }) => {
          setFirstName(props.previousStep.value);
          return `Olá ${firstName}!`;
        }}
      </ChatContext.Consumer>
    );
  }



export default function Chat() {

  const classes = useStyles(style);
  const classes1 = useStyles1(styles1);
 
  const [dataNasc, setDataNasc] = useState(null)
  const [firstName, setFirstName] = useState("");
  const [ocPrincipal, setOcPrincipal] = useState("Selecionar");
  const [profissao, setProfissao] = useState("Selecionar");
  
  const handleChange = (event) => {
    setOcPrincipal(event.target.value);
  };

  const handleChange2 = (newDate) => { setDataNasc(newDate) }
 
  const handleChange3 = (event) => {
    setProfissao(event.target.value);
  };


  function DataNascimento({triggerNextStep}, props) {

    const {
      inputProps: { ...restInputProps },
      formControlProps,
      labelText,
      ...restProps
    } = props;

    return (
      <ChatContext.Consumer>
          {({dataNasc, setDataNasc}) => {
            const validacaoOcupacaoPrincipalNasc = () => dataNasc === null ? "" : triggerNextStep({trigger:'dataNascimento'})     
            console.log(dataNasc)
            
         
          return ( 
            <div style={{height:"375px", maxWidth: "100%"}}>
                <FormControl  fullWidth>
                    <Datetime
                      timeFormat={false}
                      inputProps={{placeholder: "Selecione sua data", className:"DataInputTime" }}
                      value={dataNasc}
                      onChange={handleChange2}
                      dateFormat={'DD-MM-YYYY'}
                      locale={'en-gb'}
                      closeOnSelect={false}
                      style={{color:"#fff"}}
                      open={true}
                      renderInput={(dateInputProps, open) => (
                        <CustomInput2 
                          inputProps={{
                            ...dateInputProps,
                            ...restInputProps,
                            onFocus: open,
                            value: dataNasc ? moment(dataNasc).format("DD/MM/YYYY") : "",
                            
                          }}
                          labelText={labelText}
                          formControlProps={formControlProps}>
                        </CustomInput2>
                      )}
                      {...restProps}>
                    </Datetime>
                </FormControl>
              <Button color="success" round onClick={validacaoOcupacaoPrincipalNasc}>
                  Confirmar
              </Button>      
            </div>
          );
        }}
      </ChatContext.Consumer>
    );
  };

  function RespostaDataNascimento() {
    return (
      <ChatContext.Consumer>
          {({ dataNasc, setDataNasc }) => {
     
          return ( 
            <div>
              <div className="rsc-ts rsc-ts-user sc-bsbRJL domqkE">
              <div className="rsc-ts-image-container sc-iQNlJl gUVrcY">
                <img className="rsc-ts-image sc-epnACN EjYuI" src="data:image/svg+xml,%3csvg viewBox='-208.5 21 100 100' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ccircle cx='-158.5' cy='71' fill='%23F5EEE5' r='50'/%3e%3cdefs%3e%3ccircle cx='-158.5' cy='71' id='a' r='50'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse overflow='visible' xlink:href='%23a'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' fill='%23E6C19C'/%3e%3cg clip-path='url(%23b)'%3e%3cdefs%3e%3cpath d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' id='c'/%3e%3c/defs%3e%3cclipPath id='d'%3e%3cuse overflow='visible' xlink:href='%23c'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23d)' d='M-158.5 100.1c12.7 0 23-18.6 23-34.4 0-16.2-10.3-24.7-23-24.7s-23 8.5-23 24.7c0 15.8 10.3 34.4 23 34.4z' fill='%23D4B08C'/%3e%3c/g%3e%3cpath d='M-158.5 96c12.7 0 23-16.3 23-31 0-15.1-10.3-23-23-23s-23 7.9-23 23c0 14.7 10.3 31 23 31z' fill='%23F2CEA5'/%3e%3c/svg%3e" alt="avatar"></img>
              </div>
              <div className="rsc-ts-bubble sc-hXRMBi kscpvO">{dataNasc ? moment(dataNasc).format("DD/MM/YYYY") : ""}</div>
            </div>
          </div>
          );
        }}
      </ChatContext.Consumer>
    );
  };


  function OcupacaoPrincipal1({triggerNextStep}) {
    return (
      <ChatContext.Consumer>
          {({ ocPrincipal, setOcPrincipal }) => {
            const validacaoOcupacaoPrincipal = () => ocPrincipal === "Selecionar" ? "" : triggerNextStep({trigger:'16'})     
            console.log(ocPrincipal)
                  
                      return ( 
                        <div>
                            <FormControl1 fullWidth className={classes1.selectFormControl2}>
                                  <InputLabel1 
                                    variant="standard" 
                                    htmlFor="simple-select" 
                                    className={classes1.selectLabel2}
                                    children= "false" 
                                    color= "primary"
                                    >
                                    Ocupação Principal
                                  </InputLabel1>
                                  <Select1
                                    MenuProps={{className: classes1.selectMenu}}
                                    classes={{select: classes1.select2}}
                                    value={ocPrincipal}
                                    onChange={handleChange}
                                    inputProps={{
                                      name: "simpleSelect",
                                      id: "simple-select"
                                    }}>
                                    <MenuItem1
                                      classes={{
                                        root: classes1.selectMenuItem2,
                                        selected: classes1.selectMenuItemSelected2}}
                                      value="Selecionar">
                                      Selecionar
                                  </MenuItem1>
                                    <MenuItem1
                                      classes={{
                                        root: classes1.selectMenuItem2,
                                        selected: classes1.selectMenuItemSelected2
                                      }}
                                      value="Assalariado Privado">
                                      Assalariado Privado
                                    </MenuItem1>
                                    <MenuItem1
                                      classes={{
                                        root: classes1.selectMenuItem2,
                                        selected: classes1.selectMenuItemSelected2
                                      }}
                                      value="Assalariado Público">
                                      Assalariado Público
                                    </MenuItem1>
                                    <MenuItem1
                                      classes={{
                                        root: classes1.selectMenuItem2,
                                        selected: classes1.selectMenuItemSelected2
                                      }}
                                      value="Sócio/Proprietário de empresa">
                                      Sócio/Proprietário de empresa
                                    </MenuItem1>
                                    <MenuItem1
                                      classes={{
                                        root: classes1.selectMenuItem2,
                                        selected: classes1.selectMenuItemSelected2
                                      }}
                                      value="Autônomo/Profissional Liberal">
                                      Autônomo/Profissional Liberal
                                    </MenuItem1>
                                    <MenuItem1
                                      classes={{
                                        root: classes1.selectMenuItem2,
                                        selected: classes1.selectMenuItemSelected2
                                      }}
                                      value="Aposentado">
                                      Aposentado
                                    </MenuItem1>
                                    <MenuItem1
                                      classes={{
                                        root: classes1.selectMenuItem2,
                                        selected: classes1.selectMenuItemSelected2
                                      }}
                                      value="Pensionista">
                                      Pensionista
                                    </MenuItem1>
                                    <MenuItem1
                                      classes={{
                                        root: classes1.selectMenuItem2,
                                        selected: classes1.selectMenuItemSelected2
                                      }}
                                      value="Locador de Imóvel">
                                      Locador de Imóvel
                                    </MenuItem1>
                                    <MenuItem1
                                      classes={{
                                        root: classes1.selectMenuItem2,
                                        selected: classes1.selectMenuItemSelected2
                                      }}
                                      value="Qualquer ocupação">
                                      Qualquer ocupação
                                    </MenuItem1>
                                  </Select1>
                            </FormControl1>
                            <Button color="success" round onClick={validacaoOcupacaoPrincipal}>
                                  Confirmar
                            </Button>               
                        </div>
                      )
                    }}
                  </ChatContext.Consumer>
              )
            };

    function Profissao1({triggerNextStep}) {
              return (
                <ChatContext.Consumer>
                    {({profissao, setProfissao }) => {
                      const validacaoProfissao = () => profissao === "Selecionar" ? "" : triggerNextStep({trigger:'20'})     
                      console.log(profissao)
                            
                                return ( 
                                  <div>
                                      <FormControl1 fullWidth className={classes1.selectFormControl2}>
                                            <InputLabel1 
                                              variant="standard" 
                                              htmlFor="simple-select" 
                                              className={classes1.selectLabel2}
                                              children= "false" 
                                              color= "primary"
                                              >
                                              Profissão
                                            </InputLabel1>
                                            <Select1
                                              MenuProps={{className: classes1.selectMenu}}
                                              classes={{select: classes1.select2}}
                                              value={profissao}
                                              onChange={handleChange3}
                                              inputProps={{
                                                name: "simpleSelect",
                                                id: "simple-select"
                                              }}>
                                              <MenuItem1
                                                classes={{
                                                  root: classes1.selectMenuItem2,
                                                  selected: classes1.selectMenuItemSelected2}}
                                                  value="Selecionar">
                                                  Selecionar
                                              </MenuItem1>
                                              <MenuItem1
                                                classes={{
                                                  root: classes1.selectMenuItem2,
                                                  selected: classes1.selectMenuItemSelected2}}
                                                value="Advogado">
                                                Advogado
                                            </MenuItem1>
                                              <MenuItem1
                                                classes={{
                                                  root: classes1.selectMenuItem2,
                                                  selected: classes1.selectMenuItemSelected2
                                                }}
                                                value="Acompanhante">
                                                Acompanhante
                                              </MenuItem1>
                                              <MenuItem1
                                                classes={{
                                                  root: classes1.selectMenuItem2,
                                                  selected: classes1.selectMenuItemSelected2
                                                }}
                                                value="Açougueiro">
                                                Açouqueiro
                                              </MenuItem1>
                                              <MenuItem1
                                                classes={{
                                                  root: classes1.selectMenuItem2,
                                                  selected: classes1.selectMenuItemSelected2
                                                }}
                                                value="Acupunturista">
                                                Acupunturista
                                              </MenuItem1>
                                              <MenuItem1
                                                classes={{
                                                  root: classes1.selectMenuItem2,
                                                  selected: classes1.selectMenuItemSelected2
                                                }}
                                                value="Adestrador de animais">
                                                Adestrador de animais
                                              </MenuItem1>
                                              <MenuItem1
                                                classes={{
                                                  root: classes1.selectMenuItem2,
                                                  selected: classes1.selectMenuItemSelected2
                                                }}
                                                value="Administrador">
                                                Administrador de Banco de dados DBA
                                              </MenuItem1>
                                              <MenuItem1
                                                classes={{
                                                  root: classes1.selectMenuItem2,
                                                  selected: classes1.selectMenuItemSelected2
                                                }}
                                                value="Administrador de redes">
                                                Administrador de redes
                                              </MenuItem1>
                                              <MenuItem1
                                                classes={{
                                                  root: classes1.selectMenuItem2,
                                                  selected: classes1.selectMenuItemSelected2
                                                }}
                                                value="Administrador público">
                                                Administrador Público
                                              </MenuItem1>
                                              <MenuItem1
                                                classes={{
                                                  root: classes1.selectMenuItem2,
                                                  selected: classes1.selectMenuItemSelected2
                                                }}
                                                value="Advogado">
                                                Advogado
                                              </MenuItem1>
                                            </Select1>
                                      </FormControl1>
                                      <Button color="success" round onClick={validacaoProfissao}>
                                            Confirmar
                                      </Button>               
                                  </div>
                                )
                              }}
                            </ChatContext.Consumer>
                        )
                      };



   /* function OcupacaoPrincipal({triggerNextStep}) {
      return (
        <ChatContext.Consumer>
            {({ ocPrincipal, setOcPrincipal }) => {
              const validacaoOcupacaoPrincipal = () => ocPrincipal === "Selecionar" ? "" : triggerNextStep({trigger:'16'})     
              console.log(ocPrincipal)
                    
                        return ( 
                          <div>
                              <FormControl  sx={{ m: 1, minWidth: 120 }} size="small">
                                    <InputLabel style={{color:"#fff"}} id="demo-simple-select-label">Ocupação</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={ocPrincipal}
                                      label="Selecionar"
                                      style={{ borderBottomStyle: "groove", borderRadius: "30px"}}
                                      onChange={handleChange}>
                                     <MenuItem value={"Selecionar"}>Selecionar</MenuItem>
                                      <MenuItem value={"Assalariado Privado"}>Assalariado Privado</MenuItem>
                                      <MenuItem value={"Assalariado Publico"}>Assalariado Publico</MenuItem>
                                      <MenuItem value={"Sócio/Proprietário de Empresa"}>Sócio/Proprietário de Empresa</MenuItem>
                                      <MenuItem value={"Autônomo/Profissional Liberal"}>Sócio/Proprietário de Empresa</MenuItem>
                                      <MenuItem value={"Aposentado"}>Aposentado</MenuItem>
                                      <MenuItem value={"Pensionista"}>Pensionista</MenuItem>
                                      <MenuItem value={"Locador de Imóvel"}>Locador de Imóvel</MenuItem>
                                      <MenuItem value={"Qualquer ocupação"}>Qualquer ocupação</MenuItem>
                                    </Select>
                              </FormControl>
                              <Button color="success" round onClick={validacaoOcupacaoPrincipal}>
                                    Confirmar
                              </Button>               
                          </div>
                        )
                      }}
                    </ChatContext.Consumer>
                )
              };
               */

              function RespostaOcupacaoPrincipal() {
                return (
                  <ChatContext.Consumer>
                      {({ ocPrincipal, setOcPrincipal }) => {
                 
                      return ( 
                        <div>
                          <div className="rsc-ts rsc-ts-user sc-bsbRJL domqkE">
                          <div className="rsc-ts-image-container sc-iQNlJl gUVrcY">
                            <img className="rsc-ts-image sc-epnACN EjYuI" src="data:image/svg+xml,%3csvg viewBox='-208.5 21 100 100' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ccircle cx='-158.5' cy='71' fill='%23F5EEE5' r='50'/%3e%3cdefs%3e%3ccircle cx='-158.5' cy='71' id='a' r='50'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse overflow='visible' xlink:href='%23a'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' fill='%23E6C19C'/%3e%3cg clip-path='url(%23b)'%3e%3cdefs%3e%3cpath d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' id='c'/%3e%3c/defs%3e%3cclipPath id='d'%3e%3cuse overflow='visible' xlink:href='%23c'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23d)' d='M-158.5 100.1c12.7 0 23-18.6 23-34.4 0-16.2-10.3-24.7-23-24.7s-23 8.5-23 24.7c0 15.8 10.3 34.4 23 34.4z' fill='%23D4B08C'/%3e%3c/g%3e%3cpath d='M-158.5 96c12.7 0 23-16.3 23-31 0-15.1-10.3-23-23-23s-23 7.9-23 23c0 14.7 10.3 31 23 31z' fill='%23F2CEA5'/%3e%3c/svg%3e" alt="avatar"></img>
                          </div>
                          <div className="rsc-ts-bubble sc-hXRMBi kscpvO">{ocPrincipal}</div>
                        </div>
                      </div>
                      );
                    }}
                  </ChatContext.Consumer>
                );
              };

              function RespostaProfissao1() {
                return (
                  <ChatContext.Consumer>
                      {({ profissao, setProfissao }) => {
                 
                      return ( 
                        <div>
                          <div className="rsc-ts rsc-ts-user sc-bsbRJL domqkE">
                          <div className="rsc-ts-image-container sc-iQNlJl gUVrcY">
                            <img className="rsc-ts-image sc-epnACN EjYuI" src="data:image/svg+xml,%3csvg viewBox='-208.5 21 100 100' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ccircle cx='-158.5' cy='71' fill='%23F5EEE5' r='50'/%3e%3cdefs%3e%3ccircle cx='-158.5' cy='71' id='a' r='50'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse overflow='visible' xlink:href='%23a'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' fill='%23E6C19C'/%3e%3cg clip-path='url(%23b)'%3e%3cdefs%3e%3cpath d='M-108.5 121v-14s-21.2-4.9-28-6.7c-2.5-.7-7-3.3-7-12V82h-30v6.3c0 8.7-4.5 11.3-7 12-6.8 1.9-28.1 7.3-28.1 6.7v14h100.1z' id='c'/%3e%3c/defs%3e%3cclipPath id='d'%3e%3cuse overflow='visible' xlink:href='%23c'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23d)' d='M-158.5 100.1c12.7 0 23-18.6 23-34.4 0-16.2-10.3-24.7-23-24.7s-23 8.5-23 24.7c0 15.8 10.3 34.4 23 34.4z' fill='%23D4B08C'/%3e%3c/g%3e%3cpath d='M-158.5 96c12.7 0 23-16.3 23-31 0-15.1-10.3-23-23-23s-23 7.9-23 23c0 14.7 10.3 31 23 31z' fill='%23F2CEA5'/%3e%3c/svg%3e" alt="avatar"></img>
                          </div>
                          <div className="rsc-ts-bubble sc-hXRMBi kscpvO">{profissao}</div>
                        </div>
                      </div>
                      );
                    }}
                  </ChatContext.Consumer>
                );
              };


  return (
    <section  className={classes.appcss}>
  
        <GridContainer id="container-tabs" className={classes.container} style={{display: "flex", justifyContent: "center", minHeight: "791px"}}>
        <div className={classes.chathomeequityAlign}>
            <GridItem xs={12} sm={12} md={6} className={classes.chathomeequityAlign2}>
                                <Typography  className={classes.tableHomeCarEequityTitle12}>
                                    Fale com o Fitti e simule o seu empréstimo com garantia de imóvel.
                                </Typography>
                                <Typography className={classes.tableHomeCarEequityTitle2} style={{order:"3"}}>
                                   Conte-nos um pouco mais sobre você e as suas necessidades para oferecermos o crédito com garantia (Home Equity) ideal para você.
                                </Typography>
                                <Fade style={{order:"1"}} direction="up" in={true} timeout={{ appear: 3000, enter: 3000, exit: 3000,}} mountOnEnter unmountOnExit>
                                    <div className={classes.imgAnimation}>
                                            <SmoothImage containerStyles={{height:"180px", marginBottom:"110px", overflow:"visible", paddingBottom:"0", display: "grid", justifyContent: "center"}} imageStyles={{position:"relative", height: "auto", overflow:"visible",  marginTop:"5%", marginBottom:"10%",  paddingBottom:"0" }}
                                                src={EmprestimoCarHomeEquity2} alt='Home Equity Fitti' transitionTime={1.0}  transitionTimeFunction={"ease-in"}>
                                            </SmoothImage>
                                    </div>
                                </Fade>
                               
            </GridItem>
            <GridItem xs={12} sm={12} md={6} className={classes.chathomeequityAlign1} >
            <ChatContext.Provider value={{ firstName, setFirstName, ocPrincipal, setOcPrincipal, dataNasc, setDataNasc, profissao, setProfissao}}>
                <ThemeProvider theme={theme}>
                  <ChatBot
                    height="470px"
                    headerTitle= "Simulação do seu empréstimo"
                    recognitionLang= "Pt-br"
                    recognitionPlaceholder= "Ouvindo..."
                    width="100%"
                    placeholder= "Digite a 'sua resposta..."
                    bubbleStyle={{maxWidth:'100%'}}
                
                    steps={[
                        {
                              id: '0',
                              message: '1 - Sobre você:',
                              trigger: '1',
                            },
                            {
                              id: '1',
                              message: 'Qual é o seu 1º nome?',
                              trigger: 'name',
                            },
                            {
                              id: 'name',
                              placeholder:'Digite o seu 1º nome.',
                              inputAttributes: {
                                name:'name',
                              },
                              user: true,
                              trigger: '2',
                            },
                            {
                              id: "2",
                              component: <FirstNameSaver />,
                              trigger: "3",
                              asMessage: true
                            },
                            {
                              id: '3',
                              message: 'Qual é o seu sobrenome completo?',
                              trigger: 'sobrenome',
                            },
                            {
                              id: 'sobrenome',
                              placeholder:'Digite o seu sobrenome completo!',
                              inputAttributes: {
                                name:'sobrenome',
                              },
                              user: true,
                              trigger: '4',
                            },
                            {
                              id: '4',
                              message: 'Você possui algum imóvel?',
                              trigger: 'imovel',
                            },
                            {
                              id: 'imovel',
                              options: [
                                { value: 'Sim', label: `Sim, tenho!`, trigger: '5' },
                                { value: 'Não', label: 'Não! Não possuo!', trigger: '5' },
                              ],
                            },
                            {
                              id: '5',
                              message: 'Qual é o seu email?',
                              trigger: 'email',
                            },
                            {
                              id: 'email',
                              user: true,
                              placeholder:'Digite o seu melhor email!',
                              inputAttributes: {
                                type:'email',
                                name:'email',
                              },
                              trigger: '6',
                              validator: (value) => {
                                function validateEmail(value){
                                  var re = /\S+@\S+\.\S+/;
                                  return re.test(value); }

                                if (validateEmail(value) === false) {
                                  return 'Insira um email válido!';
                                } else { return true; } 
                              },
                              
                            },
                            {
                              id: '6',
                              message: 'Qual é o Nº do seu telefone celular com DDD? ',
                              trigger: '7',
                            },
                             {
                              id: '7',
                              message: 'Digite o DDD com 2 digitos + 9 + Nº do telefone! Tudo junto e sem espaços ou barras! Ex: 31988990022',
                              trigger: 'telefone',
                            },
                            {
                              id: 'telefone',
                              user: true,
                              placeholder:'Digite o Nº do seu celular com DDD.',
                              inputAttributes: {
                                type:'tel',
                                name:'tel',
                              },
                              trigger: '8',
                              validator: (value) => {
                                function validateTelefone(value){
                                  var re =  new RegExp('^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$');
                                  return re.test(value); }
                                if (validateTelefone(value) === false) {
                                  return 'Insira um telefone celular válido!';
                                } else { return true; } 
                              },
                            },
                            {
                              id: '8',
                              message: 'Qual é o ceu CPF?',
                              trigger: '9',
                            },
                               {
                              id: '9',
                              message: 'Digite somente números',
                              trigger: 'cpf',
                            },
                            {
                              id: 'cpf',
                              placeholder:'Digite o Nº do seu CPF!',
                              user: true,
                              inputAttributes: {
                                type:'tel',
                                name:'cpf',
                              },
                              trigger: '10',
                              validator: (value) => {
                               /*   function validatecpf(value){
                                var re =  /(\d{3})(\d{3})(\d{3})(\d{2})/
                                  return re.test(value); }; */

                                  function TestaCPF(value) {
                                    var Soma;
                                    var Resto;
                                    Soma = 0;
                                    var i = 0
                                  if (value === "00000000000") return false;
                                
                                  for (i=1; i<=9; i++) Soma = Soma + parseInt(value.toString().substring(i-1, i)) * (11 - i);
                                  Resto = (Soma * 10) % 11;
                                
                                    if ((Resto === 10) || (Resto === 11))  Resto = 0;
                                    if (Resto !== parseInt(value.toString().substring(9, 10)) ) return false;
                                
                                  Soma = 0;
                                    for (i=1; i<=10; i++) Soma = Soma + parseInt(value.toString().substring(i-1, i)) * (12 - i);
                                    Resto = (Soma * 10) % 11;
                                
                                    if ((Resto === 10) || (Resto === 11))  Resto = 0;
                                    if (Resto !== parseInt(value.toString().substring(10, 11) ) ) return false;
                                    return true;
                                };

                                if (TestaCPF(value) === false) {
                                  return 'Insira um CPF válido!';
                                } else { return true; } 
                              },
                            },
                            {
                              id: '10',
                              message: 'Qual é o RG?',
                              trigger: '11',
                            },
                               {
                              id: '11',
                              message: 'Digite exatamente como está na sua identidade.',
                              trigger: 'rg',
                            },
                            {
                              id: 'rg',
                              placeholder:'Digite o Nº do seu RG!',
                              user: true,
                              trigger: '12',
                              // validator: (value) => {
                               /*   function validatecpf(value){
                                var re =  /(\d{3})(\d{3})(\d{3})(\d{2})/
                                  return re.test(value); }; */

                                /*  if (TestaCPF(value) === false) {
                                  return 'Insira um RG válido!';
                                } else { return true; } */
                             }, 
                             {
                              id: '12',
                              message: 'Qual é a sua data de nascimento?',
                              trigger: '13',
                            },
                               {
                              id: '13',
                              component: <DataNascimento/>,
                              asMessage: true,
                              inputAttributes: {
                                name:'ocupacaoPrincipal',
                              },
                             
                            },
                            {
                              id: 'dataNascimento',
                              component: <RespostaDataNascimento/>,
                              asMessage: false,
                              trigger: '14',
                            }, 
                            {
                              id: '14',
                              message: 'Qual é a sua ocupação principal?',
                              trigger: '15',
                            },
                            {
                              id: "15",
                              component: <OcupacaoPrincipal1/>,
                              asMessage: true,
                              metadata: {custom: "ok"},
                              inputAttributes: {
                                name:'ocupacaoPrincipal',
                                id: "15"
                              },
                            },
                              { 
                              id: "16",
                              message: params => (params.steps[15].metadata.custom),
                              trigger: 'respostaOcupacaoPrincipal'
                            },
                            {
                              id: 'respostaOcupacaoPrincipal',
                              component: <RespostaOcupacaoPrincipal/>,
                              asMessage: false,
                              metadata: {custom: "no"},
                              trigger: '18',
                            }, 

                            {
                              id: '18',
                              message: 'Qual é a sua Profissão?',
                              trigger: '19',
                            },
                            {
                              id: "19",
                              component: <Profissao1/>,
                              asMessage: true,
                              metadata: {custom: "ok"},
                              inputAttributes: {
                                name:'profissao',
                                id: "19"
                              },
                            },
                              { 
                              id: "20",
                              message: params => (params.steps[15].metadata.custom),
                              trigger: 'respostaProfissao'
                            },
                            {
                              id: 'respostaProfissao',
                              component: <RespostaProfissao1/>,
                              asMessage: false,
                              metadata: {custom: "no"},
                              trigger: '21',
                            }, 

                          
                            {
                              id: '21',
                              message: 'Ótimo! Confira o resumo dos seus dados:',
                              trigger: 'review',
                            },
                            {
                              id: 'review',
                              component: <Review />,
                              asMessage: true,
                              //trigger: 'update',
                            },
                            {
                              id: 'update',
                              message: ' Você gostaria de alterar alguma informação?',
                              trigger: 'update-question',
                            },
                            {
                              id: 'update-question',
                              options: [
                                { value: 'yes', label: 'Sim', trigger: 'update-yes' },
                                { value: 'no', label: 'Não', trigger: 'end-message' },
                              ],
                            },
                            {
                              id: 'update-yes',
                              message: 'Qual informação você gostaria de alterar?',
                              trigger: 'update-fields',
                            },
                            {
                              id: 'update-fields',
                              options: [
                                { value: 'name', label: 'Nome', trigger: 'update-name' },
                                { value: 'email', label: 'Email', trigger: 'update-email' },
                                { value: 'telefone', label: 'Telefone', trigger: 'update-telefone' },
                              ],
                            },
                            {
                              id: 'update-name',
                              update: 'name',
                              trigger: '11',
                            },
                            {
                              id: 'update-email',
                              update: 'email',
                              trigger: '11',
                            },
                            {
                              id: 'update-telefone',
                              update: 'telefone',
                              trigger: '11',
                            },
                            {
                              id: 'end-message',
                              message: 'Obrigado! Seus dados foram enviados com sucesso! Em breve entraremos em contato!',
                              end: true,
                            },
                          
                    ]}>
                  </ChatBot>
                  </ThemeProvider>
                </ChatContext.Provider>
                <Typography className={classes.chatHomeCarEequityTitleObs1}>
                    Seus dados estão protegidos:
                </Typography>
                <Typography className={classes.chatHomeCarEequityTitleObs}>
                    *Obs: Realize a sua simulação com tranquilidade que garantimos a segurança e privacidade de todos os seus dados.
                    <span>Ao iniciar a simulação inserindo seus dados, você automaticamente concorda com o tratamento das informações fornecidas e com todos os itens relativos a nossa <PoliticadePobsSimulacao></PoliticadePobsSimulacao> e demais itens ligados as premissas de LGPD.</span> 
                </Typography>
            </GridItem>
        </div>
    </GridContainer>
    <div name="rodape" id="rodape" >
        <Footer2>
        </Footer2>
    </div>
  </section>
  );
}

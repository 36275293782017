
//import { Link } from 'react-router-dom';

import React from 'react';
// nodejs library that concatenates classes
//import classNames from "classnames";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
//import Header from "components/Header/Header.js";
//import Footer2 from "components/Footer/Footer2";
import Footer3 from "components/Footer/Footer3";
//import ButtonsArea from "components/ButtonsArea/ButtonsArea";
//import ButtonsAreaAnime from "components/ButtonsArea/ButtonsAreaAnime.jsx";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CartoesProdutosInvestimentos from 'components/CartoesFitti/CartoesProdutosInvestimentos.jsx';
//import Parallax from "components/Parallax/Parallax.js";
// sections for this page
//import HeaderLinks from "components/Header/HeaderLinks.js";
//import SectionNavbarsNOVO from "./Sections/SectionNavbarsNOVO.js";
//Scrool Smooth site
//import { SmoothProvider } from 'react-smooth-scrolling'

import styles from "assets/jss/material-kit-react/views/components.js";
import ButtonsAreaAnimeInvestimentos from "components/ButtonsAreaInvestimentos/ButtonsAreaAnimeInvestimentos";

//Efeitos
import {Typewriter} from 'react-simple-typewriter';
//import 'react-simple-typewriter/dist/index.css';

//Typography
import Typography from '@material-ui/core/Typography';

//Imagens
//import Logo from 'images/logo-fitticontadigital.svg';


//import Cardeapp from 'images/cardeapp.png';

//Container
//import Container from '@material-ui/core/Container';
//import Grid from '@material-ui/core/Grid';

//Animations
//import AnimationUpDown from '../../../views/Components/Sections/AnimationUpDown';
import AnimationUpDownInvestimentos from  '../../../views/Components/Sections/AnimationUpDownInvestimentos';


//Css degrade Fitticolor
//import Fittidegrade from './Sections/Fittidegrade.js';

//Css
//Animations
import ArrowAnimatedButton from '../../../views/Components/Sections/ArrowAnimatedButton';
//import buttonStyle from "assets/jss/material-kit-react/components/buttonStyle.js";
//import "components/Appcss/App.css";

//JQuery
//import $ from "jquery"
//scroll
//import {animateScroll as scroll} from 'react-scroll';
//import { Fade } from "@material-ui/core";
//import anime from "animejs/lib/anime.es.js";

//Animação do cartão
//import Slide from '@material-ui/core/Slide';
//import Servicos from 'components/Servicos/Servicos.jsx';
import AccordionInvestimentos from  'components/Accordion/AccordionInvestimentos.jsx';
//import NavPillsPro from  'components/NavPills/NavPillsPro.jsx';
import VideoAreaInvestimentos from  'components/VideoArea/VideoAreaInvestimentos.jsx';
//import CartoesFitti from 'components/CartoesFitti/CartoesFitti.jsx';
//import CardConsorcios from 'components/CardConsorcios/CardConsorcios.jsx';
import CardInvestimentos from 'components/CardInvestimentos/CardInvestimentos.jsx';
import ServicosInvestimentos from 'components/Servicos/ServicosInvestimentos.jsx';
import ChartInvestimentos from 'components/Charts/ChartInvestimentos.jsx';
import ProdutosInvestimentos from 'components/Servicos/ProdutosInvestimentos.jsx';
import ChartInvestimentosSuitability from 'components/Charts/ChartInvestimentosSuitability';
//import TradingViewTickerTape from 'components/MenuViews/MenuContent/TradingViewTickerTape.jsx'; 
import TradingViewTickerTape from 'components/MenuViews/MenuContent/TradingViewTickerTape.jsx'; 
//import { useLocation } from 'react-router-dom';




//import {BrowserRouter as Router} from 'react-router-dom';
//import Menu from '../../components/MenuViews/Menu';
//import Content from '../../components/MenuViews/Content';

const useStyles = makeStyles(styles);

export default function MenuEmprestimos(props) {
  const classes = useStyles();
  //const { ...rest } = props;
  
  
  const phoneNumber = '31988980018';
    const handleOpenWhatsApp = () => {
      const url = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
      window.open(url, '_blank');
    };

  const abraSuaConta = () => {
     const url2 = `https://app.genialinvestimentos.com.br/abrir-conta?id=14231`
     window.open(url2, '_blank');
  }


  return (     
       <section  className={classes.appcss}>
            <div className={classes.sectionTopConsorcio}>
                    <GridContainer className={classes.container}>
                    <GridItem style={{background: "rgb(242, 243, 248)", borderRadius:"30px", marginBottom:"20px", boxShadow:" 0 3px 1px -2px rgb(153, 153, 153, 0.2)"}}>
                        <TradingViewTickerTape></TradingViewTickerTape>
                    </GridItem> 
                    <GridItem xs={12} sm={12} md={6}>
                        <div>
                            <Typography  className={classes.title}  color="initial">
                                Os Investimentos
                            </Typography>
                            <Typography className={classes.title}  color="initial">
                                    que dão Fitti com 
                                    <br></br><span style={{ color: "#3f3f3f", fontWeight: 'bold' }}> <Typewriter
                                        loop
                                        cursor
                                        cursorStyle='|'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                        words={['você!', 'sua empresa!', 'seu patrimônio!']}>
                                    </Typewriter></span>
                            </Typography> 
                            <Typography className={classes.subtitulo}>
                                    Diversas soluções de Investimentos que podem dar "Fitti" com você ou com o seu negócio, além de poder contar com uma assessoria de investimentos exclusiva e plataforma digital com produtos bem selecionados.
                            </Typography>
                            <div className={classes.alinhaBotoesInvestimentos}>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoAbraSuaContaInvestimentos} onClick={abraSuaConta}  color="success" round>
                                    ABRA SUA CONTA
                                    </Button>
                                </div>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoFaleComInvestidorInvestimentos} onClick={handleOpenWhatsApp} color="info" round>
                                    FALE C/ UM ASSESSOR
                                    </Button>
                                </div>
                            </div>
                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <AnimationUpDownInvestimentos>
                            </AnimationUpDownInvestimentos>
                        </GridItem>
                    </GridContainer>
                    <div className={classes.arrowanimatedbuttonsConsorcio}>
                        <ArrowAnimatedButton>
                        </ArrowAnimatedButton>
                    </div>
            </div>
            <div name="section2" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"620px"}}>
                <ButtonsAreaAnimeInvestimentos>
                </ButtonsAreaAnimeInvestimentos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"5px", marginBottom:"30px", justifyContent: "center"}}>
                            <div className={classes.alinhaBotoesInvestimentos}>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoAbraSuaContaInvestimentos} onClick={abraSuaConta} color="success" round>
                                    ABRA SUA CONTA
                                    </Button>
                                </div>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoFaleComInvestidorInvestimentos} onClick={handleOpenWhatsApp} color="info" round>
                                    FALE C/ UM ASSESSOR
                                    </Button>
                                </div>
                            </div>
                    </GridItem>
                </GridContainer>
            </div>
             <div name="" id="section2" style={{background: "#fff", minHeight:"700px"}}>
                <CardInvestimentos>
                </CardInvestimentos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-5px", marginBottom:"30px", justifyContent: "center"}}>
                            <div className={classes.alinhaBotoesInvestimentos}>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoAbraSuaContaInvestimentos} onClick={abraSuaConta} color="success" round>
                                    ABRA SUA CONTA
                                    </Button>
                                </div>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoFaleComInvestidorInvestimentos} onClick={handleOpenWhatsApp} color="info" round>
                                    FALE C/ UM ASSESSOR
                                    </Button>
                                </div>
                            </div>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"700px"}}>
                <ServicosInvestimentos>
                </ServicosInvestimentos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"5px", marginBottom:"30px", justifyContent: "center"}}>
                            <div className={classes.alinhaBotoesInvestimentos}>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoAbraSuaContaInvestimentos} onClick={abraSuaConta} color="success" round>
                                    ABRA SUA CONTA
                                    </Button>
                                </div>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoFaleComInvestidorInvestimentos} onClick={handleOpenWhatsApp} color="info" round>
                                    FALE C/ UM ASSESSOR
                                    </Button>
                                </div>
                            </div>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "#fff", minHeight:"550px"}}>
                <ChartInvestimentos>
                </ChartInvestimentos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"5px", marginBottom:"30px", justifyContent: "center"}}>
                            <div className={classes.alinhaBotoesInvestimentos}>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoAbraSuaContaInvestimentos} onClick={abraSuaConta} color="success" round>
                                    ABRA SUA CONTA
                                    </Button>
                                </div>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoFaleComInvestidorInvestimentos} onClick={handleOpenWhatsApp} color="info" round>
                                    FALE C/ UM ASSESSOR
                                    </Button>
                                </div>
                            </div>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{minHeight:"550px", background: "rgb(242, 243, 248)"}}>
                <ChartInvestimentosSuitability>
                </ChartInvestimentosSuitability>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"5px", marginBottom:"30px", justifyContent: "center"}}>
                            <div className={classes.alinhaBotoesInvestimentos}>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoAbraSuaContaInvestimentos} onClick={abraSuaConta} color="success" round>
                                    ABRA SUA CONTA
                                    </Button>
                                </div>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoFaleComInvestidorInvestimentos} onClick={handleOpenWhatsApp} color="info" round>
                                    FALE C/ UM ASSESSOR
                                    </Button>
                                </div>
                            </div>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{minHeight:"550px", background:"#fff"}}>
                <CartoesProdutosInvestimentos>
                </CartoesProdutosInvestimentos>
            </div>

            <div name="" id="section2" style={{background: "#fff", minHeight:"550px"}}>
                <ProdutosInvestimentos>
                </ProdutosInvestimentos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"5px", marginBottom:"30px", justifyContent: "center"}}>
                            <div className={classes.alinhaBotoesInvestimentos}>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoAbraSuaContaInvestimentos} onClick={abraSuaConta}  color="success" round>
                                    ABRA SUA CONTA
                                    </Button>
                                </div>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoFaleComInvestidorInvestimentos} onClick={handleOpenWhatsApp} color="info" round>
                                    FALE C/ UM ASSESSOR
                                    </Button>
                                </div>
                            </div>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="ViddeoArea" id="VideoArea" style={{background: "rgb(242, 243, 248)"}}>
                <VideoAreaInvestimentos>
                </VideoAreaInvestimentos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"5px", marginBottom:"30px", justifyContent: "center"}}>
                            <div className={classes.alinhaBotoesInvestimentos}>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoAbraSuaContaInvestimentos} onClick={abraSuaConta} color="success" round>
                                    ABRA SUA CONTA
                                    </Button>
                                </div>
                                <div style={{marginTop:"15px"}}>
                                    <Button  className={classes.botaoFaleComInvestidorInvestimentos} onClick={handleOpenWhatsApp} color="info" round>
                                    FALE C/ UM ASSESSOR
                                    </Button>
                                </div>
                            </div>
                    </GridItem>
                </GridContainer>
            </div>
            <div>
                <GridContainer className={classes.container} style={{minHeight:"517px"}}>
                    <AccordionInvestimentos>
                    </AccordionInvestimentos>
                </GridContainer>
            </div>
            <div name="rodape" id="rodape" >
                <Footer3>
                </Footer3>
            </div>
        </section>

  );
}
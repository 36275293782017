import React from 'react';
import { Switch, Route } from 'react-router-dom'
//import styles from "assets/jss/material-kit-react/views/components.js";
//import { makeStyles } from "@material-ui/core/styles";
//import { useTransition} from 'react-spring'

import MenuMainPage from './MenuContent/MenuMainPage';
import MenuEmprestimos from './MenuContent/MenuEmprestimos';
import MenuSeguros from './MenuContent/MenuSeguros';
import MenuCambio from './MenuContent/MenuCambio';
import MenuConsorcios from './MenuContent/MenuConsorcios';
//Consócios
import MenuConsorciosAuto from './MenuContent/SubMenuContent/Consorcios/MenuConsorciosAuto';
import MenuConsorciosImobiliario from './MenuContent/SubMenuContent/Consorcios/MenuConsorciosImobiliario';
import MenuConsorciosMoto from './MenuContent/SubMenuContent/Consorcios/MenuConsorciosMoto';
import MenuConsorciosServicos from './MenuContent/SubMenuContent/Consorcios/MenuConsorciosServicos';
//Empréstimos
import MenuEmprestimoPessoalCDC from './MenuContent/SubMenuContent/Emprestimos/MenuEmprestimosPessoalCDC';
import MenuEmprestimosHomeCarEquity from './MenuContent/SubMenuContent/Emprestimos/MenuEmprestimosHomeCarEquity';
//chatProdutos
import ChatHomeEquity from '../../components/ChatsProdutos/ChatHomeEquity.jsx'
//import AberturaConta from '../../components/Genial/AberturaConta.jsx';
//Investimentos
import MenuInvestimentos from './MenuContent/MenuInvestimentos.jsx';
import MenuInvestimentosRetail from  './MenuContent/SubMenuContent/Investimentos/MenuInvestimentosRetail.jsx'

//Routes animation Fade


//const useStyles = makeStyles(styles);

export default function Routes(props) { 

//const [flip, set] = useState(false)
//const location = useLocation();


  //const classes = useStyles(styles)

  return (
    <main> 
    
      <Switch>
          <Route path="/investimentos/retail">
              <MenuInvestimentosRetail></MenuInvestimentosRetail>
          </Route> 
          <Route path="/investimentos/homecarequity">
              <MenuEmprestimosHomeCarEquity></MenuEmprestimosHomeCarEquity>
          </Route> 
          <Route path="/emprestimos/homecarequity/chathomeequity">
              <ChatHomeEquity></ChatHomeEquity>
          </Route> 
          <Route path="/emprestimos/pessoalcdc">
              <MenuEmprestimoPessoalCDC></MenuEmprestimoPessoalCDC>
          </Route>
          <Route path="/emprestimos">
              <MenuEmprestimos></MenuEmprestimos>
          </Route>
          <Route path="/seguros">
              <MenuSeguros></MenuSeguros>
          </Route>
          <Route path="/cambio">
              <MenuCambio></MenuCambio>
          </Route>
          <Route path="/consorcios/auto">
              <MenuConsorciosAuto></MenuConsorciosAuto>
          </Route>
          <Route path="/consorcios/imobiliario">
              <MenuConsorciosImobiliario></MenuConsorciosImobiliario>
          </Route>
          <Route path="/consorcios/moto">
              <MenuConsorciosMoto></MenuConsorciosMoto>
          </Route>
          <Route path="/consorcios/servicos">
              <MenuConsorciosServicos></MenuConsorciosServicos>
          </Route>
          <Route path="/consorcios">
              <MenuConsorcios></MenuConsorcios>
          </Route>
           <Route path="/contadigital">
              <MenuMainPage></MenuMainPage>
          </Route>
          <Route path="/">
              <MenuInvestimentos></MenuInvestimentos>
          </Route> 
       </Switch>
   
    </main>


)} 
import React, { useEffect } from 'react';

const TradingViewWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      "colorTheme": "light",
      "dateRange": "3M",
      "exchange": "BMFBOVESPA",
      "showChart": true,
      "locale": "br",
      "largeChartUrl": "",
      "isTransparent": false,
      "showSymbolLogo": true,
      "showFloatingTooltip": true,
      "width": "100%",
      "height": "550",
      "plotLineColorGrowing": "rgba(114, 196, 57, 1)",
      "plotLineColorFalling": "rgba(135, 179, 52, 1)",
      "gridLineColor": "rgba(240, 243, 250, 0)",
      "scaleFontColor": "rgba(19, 23, 34, 1)",
      "belowLineFillColorGrowing": "rgba(0, 255, 0, 0.12)",
      "belowLineFillColorFalling": "rgba(0, 255, 0, 0.12)",
      "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
      "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
      "symbolActiveColor": "rgba(56, 174, 95, 0.51)"
    });
    document.getElementById("tradingview-widget-container__widget").appendChild(script);
  }, []);

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview-widget-container__widget" />
    </div>
  );
};

export default TradingViewWidget;

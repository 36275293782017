
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import HorizontalScroll from "react-scroll-horizontal";
//import Button from "components/CustomButtons/Button.js";
//import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
//import Check from "@material-ui/icons/Check";
// core components
//import Table from "components/Tables/Table";

//import CardFooter from "components/Card/CardFooterPro.js";


import style from "assets/jss/material-kit-react/views/componentsSections/contentAreas.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import style1 from "assets/jss/material-kit-react/views/components.js";
import Typography from '@material-ui/core/Typography';
import styled, { keyframes } from 'styled-components';
//import Car from "@material-ui/icons/DirectionsCarOutlined";
//import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
//import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
//import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
//import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import MemoryIcon from '@mui/icons-material/Memory' 
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings' 
//import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
//import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import HistoryIcon from '@mui/icons-material/History';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
//import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
//import ManageSearchIcon from '@mui/icons-material/ManageSearch';
//import QueryStatsIcon from '@mui/icons-material/QueryStats';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
//import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import Button from "components/CustomButtons/Button.js";
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
//import styles from "assets/jss/material-kit-react/views/components.js";
//import { makeStyles } from "@material-ui/core/styles";
//import Slide from '@material-ui/core/Slide';
//import EmprestimoCarHomeEquity from 'images/emprestimohomeequity.png'
//import EmprestimoCarHomeEquity2 from 'images/emprestimohomeequity2.png'

import TradingViewRetailStockMarketWidget from '../../components/MenuViews/MenuContent/SubMenuContent/Investimentos/TradingViewRetailStockMarketWidget'
import Badge from "components/Badge/Badge";
import { Link } from "react-router-dom";

//import { Fade } from '@material-ui/core';
//import SmoothImage from 'react-smooth-image'


//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;

const useStyles = makeStyles(style);
const useStyles1 = makeStyles(style1);


export default function Tables() {



  const classes = useStyles();
  const classes1 = useStyles1(style1);



  return (
    <GridContainer id="container-tabs" className={classes1.container} style={{display: "flex", justifyContent: "center"}}>
    <div className={classes1.container2} style={{marginBottom:"35px", marginTop:"10px"}}>
        
                    <Animation1>
                        <Typography className={classes1.titleanimatedbuttons2}>
                        Invista utilizando simuladores e Inteligência Artificial (IA)
                        </Typography>
                    </Animation1>
                  
                <GridContainer className={classes.container} style={{display: "flex", justifyContent: "center", marginTop:"40px"}}>
                    <GridItem xs={12} sm={6} md={6}  className={classes1.tableHomeCarEequity1}>
                            <Typography style={{  order:"2"}}  className={classes1.tableHomeCarEequityTitle1}>
                                Utilize ferramentas de simulação e IA nas suas decisões de investimentos
                            </Typography>
                            <Typography className={classes1.tableHomeCarEequityTitle2} style={{order:"3", marginBottom:"30px"}}>
                                 Conte com simuladores e Inteligência Artificial (IA) para auxiliar nos seus investimentos e nas melhores decisões sobre o seus objetivos financeiros e criação de portfólio. <Badge color="success" style={{fontSize:"20px"}}>Abra sua conta conosco!</Badge>
                            </Typography>
                            <div className={classes.imgAnimation}  style={{marginBottom:"30px"}}>
                            <TradingViewRetailStockMarketWidget></TradingViewRetailStockMarketWidget>
                            </div>
                    </GridItem>
                    
                    <GridItem xs={12} sm={6} md={6} style={{order:"2"}} >
                          <GridItem style={{display: "flex", justifyContent: "center", marginTop: "20px", marginBottom:"20px"}}>
                          <Link style={{textDecoration:"none"}} to="/emprestimos/homecarequity/chathomeequity">
                            <Button  className={classes.botaoSimularConsorcio} style={{width:'100%', height:'50px', fontSize: '14px'}} color="info" round>
                                    ACESSE A INTELIGÊNCIA ARTIFICIAL (IA)  
                            </Button>
                          </Link>
                    </GridItem>
                          <div className={classes1.cardCarHomeEquity1}>
                                <div style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginRight:"20px", backgroundColor:"#fff", marginBottom:"50px", marginTop:"20px", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
                                    <DisplaySettingsIcon style={{ color:"#4caf50", fontSize:"5.0rem", alignSelf: "center", marginRight:"20px"}}></DisplaySettingsIcon>
                                <div> 
                                    <Typography style={{fontWeight:"200", lineHeight: "1.2", fontSize: "1.2rem" }}>
                                        Simuladores para <br/><strong>Renda Variável e Fixa</strong>
                                    </Typography>
                                </div>
                                <div>
                                    <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"25px"}}>
                                        Simule ganhos futuros e possibilidades passadas.
                                    </Typography>
                                </div>
                                <List>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"25px", display:"content"}}>
                                          <HistoryIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50" }}></HistoryIcon> Volte no tempo e simule resultados c/ ações.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px",  display:"content"}}>
                                          <TroubleshootIcon   style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></TroubleshootIcon> Simule aplicações com Tesouro Direto.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px", display:"content"}}>
                                            <QueryStatsIcon  style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></QueryStatsIcon>Simule aplicações em CDB, LCI e LCA.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px",  display:"content"}}>
                                         <PivotTableChartIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></PivotTableChartIcon> Volte no tempo e simule resultados c/ FII´s.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px",  display:"content"}}>
                                         <DataSaverOnIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></DataSaverOnIcon> Simule a montagem da sua carteira.
                                      </Typography>
                                  </ListItem>
                                </List>
                                
                                </div>
                                <div  style={{boxShadow: "0px 2px 23px -16px", borderRadius: "20px", marginBottom:"50px", marginTop:"20px", backgroundColor:"#fff", paddingTop: "20px", paddingBottom: "20px", paddingLeft:"25px", paddingRight:"25px"}}>
                                    <MemoryIcon   style={{color:"#4caf50", fontSize:"5.0rem", alignSelf: "center", marginRight:"20px"}}></MemoryIcon>
                                <div> 
                                    <Typography style={{fontWeight:"200", lineHeight: "1.2", fontSize: "1.2rem" }}>
                                        IA para <br/><strong>Renda Variável e Fixa</strong>
                                    </Typography>
                                </div>
                                <div>
                                <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"25px"}}>  
                                    Realize consultas e seja Assessorado com a IA.
                                </Typography>
                                </div>
                                <List>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"25px"}}>
                                            <DonutSmallIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></DonutSmallIcon>Monte seu portólio ou Asset Allocation.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px"}}>
                                            <PaidOutlinedIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></PaidOutlinedIcon>Saiba quando e onde investir.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px"}}>
                                            <DonutLargeIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></DonutLargeIcon>Rebalanceie sua carteira.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px"}}>
                                            <RequestQuoteOutlinedIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></RequestQuoteOutlinedIcon>Consulte produtos mais rentáveis.
                                      </Typography>
                                  </ListItem>
                                  <ListItem> 
                                      <Typography style={{fontWeight:"100", lineHeight: "1.4", fontSize: "0.8rem", marginTop:"20px"}}>
                                            <ManageSearchIcon style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></ManageSearchIcon>Consulte dados do mercado.
                                      </Typography>
                                  </ListItem>
                                </List>
                                
                                
                                </div>
                          </div>
                          
                    </GridItem>

                </GridContainer>
        </div>
  </GridContainer>
  );
}
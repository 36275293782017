
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import HorizontalScroll from "react-scroll-horizontal";
//import Button from "components/CustomButtons/Button.js";
//import Checkbox from "@material-ui/core/Checkbox";
// material-ui icons
//import Check from "@material-ui/icons/Check";
// core components
//import Table from "components/Tables/Table";

//import CardFooter from "components/Card/CardFooterPro.js";


import style from "assets/jss/material-kit-react/views/componentsSections/contentAreas.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import style1 from "assets/jss/material-kit-react/views/components.js";
import Typography from '@material-ui/core/Typography';
import styled, { keyframes } from 'styled-components';
//import Car from "@material-ui/icons/DirectionsCarOutlined";
//import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
//import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
//import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
//import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
//import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
//import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
//import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
//import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
//import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';

//import styles from "assets/jss/material-kit-react/views/components.js";
//import { makeStyles } from "@material-ui/core/styles";
//import Slide from '@material-ui/core/Slide';
//import EmprestimoCarHomeEquity from 'images/emprestimohomeequity.png'
import InvestRetailLista from 'images/investRetailLista.png'

import { Fade } from '@material-ui/core';
import SmoothImage from 'react-smooth-image';
//import videoImac from 'videos/clipeImac.mp4';
//import ReactPlayer from 'react-player/lazy';

import Check from '@material-ui/icons/Check';
//import Badge from "components/Badge/Badge.js";


//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;

const useStyles = makeStyles(style);
const useStyles1 = makeStyles(style1);


export default function Tables() {



  const classes = useStyles();
  const classes1 = useStyles1(style1);



  return (
    <GridContainer id="container-tabs" className={classes1.container} style={{display: "flex", justifyContent: "center"}}>
    <div className={classes1.container2}>
    <div className={classes1.espacoslistas2}>
                    <Animation1>
                        <Typography className={classes1.titleanimatedbuttons2}>
                        Uma experiência financeira completa em um só lugar
                        </Typography>
                    </Animation1>
                  
                <GridContainer style={{marginTop:"40px"}} className={classes.container} >
                    <GridItem xs={12} sm={12} md={6} className={classes1.imgCarEquitySolo}>
                            <Fade  direction="up" in={true} timeout={{ appear: 3000, enter: 3000, exit: 3000,}} mountOnEnter unmountOnExit>
                                <div className={classes.imgAnimation}>
                                        <SmoothImage containerStyles={{height:"180px", marginBottom:"75px", overflow:"visible", paddingBottom:"0", display: "grid", justifyContent: "center"}} imageStyles={{position:"relative", height: "auto", overflow:"visible",  marginTop:"3%", marginBottom:"10%",  paddingBottom:"0" }}
                                            src={InvestRetailLista} alt='Uma experiência financeira completa em um só lugar' transitionTime={1.0}  transitionTimeFunction={"ease-in"}>
                                        </SmoothImage>
                                </div>
                            </Fade>
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={6}>
                        <Typography className={classes1.tableHomeCarEequityTitleSolo1}>
                            Plataforma com produtos e serviços completos para você
                        </Typography>
                         <List>
                            <Typography  className={classes.letteraccordion}>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle", color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Comece a investir a partir de R$1,00.</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50" }}></Check><span style={{marginRight:"5px"}}> Obtenha renda extra através de aluguéis de ações e ETF´s.</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Push realizado pelo whatsapp de maneira prática.</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Profit gratuito com adesão do RLP.</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>MetaTrader gratuito com adesão do RLP.</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Shopping com cashback disponível.</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Simule e contrate seguros pela plataforma.</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Simule e contrate câmbio pela plataforma.</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Simule e contrate consórcios pela plataforma.</span>
                              </ListItem>
                              <ListItem className={classes.listacelfone}>
                                  <Check style={{marginRight:"10px", verticalAlign:"middle",  color:"#4caf50"  }}></Check><span style={{marginRight:"5px"}}>Simule e contrate empréstimos pela plataforma.</span>
                              </ListItem>
                            </Typography>
                          </List> 
                    </GridItem>
                </GridContainer>
            </div>
        </div>
  </GridContainer >
  );
}
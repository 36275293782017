import React, {useState, useEffect, useCallback} from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// react components for routing our app without refresh
//import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
//import Header from "components/Header/Header.js";
import Footer3 from "components/Footer/Footer3";
//import ButtonsArea from "components/ButtonsArea/ButtonsArea";
//import ButtonsAreaAnime from "components/ButtonsArea/ButtonsAreaAnime.jsx";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
//import Parallax from "components/Parallax/Parallax.js";
// sections for this page
//import HeaderLinks from "components/Header/HeaderLinks.js";
//import SectionNavbarsNOVO from "./Sections/SectionNavbarsNOVO.js";
//Scrool Smooth site
//import { SmoothProvider } from 'react-smooth-scrolling'

import styles from "assets/jss/material-kit-react/views/components.js";
//import ButtonsAreaAnimeConsorcio from "components/ButtonsAreaConsorcios/ButtonsAreaAnimeConsorcio";

//Efeitos
import {Typewriter} from 'react-simple-typewriter';
//import 'react-simple-typewriter/dist/index.css';

//Typography
import Typography from '@material-ui/core/Typography';

//Imagens
//import Logo from 'images/logo-fitticontadigital.svg';


//import Cardeapp from 'images/cardeapp.png';

//Container
//import Container from '@material-ui/core/Container';
//import Grid from '@material-ui/core/Grid';

//Animations
//import AnimationUpDown from '../../../views/Components/Sections/AnimationUpDown';
import AnimationUpDownInvestomentosRetail from  '../../../../../views/Components/Sections/AnimationUpDownInvestomentosRetail.js';


//Css degrade Fitticolor
//import Fittidegrade from './Sections/Fittidegrade.js';

//Css
//Animations
import ArrowAnimatedButton from '../../../../../views/Components/Sections/ArrowAnimatedButton.js';
//import buttonStyle from "assets/jss/material-kit-react/components/buttonStyle.js";
//import "components/Appcss/App.css";

//JQuery
//import $ from "jquery"
//scroll
//import {animateScroll as scroll} from 'react-scroll';
//import { Fade } from "@material-ui/core";
//import anime from "animejs/lib/anime.es.js";

//Animação do cartão
//import Slide from '@material-ui/core/Slide';
//import Servicos from 'components/Servicos/Servicos.jsx';
import AccordionInvestimentos from  'components/Accordion/AccordionInvestimentos.jsx';
//import NavPillsPro from  'components/NavPills/NavPillsPro.jsx';
import VideoAreaInvestimentos from  'components/VideoArea/VideoAreaInvestimentos';
//import CartoesFitti from 'components/CartoesFitti/CartoesFitti.jsx';
//import CardConsorcios from 'components/CardConsorcios/CardConsorcios.jsx';
import CardInvestimentosRetail from 'components/CardInvestimentos/CardInvestimentosRetail.jsx';
//import ServicosInvestimentos from 'components/Servicos/ServicosInvestimentos.jsx';
//import ChartInvestimentos from 'components/Charts/ChartInvestimentos.jsx';
//import TableHomeEquity from 'components/Tables/TableHomeEquity.jsx';
import CartoesProdutosInvestimentos from 'components/CartoesFitti/CartoesProdutosInvestimentos.jsx';
import ProdutosInvestimentosRetail from 'components/Servicos/ProdutosInvestimentosRetail.jsx';

import TableInvestRetailTradeView from 'components/Tables/TableInvestRetailTradeView.jsx'
import TableRetail from 'components/Tables/TableRetail.jsx';
import TableRetail2 from 'components/Tables/TableRetail2.jsx';

//import TableCarEquitySolo from 'components/Tables/TableCarEquitySolo.jsx';
//import NavPillsProEmpreCarHomeEquity from 'components/NavPills/NavPillsProEmpreCarHomeEquity.jsx';
//import { useLocation } from 'react-router-dom';


//import {BrowserRouter as Router} from 'react-router-dom';
//import Menu from '../../components/MenuViews/Menu';
//import Content from '../../components/MenuViews/Content';
// plugin that creates slider
//import Slider from "nouislider";
//import Nouislider from "nouislider-react";
//import noUiSlider from 'nouislider';
//import "nouislider/distribute/nouislider1.css";
//import wNumb from "wnumb";

// @material-ui/core components
//import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui icons
//import People from '@material-ui/icons/People';
// core components
//import CustomInput from 'components/CustomInput/CustomInput.js';
//import MKInput from "components/Inputs/MKInput.js";
//import InputAdornment from '@mui/material/InputAdornment';


import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import NumberFormat from 'react-number-format';
//import Input from '@mui/material/Input';
//import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
//import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
//import FormLabel from '@mui/material/FormLabel';
//Badges dos cards
import Badge from "components/Badge/Badge.js";
import NavPills from "components/NavPills/NavPillsInvestimentos.js";
//import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
//import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import Slider2 from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
//import SliderUnstyled from '@mui/base/SliderUnstyled';
import PaidIcon from '@mui/icons-material/Paid';
import RendaFixIcon from '@mui/icons-material/SignalCellularAlt';
import RendaVarIcon from '@mui/icons-material/Equalizer';
import AporteMensalIcon from '@mui/icons-material/Savings';

//import ModalButtonEmprestimos from './ModalButtonEmprestimos.jsx';
//import SliderHomeEquity from './SliderHomeEquity.jsx';
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components

import modalStyle from "assets/jss/material-kit-react/modalStylePro.js";
//import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
//import AnimatedNumber from 'react-animated-number';

//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody.js";
//import SliderUnstyled from '@mui/base/SliderUnstyled';
import styled1, { keyframes } from 'styled-components';
import RepeatIcon from '@mui/icons-material/Repeat';
import DoneIcon from '@mui/icons-material/Done';
import CountUp from 'react-countup';
//import Tables from './../../../../Tables/TableConsorcioServicos';
import {animateScroll as scroll} from 'react-scroll';
import * as Scroll from 'react-scroll'
import { QueryStatsRounded } from "@mui/icons-material";
import ChartInvestimentosSimulacaoRendaFixa from 'components/Charts/ChartInvestimentosSimulacaoRendaFixa';
import ChartInvestimentosSimulacaoRendaVariavel from 'components/Charts/ChartInvestimentosSimulacaoRendaVariavel';
//Itens da renda variável
import  AutoComplete from "@mui/material/Autocomplete";
//import { format } from "date-fns";
import axios from "axios";
import TickerIcon from '@mui/icons-material/Addchart'
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress'
//import { green, red } from "@mui/material/colors";
import BadgeDot from '@mui/material/Badge';



const style = theme => ({
  ...modalStyle(theme),
  modalRootExample: {
    "& > div:first-child": {
      display: "none"
    },
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  }
});

const useStyles = makeStyles(styles); //tudo que tiver 2 no modal tirar
const useStyles3 = makeStyles(style); //itens do modal..ligadas ao const classes sem numeração


//Itens do modal
//Animation UpDown
const float = keyframes`
  0% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }
  50% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.2);
      transform: translatey(-20px);
  }
  100% {
      box-shadow: 0 0px 0px 0px rgba(0,
      0,
      0,
      0.6);
      transform: translatey(0px);
  }`;

const Animation1 = styled1.div`
animation-delay: 1.5s;
box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
transform: translatey(0px);
animation: ${float} 6s ease-in-out infinite;
`;

//Styled do ponto verde no Badge das logos do resultado de simulação de renda variável.
const StyledBadge = styled(BadgeDot)(({ theme, isMarketOpen }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: isMarketOpen ? '#44b700' : '#d32f2f', // Verde se o mercado estiver aberto, vermelho se fechado
    color: isMarketOpen ? '#44b700' : '#d32f2f',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



//const ariaLabel = { 'aria-label': 'description' };


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  
  TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
  const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        /* onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }} */
        type="tel"
        allowNegative={false}
        autoComplete="off"
        thousandSeparator="."
        isNumericString
        prefix="R$"
        /* suffix=",00" */
        decimalScale={2}
        fixedDecimalScale={true}
        decimalSeparator=","
        allowLeadingZeros={false}
       
        onValueChange={values => {
          onChange({  target: {
            value: values.value
          },
        });
      } 
    }
        
      />
    );
  });
  
  NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  

  //APIS da parte de Renda Variável:
  //const ALPHA_VANTAGE_API_KEY = 'RXD6KESNQNFT6D5Q'; // Substitua com sua chave de API válida
  const BRAPI_BASE_URL = 'https://brapi.dev/api'; // URL base da Brapi.dev
  const BRAPI_TOKEN = 'bhBQcnewKg2kb54YRTnjYV';  // Seu token da Brapi
 // const YAHOO_FINANCE_BASE_URL = 'https://query1.finance.yahoo.com'; // URL base da Yahoo Finance API
  //const FMP_API_KEY = 'pzQ9TxcqoRipndDyRqi0OmydC2NH1s6z'; // Sua API Key para a Financial Modeling Prep
  //const NINJAS_API_KEY = 'k5dcXBZHTsursY2NAkm6SQ==S0NntmEWfynpg8ZG'; // Substitua com sua chave de API válida para API Ninjas 

  //Inicio do default function 
  export default function MenuInvestimentosRetail(props) {
  const classes = useStyles();
  //const { ...rest } = props;
  let LinkScroll = Scroll.Link;

//INPUT do Valor do Investimento Inicial da renda fixa
  var timeout20;
  const [value20, setValue20] = useState(0)
  const handleChange20 = (event20) => {
    timeout20 && clearTimeout(timeout20);
    timeout20 = setTimeout(()=>{
      console.log('change');
      const inputvalue20 = event20.target.value
       if (inputvalue20 === '') {
        setValue20(0) 
     } else {
        const parsedValue20 = parseFloat(inputvalue20)
        setValue20(isNaN(parsedValue20) ? 0 : parsedValue20)
     }
    }, 1000); 
  };


  const abraSuaConta = () => {
    const url2 = `https://app.genialinvestimentos.com.br/abrir-conta?id=14231`;
    window.open(url2, '_blank');
  }

  //INPUT do Valor do Investimento Inicial da renva variável
  var timeout201;
  const [value201, setValue201] = useState(0)
  const handleChange201 = (event201) => {
    timeout201 && clearTimeout(timeout201);
    timeout201= setTimeout(()=>{
      console.log('change');
      const inputvalue201 = event201.target.value
       if (inputvalue201 === '') {
        setValue201(0) 
     } else {
        const parsedValue201 = parseFloat(inputvalue201)
        setValue201(isNaN(parsedValue201) ? 0 : parsedValue201)
     }
    }, 1000); 
  };

 
    
 
    //Vamos lá agora... agora
    //Hooks dos states da renda variável do ticker
    var timeout202;
    const [ticker, setTicker] = useState(null)
    const handleChange202 = (event202, newValue) => {
      timeout202 && clearTimeout(timeout202);
      timeout202= setTimeout(()=>{
        console.log('change');
        setTicker(newValue)
      }, 100); 
    };

    console.log('ticker chegando', ticker)

    //Variáveis do Input Autocomplete Renda Vaiável Inputs
    //const tickersList = ['AAPL', 'MSFT','GOOG', 'AMZN','TSLA']
    //Vamos lá olhar de novo

    const [tickerOptions, setTickerOptions] = useState([
      { symbol: 'PETR3', name: 'Petrobras S.A.', logoUrl: 'https://s3-symbol-logo.tradingview.com/brasileiro-petrobras.svg'},
      { symbol: 'VALE3', name: 'Vale S.A.', logoUrl: 'https://s3-symbol-logo.tradingview.com/vale.svg'},
      { symbol: 'WEGE3', name: 'WEG S.A.', logoUrl: 'https://s3-symbol-logo.tradingview.com/weg.svg'},
      { symbol: 'BBAS3', name: 'Banco Do Brasil S.A', logoUrl: 'https://s3-symbol-logo.tradingview.com/banco-do-brasil.svg'},
      { symbol: 'B3SA3', name: 'B3 S.A.', logoUrl: 'https://s3-symbol-logo.tradingview.com/b3-on-nm.svg'},
      { symbol: 'ABEV3', name: 'AMBEV S.A', logoUrl: 'https://s3-symbol-logo.tradingview.com/ambev.svg'},
      //{ symbol: 'AAPL', name: 'Apple Inc.', logoUrl: 'https://s3-symbol-logo.tradingview.com/apple.svg'},
      //{ symbol: 'NVDA', name: 'NVIDIA Corp.', logoUrl: 'https://s3-symbol-logo.tradingview.com/nvidia.svg'},
    ]); // Para armazenar as sugestões de tickers
   
    var timeout203;
        const [searchQuery, setSearchQuery] = useState('');   // Armazena o que o usuário está digitando
        const handleChange203 = (event203, newInputValue) => {
          timeout203 && clearTimeout(timeout203);
          timeout203 = setTimeout(()=>{
            console.log('change');
            setSearchQuery(newInputValue)
            if (newInputValue === '') {
              setTickerOptions([
                { symbol: 'PETR3', name: 'Petrobras S.A.', logoUrl: 'https://s3-symbol-logo.tradingview.com/brasileiro-petrobras.svg'},
                { symbol: 'VALE3', name: 'Vale S.A.', logoUrl: 'https://s3-symbol-logo.tradingview.com/vale.svg'},
                { symbol: 'WEGE3', name: 'WEG S.A.', logoUrl: 'https://s3-symbol-logo.tradingview.com/weg.svg'},
                { symbol: 'BBAS3', name: 'Banco Do Brasil S.A', logoUrl: 'https://s3-symbol-logo.tradingview.com/banco-do-brasil.svg'},
                { symbol: 'B3SA3', name: 'B3 S.A.', logoUrl: 'https://s3-symbol-logo.tradingview.com/b3-on-nm.svg'},
                { symbol: 'ABEV3', name: 'AMBEV S.A', logoUrl: 'https://s3-symbol-logo.tradingview.com/ambev.svg'},
                //{ symbol: 'AAPL', name: 'Apple Inc.', logoUrl: 'https://s3-symbol-logo.tradingview.com/apple.svg'},
                //{ symbol: 'NVDA', name: 'NVIDIA Corp.', logoUrl: 'https://s3-symbol-logo.tradingview.com/nvidia.svg'},
                // Outras opções padrão
              ]); // Resetar para opções padrão quando vazio
            }
          }, 100); 
        };

//Hook loader do dialog de Renda Variável:
    const [loading, setLoading] = useState(false); // Adiciona estado para carregar o loader
    const [loading2, setLoading2] = useState(false); 
    //Hook loader do dialog de Renda Fixa:
    const [loading3, setLoading3] = useState(false); 
    /*

    // Função para formatar a data no formato dd/MM/yyyy
    const formatarData = (data) => {
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês em JavaScript vai de 0 a 11
      const ano = data.getFullYear();
      return `${dia}/${mes}/${ano}`;
    };

    const fetchBCBData = async (serie, startDate, endDate) => {
      const url = `http://localhost:3001/api/bcb/${serie}?dataInicial=${startDate}&dataFinal=${endDate}`;
      try {
        const response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.error(`Erro ao buscar dados da série ${serie}:`, error.message);
        throw error;
      }
    };

     */

    // Função para buscar dados das empresas pela API BRAPI (via backend)
    const fetchCompanyData = async (query) => {
      try {
        setLoading(true);
        const response = await axios.get(`${BRAPI_BASE_URL}/quote/list`, {
          params: {
            search: query, // Pesquisa por nome ou ticker
            token: BRAPI_TOKEN,
          },
        });
        console.log('Resposta da API Brapi:', response.data);
        
        if (response.data && response.data.stocks && response.data.stocks.length > 0) {
          const options = response.data.stocks.map(stock => ({
            symbol: stock.stock,      // Ticker
            name: stock.name,         // Nome da empresa
            logoUrl: stock.logo || '', // URL do logo, caso exista
          }));
    
          setTickerOptions(options);
        } else {
          console.error('Nenhum dado encontrado para essa busca.');
        }
      } catch (error) {
        console.error('Erro ao buscar dados da empresa na Brapi.dev:', error.message);
      } finally {
        setLoading(false);
      }
    };


 // useEffect para buscar tickers quando o usuário digitar
useEffect(() => {
  let isMounted = true; // Para rastrear se o componente está montado

  if (searchQuery.length > 1) {
    const delayDebounceFn = setTimeout(() => {
      fetchCompanyData(searchQuery).then(() => {
        if (!isMounted) return;
      });
    }, 300);

    return () => {
      clearTimeout(delayDebounceFn);
      isMounted = false;
    };
  }
}, [searchQuery]);



    //Hooks dos pontos em verde e vermelho do pregão relativo aos avatares das logos dos Resultados.
    const [isMarketOpen, setIsMarketOpen] = useState(false);

    // Função para verificar se estamos dentro do horário do pregão
    const checkMarketStatus = () => {
    const now = new Date();
    const day = now.getDay(); // Domingo = 0, Segunda = 1, ..., Sábado = 6
    const hours = now.getHours();
    const minutes = now.getMinutes();

    // Verifica se é um dia útil (Segunda a Sexta)
    const isWeekday = day >= 1 && day <= 5;

    // Horário de abertura: 10:00, Fechamento: 17:00 (Horário de Brasília)
    const marketOpen = hours >= 10 && (hours < 17 || (hours === 17 && minutes === 0));

    setIsMarketOpen(isWeekday && marketOpen);
  };

  // Efeito para verificar o status do mercado em intervalos regulares
  useEffect(() => {
    checkMarketStatus(); // Verifica inicialmente
    const interval = setInterval(checkMarketStatus, 60000); // Verifica a cada minuto

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, []);




  /*
//HyperText do Input do Crédito
const [valorMinimoEmprestimo, setValorMinimoEmprestimo] = useState(<span>Valor mínimo de R$40.000</span>)
useEffect( () => {
  var timeMinimoEmprestimo
  timeMinimoEmprestimo && clearTimeout(timeMinimoEmprestimo);
  timeMinimoEmprestimo = setTimeout(()=>{
    console.log('change');
    setValorMinimoEmprestimo(value20 > 0 && value20 < 40000 ?  <span style={{color:"red"}}>Valor mínimo de R$40.000</span> : <span>Valor mínimo de R$40.000</span>)
  },500)

}, [value20])

*/
  //INPUT do Valor dos aportes mensais da renda fixa
  var timeout22;
  const [value22, setValue22] = useState(0)
  const handleChange22 = (event22) => {
    timeout22 && clearTimeout(timeout22);
    timeout22 = setTimeout(()=>{
      console.log('change');
     const inputvalue22 = event22.target.value
     if (inputvalue22 === '') {
        setValue22(0) 
     } else {
        const parsedValue22 = parseFloat(inputvalue22)
        setValue22(isNaN(parsedValue22) ? 0 : parsedValue22)
     }
    }, 1000); 
  };

/*
  //HyperText do Input do Imovel

  const valorCondicao = value20 * 2.5
  const [valorMinimoImovel, setValorMinimoImovel] = useState(<span>Valor mínimo de R$40.000</span>)
useEffect( () => {
  function minimoimovel(){ 
    if(value22 > 0 && value22 < valorCondicao){ 
      return(
      <span style={{color:"red"}}>Valor mínimo de R$ {new Intl.NumberFormat('pt-BR').format(valorCondicao)} </span>
      ) 
    } else { 
      return(
      <span>Valor mínimo de R$100.000</span>
      ) 
    }
  };
  var timeMinimoImovel
  timeMinimoImovel && clearTimeout(timeMinimoImovel);
  timeMinimoImovel = setTimeout(()=>{
    console.log('change');
    setValorMinimoImovel(minimoimovel())
  },500)

}, [value22, valorCondicao])

*/

 

  //Itens do radio
  const [selectedValue, setSelectedValue] = React.useState('prefixado');

  const handleChange3 = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange3,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

 
//INPUT do Radio Tesouro prefixado, selic, IPCA
var timeoutRadioTesouro;
const [valueRadioTesouro, setValueRadioTesouro] = useState('prefixado')
const handleChangeRadioTesouro = (eventRadioTesouro) => {
  timeoutRadioTesouro && clearTimeout(timeoutRadioTesouro);
  timeoutRadioTesouro = setTimeout(()=>{
    console.log('change');
    setValueRadioTesouro(eventRadioTesouro.target.value);
  }, 0); 
};

 //Itens do radio Renda Variável
 const [selectedValueRV, setSelectedValueRV] = React.useState('ganho_de_capital');

 const handleChange31 = (event) => {
   setSelectedValueRV(event.target.value);
 };

 const controlPropsRV = (item) => ({
   checked: selectedValueRV === item,
   onChange: handleChange31,
   value: item,
   name: 'color-radio-button-demo',
   inputProps: { 'aria-label': item },
 });

//INPUT do Radio Renda Vatiável, Ganho de Capital, dividendos, 
var timeoutCapitalDividendo;
const [valueRadioCapitalDividendo, setValueRadioCapitalDividendo] = useState('ganho_de_capital')
const handleChangeCapitalDividendo = (eventCapitalDividendo) => {
  timeoutCapitalDividendo && clearTimeout(timeoutCapitalDividendo);
  timeoutCapitalDividendo = setTimeout(()=>{
    console.log('change');
    setValueRadioCapitalDividendo(eventCapitalDividendo.target.value);
  }, 0); 
};

/*
//Item sobre o taxa de juros que aparece no modal
const [tipoTxjuros, setTipoTxJuros] = useState(0.0124)
useEffect( () => {
  var timeoutTipoTxJuros
  timeoutTipoTxJuros && clearTimeout(timeoutTipoTxJuros);
  timeoutTipoTxJuros = setTimeout(()=>{
    console.log('change');
    const proporcaoInputCredito1 = value20 * 3.3334
    setTipoTxJuros(value22 >= proporcaoInputCredito1 ? 0.0124 : 0.0129 )
  },200)

}, [value22, value20]) */

        //Função Titop label
        //Função do Slider da renda fixa com todas as variáveis necessárias
        function valueLabelFormat(value5) {
          const units = ['Meses'];

          return `${value5} ${units[0]}`;
        }
        var timeout;
        const [value5, setValue5] = useState(3)
        const handleChange10 = (event, newValue) => {
          timeout && clearTimeout(timeout);
          timeout = setTimeout(()=>{
            console.log('change');
            setValue5(newValue);
          }, 1000); 
        };

        //Função do Slider da renda variável com todas as variáveis necessárias
        function valueLabelFormat2(value51) {
          const units = ['Meses atrás'];

          return `${value51} ${units[0]}`;
        }
        var timeout51;
        const [value51, setValue51] = useState(3)
        const handleChange101 = (event, newValue) => {
          timeout51 && clearTimeout(timeout51);
          timeout51 = setTimeout(()=>{
            console.log('change');
            setValue51(newValue);
          }, 1000); 
        };
        
      



        const PrettoSlider = styled(Slider2)({
          color: '#52af77',
          height: 8,
          '& .MuiSlider-track': {
            border: 'none',
          },
          '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
            '&:before': {
              display: 'none',
            },
          },
          '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 90,
            height: 30,
            //borderRadius: '50% 50% 50% 0',
            backgroundColor: '#52af77',
          // transformOrigin: 'bottom left',
            //transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            //'&:before': { display: 'none' },
            //'&.MuiSlider-valueLabelOpen': {
              //transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            //},
            //'& > *': {
              //transform: 'rotate(45deg)',
            //},
          },
          '& .MuiSlider-markLabel': {
            fontSize: "0.55rem",
            },
        });

        //Marks da renda fixa
        const marks = [
          {
            value:3,
            label: '3',
          },
          {
            value:6,
            label: '6',
          },
          {
            value:12,
            label: '12',
          },
          {
            value: 24,
            label: '24',
          },
          {
            value: 36,
            label: '36',
          },
          {
            value: 48,
            label: '48',
          },
          {
            value: 60,
            label: '60',
          },
          {
            value: 72,
            label: '72',
          },
            {
            value: 84,
            label: '84',
          },
            {
            value: 96,
            label: '96',
          },
          {
            value: 108,
            label: '108',
          },
            {
            value: 120,
            label: '120',
          },
          {
            value: 132,
            label: '132',
          },
          {
            value: 144,
            label: '144',
          },
          {
            value: 156,
            label: '156',
          },
            {
            value: 168,
            label: '168',
          },
            {
            value: 180,
            label: '180',
          },
        ];

        //Marks da renda variável
        const marks2 = [
          {
            value:3,
            label: '3',
          },
          {
            value:6,
            label: '6',
          },
          {
            value: 12,
            label: '12',
          },
          {
            value: 24,
            label: '24',
          },
          {
            value: 60,
            label: '60',
          },  
        ];


        //Itens do modal de simulação
        //Modal que aparece mostrando o resultado da renda fixa
        const [scrollingModal, setScrollingModal] = React.useState(false);
       
        //Aviso Warning de que precisa preencher os campos
        const [scrollingModal2, setScrollingModal2] = React.useState(false);
       
        //Modal que aparece mostrando o resultado da renda variável
        const [scrollingModal3, setScrollingModal3] = React.useState(false);
        
        //Itens do useStyles()
        //const classes = useStyles();
        const classes3 = useStyles3();


        //Hooks dos states dos resultados de renda fixa
        //const [price, setPrice] = useState(0)
        //const [price2, setPrice2] = useState(0)
        //const [price3, setPrice3] = useState(0)
        //const [price4, setPrice4] = useState(0)
        //const [price5, setPrice5] = useState(0)
        //const [investTotal, setInvestTotal] = useState(0)
        //const [rendTotalCdb, setRendTotalCdb] = useState(0)
        //const [rendTotalPrefixado, setRendTotalPrefixado] = useState(0)
        //const [rendTotalSelic, setRendTotalSelic] = useState(0)
        //const [rendTotalIpca, setRendTotalIpca] = useState(0)
        //const [rendTotalPoupanca, setRendTotalPoupanca] = useState(0)

        

       // Função para calcular a TR acumulada com base nos últimos 12 meses
        const calcularTRAcumulada = (trData) => {
          try {
              // Verifica se trData é um array
              if (!Array.isArray(trData)) {
                  console.error('Erro: trData não é um array', trData);
                  return 0;
              }

              // Filtrar os últimos 12 meses de dados (com base na data mais recente)
              const ultimos12Meses = trData
                  .map(item => ({
                      ...item,
                      data: new Date(item.data.split('/').reverse().join('-'))  // Converter string 'dd/mm/yyyy' para objeto Date
                  }))
                  .sort((a, b) => b.data - a.data)  // Ordenar por data em ordem decrescente
                  .slice(0, 12);  // Selecionar os últimos 12 meses

              let acumulado = 1;

              // Itera sobre os últimos 12 meses da TR
              ultimos12Meses.forEach((mes, index) => {
                  const trMensal = parseFloat(mes.valor);

                  // Verifica se o valor de TR mensal é válido
                  if (!isNaN(trMensal)) {
                      acumulado *= (1 + trMensal / 100);  // Acumula a TR mensal
                  } else {
                      console.error(`Erro no valor da TR no índice ${index}:`, mes);
                  }
              });

              return (acumulado - 1) * 100;  // Retorna o valor acumulado em %
          
          } catch (error) {
              console.error('Erro na função calcularTRAcumulada:', error);
              return 0;  // Retorna 0 se houver um erro
          }
        };
        
        // Função para calcular o CDI acumulado
        const calcularCDIAcumulado = (cdiData) => {
          
          try { 

            //Verifica se cdiData é um array
            if(!Array.isArray(cdiData)){
              console.error('Erro: cdiData não é um array', cdiData)
              return 0; //Retorna 0 em caso de erro
            }
            
            let acumulado = 1;
          
            //Itera sobre os dados do CDIm caso seja um array válido
            cdiData.forEach((dia, index) => {
            const cdiDiario = parseFloat(dia.valor);

            //Verifica se o valor do CDI diário é um número válido
            if (!isNaN(cdiDiario)) {
              acumulado *= (1 + cdiDiario / 100); // Acumulando CDI diário
            } else {
              console.error(`Erro no valor do CDI no índice ${index}:`, dia)
            }
            
          });
            
            return (acumulado - 1) * 100; // Retorna o acumulado em %

            } catch (error) {
              console.error('Erro dentro da função calcularCDIAcumulado:', error);
              return 0
            }
        }

              // Função para calcular o IPCA acumulado dos últimos 12 meses
        const calcularIPCAAcumuladoUltimos12Meses = (ipcaData) => {
        // Obter a data de 12 meses atrás
        const hoje = new Date();
        const dataLimite = new Date(hoje);
        dataLimite.setMonth(dataLimite.getMonth() - 12);

        // Filtrar os dados para considerar apenas os últimos 12 meses
        const ultimos12Meses = ipcaData.filter((dia) => {
          const dataDia = new Date(dia.data); // Supondo que 'dia.data' seja uma string de data
          return dataDia >= dataLimite && dataDia <= hoje;
        });

        // Calcular o IPCA acumulado dos últimos 12 meses
        let acumulado = 0;
        ultimos12Meses.forEach((dia) => {
          acumulado += parseFloat(dia.valor); // Somando IPCA mensal
        });

        return acumulado;
      };


        
        // Função para calcular os indexadores acumulados
         const calcularIndexadores = async (value51, value201) => {
          // Definir data inicial e final
          const today = new Date();
          const startDate = new Date(today);
          const startDateRF = new Date(today);
          //data diferenciada variável para renda variável
          startDate.setMonth(today.getMonth() - parseInt(value51));
          //data diferenciada de 12 meses para renda fixa 
          startDateRF.setFullYear(today.getFullYear() - 1);

          const formattedEndDate = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
          const formattedStartDate = `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`;

          const formattedStartDateRF = `${startDateRF.getDate()}/${startDateRF.getMonth() + 1}/${startDateRF.getFullYear()}`;

          // Buscar CDI, Selic, IPCA e TR no backend (que chama o Banco Central)
          const [cdiResponse, cdiResponseRF, selicResponse, ipcaResponse, trResponse] = await Promise.all([
            axios.get(`https://backendfittiinvest-390ee4744fe8.herokuapp.com/api/bcb/12?dataInicial=${formattedStartDate}&dataFinal=${formattedEndDate}`),   // CDI
            
            axios.get(`https://backendfittiinvest-390ee4744fe8.herokuapp.com/api/bcb/12?dataInicial=${formattedStartDateRF}&dataFinal=${formattedEndDate}`),   // CDI 12 meses Renda fixa
            axios.get(`https://backendfittiinvest-390ee4744fe8.herokuapp.com/api/bcb/432?dataInicial=${formattedStartDateRF}&dataFinal=${formattedEndDate}`),  // Selic 12 meses Renda fixa
            axios.get(`https://backendfittiinvest-390ee4744fe8.herokuapp.com/api/bcb/433?dataInicial=${formattedStartDateRF}&dataFinal=${formattedEndDate}`),   // IPCA 12 meses Renda fixa
            axios.get(`https://backendfittiinvest-390ee4744fe8.herokuapp.com/api/bcb/226?dataInicial=${formattedStartDateRF}&dataFinal=${formattedEndDate}`)   // TR (Taxa Referencial)
          ]);

          const cdiData = cdiResponse.data;
          const cdiDataRFAnual = cdiResponseRF.data;
          const selicData = selicResponse.data;
          const ipcaData = ipcaResponse.data;
          const trData = trResponse.data;

          // CDI e IPCA acumulado
          const cdiAcumuladoRFAnualTeste = calcularCDIAcumulado(cdiDataRFAnual);
          console.log("cdiAcumuladoRFAnualTeste:", cdiAcumuladoRFAnualTeste)
          const cdiAcumulado = calcularCDIAcumulado(cdiData);  
          const cdiAcumuladoRFAnual = calcularCDIAcumulado(cdiDataRFAnual);
          const ipcaAcumulado = calcularIPCAAcumuladoUltimos12Meses(ipcaData);
          const trAcumulada = calcularTRAcumulada(trData);  //Calcular TR acumulada
          console.log("Tr acumulada:", trAcumulada)
          console.log("Tr data:", trData)
        
          console.log("ipca acumulada:", ipcaAcumulado)


          // Selic final (último valor do período)
          const selicFinal = parseFloat(selicData[selicData.length - 1]?.valor);
          console.log("selic:", selicFinal)

          // Calcular montante final com CDI, Selic, IPCA e TR
          const montanteFinalCDI = value201 * (1 + cdiAcumulado / 100);
          const montanteCdiAcumuladoRFAnual = value201 * (1 + cdiAcumuladoRFAnual / 100);
          const montanteFinalSelic = value201 * (1 + selicFinal / 100);
          const montanteFinalIPCA = value201 * (1 + ipcaAcumulado / 100);
          const montanteFinalTR = value201 * (1 + trAcumulada / 100);

          const rendimentoCDIRV = montanteFinalCDI - value201;
          const rendimentoCdiAcumuladoRFAnual = montanteCdiAcumuladoRFAnual  - value201;
          const rendimentoSelicRV = montanteFinalSelic - value201;
          const rendimentoIPCARV = montanteFinalIPCA - value201;
          const rendimentoTR = montanteFinalTR - value201;
             
          // Exibir resultados
              console.log('CDI Acumulado:', cdiAcumulado);
              console.log('CDIRF12 Acumulado:', cdiAcumuladoRFAnual);
              console.log('Rendimento CDI:', rendimentoCDIRV);
              console.log('Montante Final CDI:', montanteFinalCDI);
              

              console.log('Selic Final:', selicFinal);
              console.log('Rendimento Selic:', rendimentoSelicRV);
              console.log('Montante Final Selic:', montanteFinalSelic);

              console.log('IPCA Acumulado:', ipcaAcumulado);
              console.log('Rendimento IPCA:', rendimentoIPCARV);
              console.log('Montante Final IPCA:', montanteFinalIPCA);


              console.log('TR Acumulado:', trAcumulada);
              console.log('Rendimento TR:', rendimentoTR );
              console.log('Montante Final TR:', montanteFinalTR );

          // Retornar os valores calculados
          return {
            cdiAcumulado,
            rendimentoCDIRV,
            montanteFinalCDI,
            selicFinal,
            rendimentoSelicRV,
            montanteFinalSelic,
            ipcaAcumulado,
            rendimentoIPCARV,
            montanteFinalIPCA,
            trAcumulada,
            rendimentoTR,
            montanteFinalTR,
            cdiAcumuladoRFAnual,
            montanteCdiAcumuladoRFAnual,
            rendimentoCdiAcumuladoRFAnual 
          };
        };

  
        
  const simulacaoIndexRF = async () => {

        //Variáveis Input Renda Fixa
        const vp = parseFloat(value20)
        const pmt = parseFloat(value22)
        //const pmt2 = parseFloat(value22/21)
        //const pmt3 = parseFloat(value22/30)
        const n = parseFloat(value5)
        const n2 = parseFloat(value5*21)
        const n3 = parseFloat(value5*30)

        const calculadorIR = (value5 <= 6) ? parseFloat(22.5/100) : 
                            (value5 > 6 && value5 <= 12 ) ? parseFloat(20/100) : 
                            (value5 > 12 && value5 <= 24) ? parseFloat(17.5/100) :
                            (value5 > 24) ? parseFloat(15/100) :
                            parseFloat(0)
        
        //Puxam resultados da função calcular rendimentos CDI
        const resultadosRF = await calcularIndexadores(value51, value201);
        
        //Variáveis Taxas CDI
        const Cdi =  parseFloat(resultadosRF.cdiAcumuladoRFAnual)
        console.log("CDI acumulado de:", Cdi)
        const iCdiAno = Cdi/100
        const iCdiDiario = (Math.pow((1 + iCdiAno), 1/252))-1
        const iCdiMes = (Math.pow((1 + iCdiDiario), 22))-1
        
         //Variáveis InvestimentoTotal
        const investimentoTotal = parseFloat(value20) + (parseFloat(value22) * parseFloat(value5))
      
        //Variáveis Taxas CDB
        const rentCdi = parseFloat(150/100)
        const iCdbDiario = iCdiDiario * rentCdi
        const iCdbMes = (Math.pow((1 + iCdbDiario), 22))-1
        
        //Variáveis resultado CDB
        const resultadoCdb = ((vp*(Math.pow((1 + iCdbDiario), n2))) + pmt*((((Math.pow((1 + iCdbMes), n))-1)/iCdbMes)))
        const rendimentoTotalCdb = resultadoCdb-investimentoTotal
        const irCdb = rendimentoTotalCdb * calculadorIR
        const rendimentoLiquidoCdb = rendimentoTotalCdb - irCdb
        const resultadoLiquidoCdb =  rendimentoLiquidoCdb + investimentoTotal
        const taxaRetornoCdb = ((resultadoLiquidoCdb/investimentoTotal)-1)*100
       
        //Variáveis Taxas LC
        const rentLC = parseFloat(90/100)
        //const iLciMes = iCdiMes * rentLC
        const iLciDiario = iCdiDiario * rentLC
        const iLciMes =  (Math.pow((1 + iLciDiario), 22))-1
        //Variáveis resultado LC
        const resultadoLC = ((vp*(Math.pow((1 + iLciDiario), n2))) + pmt*((((Math.pow((1 + iLciMes), n))-1)/iLciMes)))
        const rendimentoTotalLC = resultadoLC-investimentoTotal
        const taxaRetornoLC = ((resultadoLC/investimentoTotal)-1)*100
      

        //Variáveis Taxas Tesouro Direto Prefixado - Vamos que vamos
        //const rentPre = 100/100
        const iPre = parseFloat(11.6/100)
        const iPreDiaria = (Math.pow((1 + iPre), 1/252))-1
        const iPreMes = (Math.pow((1 + iPreDiaria), 22))-1
        
        //Variáveis resultado Selic
        const resultadoTesouroPrefixado = ((vp*(Math.pow((1 + iPreDiaria), n2))) + pmt*((((Math.pow((1 + iPreMes), n))-1)/iPreMes)))
        const rendimentoTotalTesouroPrefixado = resultadoTesouroPrefixado-investimentoTotal
        const irTesouroPrefixado = rendimentoTotalTesouroPrefixado * calculadorIR
        const rendimentoLiquidoTesouroPrefixado = rendimentoTotalTesouroPrefixado - irTesouroPrefixado
        const resultadoLiquidoTesouroPrefixado =  rendimentoLiquidoTesouroPrefixado + investimentoTotal
        const taxaRetornoTesouroPrefixado = ((resultadoLiquidoTesouroPrefixado/investimentoTotal)-1)*100

        //Variáveis Taxas Tesouro Direto Selic
        const rentSelic = parseFloat(100/100)
        const selic = parseFloat(resultadosRF.selicFinal)
        const selicNumber = selic/100
        console.log("selic de:", selic)
        console.log("selicNumber de:", selicNumber)
        const selicDia = (Math.pow((1 + selicNumber), 1/252))-1
        const selicMes = (Math.pow((1 + selicDia), 22))-1
        
        //Variáveis resultado Selic
        const resultadoTesouroSelic = ((vp*(Math.pow((1 + selicDia), n2))) + pmt*((((Math.pow((1 + selicMes), n))-1)/selicMes)))
        const rendimentoTotalTesouroSelic = resultadoTesouroSelic-investimentoTotal
        const irTesouroSelic = rendimentoTotalTesouroSelic * calculadorIR
        const rendimentoLiquidoTesouroSelic = rendimentoTotalTesouroSelic - irTesouroSelic
        const resultadoLiquidoTesouroSelic =  rendimentoLiquidoTesouroSelic + investimentoTotal
        const taxaRetornoTesouroSelic = ((resultadoLiquidoTesouroSelic/investimentoTotal)-1)*100

        //Variáveis Taxas Tesouro Direto IPCA+
        const iPlus = parseFloat(6.15/100)
        const IPCA = parseFloat(resultadosRF.ipcaAcumulado)
        const IPCANumber = IPCA/100
        console.log("IPCA de:", IPCA)
        const IPCADiario = (Math.pow((1 + IPCANumber), 1/360))-1
        const IPCAMes = (Math.pow((1 + IPCADiario), 30))-1
        const iPlusDiario = (Math.pow((1 + iPlus), 1/252))-1
        const iPlusMes = (Math.pow((1 + iPlusDiario), 22))-1
        const IPCAiPLUSMes = IPCAMes+iPlusMes
        //const IPCAiPLUSDiario = IPCADiario+iPlusDiario
        const IPCAiPLUS = IPCANumber  + iPlus 

        //Variáveis resultado Selic
        const captizalicaoTesouroIPCAIPlus = ((vp*(Math.pow((1 + iPlusDiario), n2))) + pmt*((((Math.pow((1 + iPlusMes), n))-1)/iPlusMes)))
        const capitalizacaoTesouroIPCAIPCA = ((vp*(Math.pow((1 + IPCADiario ), n3))) + pmt*((((Math.pow((1 + IPCAMes  ), n))-1)/IPCAMes)))
        const resultadoTesouroIPCATotal = (captizalicaoTesouroIPCAIPlus + capitalizacaoTesouroIPCAIPCA)-investimentoTotal 
        const rendimentoTotalTesouroIPCA =  resultadoTesouroIPCATotal-investimentoTotal
        const irTesouroIPCA = rendimentoTotalTesouroIPCA * calculadorIR
        const rendimentoLiquidoTesouroIPCA = rendimentoTotalTesouroIPCA - irTesouroIPCA
        const resultadoLiquidoTesouroIPCA = rendimentoLiquidoTesouroIPCA + investimentoTotal
        const taxaRetornoTesouroIPCA = ((resultadoLiquidoTesouroIPCA/investimentoTotal)-1)*100
     
      
        //Poupanca = Taxa selic igual ou inferior que 8.5% = 70% da selic
        //           Taxa selic maior que 8.5%, fica 0.5% + TR
        const iPoupanca = parseFloat(0.5/100)
        const iPoupancaAno = (Math.pow((1 + iPoupanca), 12))-1
        console.log("vamos ver iPoupancaAno",iPoupancaAno)
        const tr = parseFloat(resultadosRF.trAcumulada)
        const trNumber = tr/100
        console.log("tr acumulada de:", tr)
        const iPoupancaValida = selicNumber >= (8.5/100) ? (iPoupancaAno + trNumber) : ((selic*parseFloat(0.7))/100)
        console.log("Vamo ver iPoupancaValida", iPoupancaValida)
        const iPoupancaValidaMes = (Math.pow((1 + iPoupancaValida), 1/12))-1
        console.log("Vamo ver iPoupancaValidaMes", iPoupancaValidaMes)
        //Variáveis resultado Poupanca
        const resultadoPoupanca = ((vp*(Math.pow((1 + iPoupancaValidaMes ), n))) + pmt*((((Math.pow((1 + iPoupancaValidaMes ), n))-1)/iPoupancaValidaMes )))
        console.log("Vamo ver resultadoPoupanca", resultadoPoupanca)
        const rendimentoTotalPoupanca = resultadoPoupanca - investimentoTotal
        console.log("Vamo ver rendimentoTotalPoupanca", rendimentoTotalPoupanca)

        return {
          Cdi,
          iCdiAno,
          iCdiDiario,
          iCdiMes,
          investimentoTotal,
          rentCdi, 
          iCdbDiario, 
          iCdbMes,
          rendimentoTotalCdb,
          irCdb,
          rendimentoLiquidoCdb,
          resultadoLiquidoCdb,
          taxaRetornoCdb,
          rentLC,
          iLciDiario,
          iLciMes,
          resultadoLC,
          rendimentoTotalLC,
          taxaRetornoLC,
          iPre,
          iPreDiaria,
          iPreMes,
          rendimentoTotalTesouroPrefixado,
          rendimentoLiquidoTesouroPrefixado,
          resultadoLiquidoTesouroPrefixado, 
          taxaRetornoTesouroPrefixado,
          rentSelic, 
          selic,
          selicDia, 
          selicMes,   
          rendimentoTotalTesouroSelic,
          irTesouroSelic,
          rendimentoLiquidoTesouroSelic,
          resultadoLiquidoTesouroSelic, 
          taxaRetornoTesouroSelic,
          iPlus,
          IPCA, 
          IPCADiario, 
          IPCAMes,
          iPlusDiario, 
          iPlusMes, 
          IPCAiPLUSMes, 
          IPCAiPLUS,
          resultadoTesouroIPCATotal, 
          rendimentoTotalTesouroIPCA,
          irTesouroIPCA, 
          rendimentoLiquidoTesouroIPCA, 
          resultadoLiquidoTesouroIPCA,
          taxaRetornoTesouroIPCA, 
          iPoupanca, 
          tr,
          iPoupancaValida,
          iPoupancaValidaMes,
          resultadoPoupanca,
          rendimentoTotalPoupanca,
        }
  
      }
      
      
      const [rfResults, setRfResults] = useState ({
        Cdi: 0,
        iCdiAno: 0,
        iCdiDiario: 0,
        iCdiMes: 0,
        investimentoTotal: 0,
        rentCdi: 0,
        iCdbDiario: 0,
        iCdbMes: 0,
        rendimentoTotalCdb: 0,
        irCdb: 0,
        rendimentoLiquidoCdb: 0,
        resultadoLiquidoCdb: 0,
        taxaRetornoCdb: 0,
        rentLC: 0,
        iLciDiario: 0,
        iLciMes: 0,
        resultadoLC: 0,
        rendimentoTotalLC: 0,
        taxaRetornoLC: 0,
        iPre: 0,
        iPreDiaria: 0,
        iPreMes: 0,
        rendimentoTotalTesouroPrefixado: 0,
        rendimentoLiquidoTesouroPrefixado: 0,
        resultadoLiquidoTesouroPrefixado: 0,
        taxaRetornoTesouroPrefixado: 0,
        rentSelic: 0,
        selic: 0,
        selicDia: 0,
        selicMes: 0,
        rendimentoTotalTesouroSelic: 0,
        irTesouroSelic: 0,
        rendimentoLiquidoTesouroSelic: 0,
        resultadoLiquidoTesouroSelic: 0,
        taxaRetornoTesouroSelic: 0,
        iPlus: 0,
        IPCA: 0,
        IPCADiario: 0,
        IPCAMes: 0,
        iPlusDiario: 0,
        iPlusMes: 0,
        IPCAiPLUSMes: 0,
        IPCAiPLUS: 0,
        resultadoTesouroIPCATotal: 0,
        rendimentoTotalTesouroIPCA: 0,
        irTesouroIPCA: 0,
        rendimentoLiquidoTesouroIPCA: 0,
        resultadoLiquidoTesouroIPCA: 0,
        taxaRetornoTesouroIPCA: 0,
        iPoupanca: 0,
        tr: 0,
        iPoupancaValida: 0,
        iPoupancaValidaMes: 0,
        resultadoPoupanca: 0,
        rendimentoTotalPoupanca: 0,
})


        console.log("tr final:", rfResults.tr) 
        console.log("iPoupancaValida de:", rfResults.iPoupancaValida)
        console.log("PoupancaValidaMesde:", rfResults.iPoupancaValidaMes)
        console.log("resultadoPoupanca de:", rfResults.resultadoPoupanca)
        console.log("rendimentoTotalPoupanca:", rfResults.rendimentoTotalPoupanca)
    
        
        const [activeTab, setActiveTab] = useState(0)
        const handleChangeTabRVarFix = (index) => {
          setActiveTab(index)
        } 
        //Vamos lá
        //Função para atualizar a aba ativa
        //ActiveTab do botão principal simular
        const mudaRendaFixaXRendaVariavel = () => {
                  if(activeTab === 0){ 
                      simulacaoRendaFixa() 
                    
                  } else if (activeTab === 1) { 
                      simulacaoRendaVariavel() 
                }
        }


        //Hook do Tab da Renda fixa: CDB, lCI, Tesouro
        const [activeTabRF, setActiveTabRF] = useState(0)
        const handleChangeTabRVarFixRF = (index) => {
          setActiveTabRF(index)
        } 

        const simulacaoRendaFixa = async () => {
          //setPrice(price + ((10000)/((((1+0.125)^5)-1)/(0.125*((1+0.125)^5)))) )
          //const variacaoTxCreditoBruto = ((((value20 - 40000)/1000)*(-0.0005))+0.1560235)
          //const txCreditoBruto = value20 ===  40000  ?  0.1560235 : variacaoTxCreditoBruto
          if(value20 ==="" && value22=== "") {
            setScrollingModal(false) 
            setScrollingModal2(true) 
          } else if(value20 <= 0 && value22 <= 0) {
            setScrollingModal(false) 
            setScrollingModal2(true)
          } else { 
            setScrollingModal(true)
            setCountUptipoPrice(mudaPriceTesouro)
            setActiveTabRF(0)
            setLoading3(true); 
            
            try {
              // Chama a função para cálculos
            simulacaoIndexRF() 
            const resultados = await calcularIndexadores(value5, value20, value22);
            const rfResultsFinal = await simulacaoIndexRF(value5, value20, value22)
             // Atualiza o estado de rfResults, preservando o estado anterior
            setRfResults(prevState => ({
              ...prevState,         // Preserva as chaves existentes no estado anterior
              ...rfResultsFinal     // Atualiza com os novos valores retornados de simulacaoIndexRF
            }));
            

            
      
            // Atualiza os hooks com os valores calculados
            setRendCDiRV(resultados.rendimentoCDIRV);
          
            //setRendSelicRV(resultados.rendimentoSelicRV);
            //setRendIPCARV(resultados.rendimentoIPCARV);
            //setRendTR(resultados.rendimentoTR);
      
            // Logs de depuração, caso necessário
            console.log('Resultados:', resultados);
          
          

            //setPrice(price + rfResults.resultadoLiquidoCdb)
            //setPrice2(price2 + rfResults.resultadoLC)
            //setPrice3(price3 + rfResults.resultadoLiquidoTesouroPrefixado )
            //setPrice4(price4 + rfResults.resultadoLiquidoTesouroSelic)
            //setPrice5(price5 + rfResults.resultadoLiquidoTesouroIPCA)

            //setInvestTotal(investTotal + rfResults.investimentoTotal)
            
            //setRendTotalCdb(rendTotalCdb + rfResults.rendimentoTotalCdb)
            //setRendTotalPrefixado(rendTotalPrefixado + rfResults.rendimentoLiquidoTesouroPrefixado)
            //setRendTotalSelic(rendTotalSelic + rfResults.rendimentoLiquidoTesouroSelic)
            //setRendTotalIpca(rendTotalIpca + rfResults.rendimentoLiquidoTesouroIPCA)
            //setRendTotalPoupanca(rendTotalPoupanca + rfResults.rendimentoTotalPoupanca)

              
            

          } catch (error) {
            console.error('Erro ao calcular rendimentos:', error);
          } finally {
            setLoading2(false);
            setLoading3(false);  
       
          }
            }
          
        }

        //Para desligar o modal e zerar o calculo
          const simulacaoRendaFixaRestart = () => {
            setRfResults({
              Cdi: 0,
              iCdiAno: 0,
              iCdiDiario: 0,
              iCdiMes: 0,
              investimentoTotal: 0,
              rentCdi: 0,
              iCdbDiario: 0,
              iCdbMes: 0,
              rendimentoTotalCdb: 0,
              irCdb: 0,
              rendimentoLiquidoCdb: 0,
              resultadoLiquidoCdb: 0,
              taxaRetornoCdb: 0,
              rentLC: 0,
              iLciDiario: 0,
              iLciMes: 0,
              resultadoLC: 0,
              rendimentoTotalLC: 0,
              taxaRetornoLC: 0,
              iPre: 0,
              iPreDiaria: 0,
              iPreMes: 0,
              rendimentoTotalTesouroPrefixado: 0,
              rendimentoLiquidoTesouroPrefixado: 0,
              resultadoLiquidoTesouroPrefixado: 0,
              taxaRetornoTesouroPrefixado: 0,
              rentSelic: 0,
              selic: 0,
              selicDia: 0,
              selicMes: 0,
              rendimentoTotalTesouroSelic: 0,
              irTesouroSelic: 0,
              rendimentoLiquidoTesouroSelic: 0,
              resultadoLiquidoTesouroSelic: 0,
              taxaRetornoTesouroSelic: 0,
              iPlus: 0,
              IPCA: 0,
              IPCADiario: 0,
              IPCAMes: 0,
              iPlusDiario: 0,
              iPlusMes: 0,
              IPCAiPLUSMes: 0,
              IPCAiPLUS: 0,
              resultadoTesouroIPCATotal: 0,
              rendimentoTotalTesouroIPCA: 0,
              irTesouroIPCA: 0,
              rendimentoLiquidoTesouroIPCA: 0,
              resultadoLiquidoTesouroIPCA: 0,
              taxaRetornoTesouroIPCA: 0,
              iPoupanca: 0,
              tr: 0,
              iPoupancaValida: 0,
              iPoupancaValidaMes: 0,
              resultadoPoupanca: 0,
              rendimentoTotalPoupanca: 0,
      })
          //setPrice(price + ((10000)/((((1+0.125)^5)-1)/(0.125*((1+0.125)^5)))) )
          //setPrice(0)
          //setPrice2(0)
          //setPrice3(0)
          //setPrice4(0)
          //setPrice5(0)
          //setInvestTotal(0)
          //setRendTotalCdb(0)
          //setRendTotalPrefixado(0)
          //setRendTotalSelic(0)
          //setRendTotalIpca(0)
          //setRendTotalPoupanca(0)
          
          setScrollingModal(false)
          setValueRadioTesouro("prefixado")
          setSelectedValue("prefixado");
          setActiveTabRF(0)
          
        }

            const simulacaoRendaFixaClose = () => {
          setRfResults({
            Cdi: 0,
            iCdiAno: 0,
            iCdiDiario: 0,
            iCdiMes: 0,
            investimentoTotal: 0,
            rentCdi: 0,
            iCdbDiario: 0,
            iCdbMes: 0,
            rendimentoTotalCdb: 0,
            irCdb: 0,
            rendimentoLiquidoCdb: 0,
            resultadoLiquidoCdb: 0,
            taxaRetornoCdb: 0,
            rentLC: 0,
            iLciDiario: 0,
            iLciMes: 0,
            resultadoLC: 0,
            rendimentoTotalLC: 0,
            taxaRetornoLC: 0,
            iPre: 0,
            iPreDiaria: 0,
            iPreMes: 0,
            rendimentoTotalTesouroPrefixado: 0,
            rendimentoLiquidoTesouroPrefixado: 0,
            resultadoLiquidoTesouroPrefixado: 0,
            taxaRetornoTesouroPrefixado: 0,
            rentSelic: 0,
            selic: 0,
            selicDia: 0,
            selicMes: 0,
            rendimentoTotalTesouroSelic: 0,
            irTesouroSelic: 0,
            rendimentoLiquidoTesouroSelic: 0,
            resultadoLiquidoTesouroSelic: 0,
            taxaRetornoTesouroSelic: 0,
            iPlus: 0,
            IPCA: 0,
            IPCADiario: 0,
            IPCAMes: 0,
            iPlusDiario: 0,
            iPlusMes: 0,
            IPCAiPLUSMes: 0,
            IPCAiPLUS: 0,
            resultadoTesouroIPCATotal: 0,
            rendimentoTotalTesouroIPCA: 0,
            irTesouroIPCA: 0,
            rendimentoLiquidoTesouroIPCA: 0,
            resultadoLiquidoTesouroIPCA: 0,
            taxaRetornoTesouroIPCA: 0,
            iPoupanca: 0,
            tr: 0,
            iPoupancaValida: 0,
            iPoupancaValidaMes: 0,
            resultadoPoupanca: 0,
            rendimentoTotalPoupanca: 0,
    })
          //setPrice(price + ((10000)/((((1+0.125)^5)-1)/(0.125*((1+0.125)^5)))) )
          //setPrice(0)
          //setPrice2(0)
          //setPrice3(0)
          //setPrice4(0)
          //setPrice5(0)
          //setInvestTotal(0)
          //setRendTotalCdb(0)
          //setRendTotalPrefixado(0)
          //setRendTotalSelic(0)
          //setRendTotalIpca(0)
          //setRendTotalPoupanca(0)


          setScrollingModal2(false)
          setValueRadioTesouro("prefixado")
          setSelectedValue("prefixado");
          setActiveTabRF(0)
        }


    //Item sobre tipo de Tesouro Calculo - Montante principal que aparece no modal com mudança do radio
        const mudaPriceTesouro = useCallback(() => {
          if(valueRadioTesouro === 'prefixado'){ 
            return(
              <CountUp end={rfResults.resultadoLiquidoTesouroPrefixado}
                    className={classes.titlePriceRendaFixa}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp>
            ) 
          } else if (valueRadioTesouro === 'selic') { 
            return(
              <CountUp end={rfResults.resultadoLiquidoTesouroSelic}
                    className={classes.titlePriceRendaFixa}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp>
            ) 
          } else { 
             return(
                <CountUp end={rfResults.resultadoLiquidoTesouroIPCA}
                    className={classes.titlePriceRendaFixa}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp> 
            ) 
          }
         }, [valueRadioTesouro, rfResults.resultadoLiquidoTesouroPrefixado, rfResults.resultadoLiquidoTesouroSelic, rfResults.resultadoLiquidoTesouroIPCA, classes.titlePriceRendaFixa])
        

      //Item sobre tipo de Tesouro - Calculo Montante principal que aparece no modal com mudança do radio
       const [countUptipoPrice, setCountUptipoPrice] = useState(null)

        useEffect(() => {
            setCountUptipoPrice(mudaPriceTesouro())                          
        }, [mudaPriceTesouro])



      //Item sobre tipo de Tesouro - Calculo Rentabilidade principal que aparece no modal com mudança do radio
        const mudaRentTesouro = useCallback(() => {
          if(valueRadioTesouro === 'prefixado'){ 
            return(
              
                <CountUp end={rfResults.rendimentoLiquidoTesouroPrefixado}
                  style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                  formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                  frameStyle={perc => ( {opacity: perc/100} )}
                  decimals={2}
                  decimal=","
                ></CountUp>
             
            ) 
          } else if (valueRadioTesouro === 'selic') { 
            return(
              
              <CountUp end={rfResults.rendimentoLiquidoTesouroSelic}
                    style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp>
           
            ) 
          } else { 
            return(
              
                <CountUp end={rfResults.rendimentoLiquidoTesouroIPCA}
                    style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp>
             
            ) 
          }
         }, [valueRadioTesouro, rfResults.rendimentoLiquidoTesouroPrefixado, rfResults.rendimentoLiquidoTesouroSelic, rfResults.rendimentoLiquidoTesouroIPCA])

        //Item sobre tipo de Tesouro - Calculo Rentabilidade que aparece no modal com mudança do radio
        const [countUptipoRent, setCountUptipoRent] = useState(null)

        useEffect(() => {
          setCountUptipoRent(mudaRentTesouro());
        }, [mudaRentTesouro]);
        

        //Item sobre tipo de Tesouro - Calculo Taxa principal que aparece no modal com mudança do radio
        const mudaTaxaTesouro = useCallback(()=>{
          if(valueRadioTesouro === 'prefixado'){ 
            return(
              <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>Rentabilidade prefixada de {(rfResults.iPre*100).toFixed(2).replace(".", ",")}% a.a ou de {(rfResults.iPreMes*100).toFixed(2).replace(".", ",")}% a.m, c/ retorno líquido de {rfResults.taxaRetornoTesouroPrefixado.toFixed(2).replace(".", ",")}% a.p.</div>
            ) 
          } else if (valueRadioTesouro === 'selic') { 
            return(
              <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{(rfResults.rentSelic*100).toFixed(2).replace(".", ",")}% da selic de {(rfResults.selic*1).toFixed(2).replace(".", ",")}% a.a ou de {(rfResults.selicMes*100).toFixed(2).replace(".", ",")}% a.m, c/ retorno líquido de {rfResults.taxaRetornoTesouroSelic.toFixed(2).replace(".", ",")}% a.p.</div>
            ) 
          } else { 
            return(
              <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>IPCA de {(rfResults.IPCA*1).toFixed(2).replace(".", ",")}% a.a + juros prefixados de {(rfResults.iPlus*100).toFixed(2).replace(".", ",")}% a.a, totalizando {(rfResults.IPCAiPLUS*100).toFixed(2).replace(".", ",")}% a.a ou {(rfResults.IPCAiPLUSMes*100).toFixed(2).replace(".", ",")}% a.m , c/ retorno líquido de {rfResults.taxaRetornoTesouroIPCA.toFixed(2).replace(".", ",")}% a.p.</div>
            ) 
          }
         }, [valueRadioTesouro, rfResults.IPCA, rfResults.IPCAiPLUS, rfResults.IPCAiPLUSMes, rfResults.iPlus, rfResults.iPre, rfResults.iPreMes, rfResults.rentSelic, rfResults.selic, rfResults.selicMes, rfResults.taxaRetornoTesouroIPCA, rfResults.taxaRetornoTesouroPrefixado, rfResults.taxaRetornoTesouroSelic])

        //Item sobre tipo de Tesouro - Calculo Taxa principal que aparece no modal com mudança do radio
        const [countUptipoTaxa, setCountUptipoTaxa] = useState(null)

        useEffect( () => {
            setCountUptipoTaxa(mudaTaxaTesouro())                          
        }, [mudaTaxaTesouro])

        //Hooks dos states dos resultados de renda variável
        const [investTotalRendaVariavel, setInvestTotalRendaVariavel] = useState(0)
        const [priceVariavel6, setPriceVariavel6] = useState(0)
        const [rendTotalRendaVariavel, setRendTotalRendaVariavel] = useState(0)
        const [iRetornoLiq, setIRetornoLiq] = useState(0)

        //Hooks dos states dos dividendos de renda variável

        const [cotInicial, setCotInicial] = useState(0)
        const [cotFinal, setCotFinal] = useState(0)
        const [variacaoRV, setVariacaoRV] = useState(0)
        const [nacoesCompradas, setNacoesCompradas] = useState(0)
        const [priceDividendosPeriodo, setPriceDividendosPeriodo] = useState(0)
        const [idivYield, setIdivYield ] = useState(0)
        const [priceVariavelDividendos, setPriceVariavelDividendos] = useState(0)
        const [rendTotalRendaVariavelDividendos, setRendTotalRendaVariavelDividendos] = useState(0)
        const [iRetornoRVDividendosLiq, setIRetornoRVDividendosLiq] = useState(0)
        const [taxaRetornoDivSobInvest, setTaxaRetornoDivSobInvest] = useState(0)
        //Itens de rendaVariável que passo para o ChartInvestimentosSimulacaoRendaVariavel
        const [taxaRetornoMes, setTaxaRetornoMes] = useState([]);
        const [taxaRetornoDividendosMes, setTaxaRetornoDividendosMes] = useState([]);
        console.log("taxaRetornoMes", taxaRetornoMes)
        console.log("taxaRetornoDividendosMes", taxaRetornoDividendosMes)
        //Hooks dos states dos indexadores RV
        const [rendCDiRV, setRendCDiRV] = useState(0)
        console.log("Valor do CDI vamos ver:", rendCDiRV)

        // Estados para armazenar as taxas de retorno do Chart renda variável
        const [taxaRetornoRVLiquidaArray, setTaxaRetornoRVLiquidaArray] = useState([]);
        const [taxaRetornoRVDividendosLiquidaArray, setTaxaRetornoRVDividendosLiquidaArray] = useState([]);
              
        //const [rendSelicRV, setRendSelicRV] = useState(0)
        //console.log("Valor do SELICRV vamos ver:", rendSelicRV)
        //const [rendIPCARV, setRendIPCARV] = useState(0)
        //console.log("Valor do rendIPCARV vamos ver:", rendIPCARV)
        //const [rendTR, setRendTR] = useState(0)
      


        // Itens resultado renda variável - Chamada do botão simular:

        const simulacaoRendaVariavel = async () => {
          console.log('ticker vei2o', ticker)
          console.log('value51 vei2o', value51)
          console.log('value201 vei2o', value201)
        
            if(!value201 || !ticker) {
            setScrollingModal3(false) 
            setScrollingModal2(true)
          } else { 
            setScrollingModal3(true)   
            setLoading2(true);

            try {
              console.log('Entrou no try');
              setLoading(true);

              // Chama a função para cálculos
              const resultados = await calcularIndexadores(value51, value201);
        
              // Atualiza os hooks com os valores calculados
              setRendCDiRV(resultados.rendimentoCDIRV);
              //setRendSelicRV(resultados.rendimentoSelicRV);
              //setRendIPCARV(resultados.rendimentoIPCARV);
              //setRendTR(resultados.rendimentoTR);
                 // Chama a função para cálculos
              simulacaoIndexRF() 
              const rfResultsFinal = await simulacaoIndexRF(value51, value201)
              // Atualiza o estado de rfResults, preservando o estado anterior
              setRfResults(prevState => ({
                ...prevState,         // Preserva as chaves existentes no estado anterior
                ...rfResultsFinal     // Atualiza com os novos valores retornados de simulacaoIndexRF
              }));
        
              // Logs de depuração, caso necessário
              console.log('Resultados:', resultados);
           
             
              // Faz a requisição para o backend com o ticker e o value51 Yahoo finance
              const response = await axios.post(`https://backendfittiinvest-390ee4744fe8.herokuapp.com/api/chart`, {
                ticker: ticker.symbol,   // Envia o símbolo do ticker
                value51,                 // Envia o horizonte de tempo (em meses)
              });

              // Verifique o status da resposta
              console.log('Status da resposta:', response.status);

              // Acesse corretamente os dados retornados
              const stockData = response.data;
              const quotes = stockData.quotes;
              const dividends = stockData.events?.dividends || [];
              console.log('Stock data retornando:', stockData);
              console.log('Stock data quotes retornando:', quotes);
              console.log('Dividens retornando:', dividends);

              if (quotes && quotes.length > 0) {
                const cotacaoInicial = quotes[0].close;
                let cotacaoInicialChart = quotes[0].close;
                const cotacaoFinal = quotes[quotes.length - 1].close;  // Acessa o último valor de fechamento
                console.log('Cotação inicial:', cotacaoInicial);
                console.log('Cotação final:', cotacaoFinal);

                // Calculo de dividendos
                let totalDividendos = 0;
                dividends.forEach(div => {
                  totalDividendos += div.amount;
                });

                console.log('Total de Dividendos no Período:', totalDividendos);
                                  
              //Variavel do Investimento total da Renda Variável
              const investimentoInicialRvariavel = parseFloat(value201);
                
              //Itens do Chart parte Renda Variavel
              const taxasLiquidas = [];
              console.log("taxasLiquidas", taxasLiquidas)
              const taxasDividendosLiquidas = []
              console.log("taxasDividendosLiquidas", taxasDividendosLiquidas)

              // Iniciando com 0 para o período 0
              taxasLiquidas.push(0);
              taxasDividendosLiquidas.push(0);

              // Iterando para calcular a taxa para cada período (3, 6, 12, ..., até value51)
           // Verifique se há cotações suficientes antes de começar
            // Verifique se há cotações suficientes antes de começar
          // Iterando para calcular a taxa para cada período (3, 6, 12, ..., até value51)
        for (let i = 3; i <= value51; i += 3) {
          // Calculando o índice da cotação final com base no tamanho real de `quotes`
          const indexCotacaoFinal = Math.max(0, Math.floor((i / value51) * (quotes.length - 1)));
          
          // Verificando se o índice está dentro dos limites válidos
          if (indexCotacaoFinal < quotes.length && indexCotacaoFinal >= 0) {
              const cotacaoFinalPeriodo = quotes[indexCotacaoFinal].close;
              console.log(`Cotação final para o período ${i} meses:`, cotacaoFinalPeriodo);

              const cotacaoInicialPeriodo = quotes[0].close;
              console.log('Cotação inicial para o período:', cotacaoInicialPeriodo);

              const taxaRetornoBrutaPeriodo = ((cotacaoFinalPeriodo / cotacaoInicialPeriodo) - 1) * 100;
              const resultadoTotalRendaVariavelBruto = investimentoInicialRvariavel * (1 + (taxaRetornoBrutaPeriodo / 100));
              const rendimentoTotalRendaVariavelBruto = resultadoTotalRendaVariavelBruto - investimentoInicialRvariavel;

              // Dividendos
              const numeroAcoesCompradas = investimentoInicialRvariavel / cotacaoInicialPeriodo;
              const dividendosRecebidosTotais = numeroAcoesCompradas * totalDividendos;
              
              // Resultado com dividendos
              const resultadoTotalRendaVariavelComDividendos = resultadoTotalRendaVariavelBruto + dividendosRecebidosTotais;

              // Cálculo da taxa líquida com dividendos
              const taxaRetornoDividendosLiquida = ((resultadoTotalRendaVariavelComDividendos / investimentoInicialRvariavel) - 1) * 100;
              const taxaRetornoLiquida = ((resultadoTotalRendaVariavelBruto / investimentoInicialRvariavel) - 1) * 100;

              // Adiciona ao array de taxas para o gráfico
              taxasLiquidas.push(taxaRetornoLiquida);
              taxasDividendosLiquidas.push(taxaRetornoDividendosLiquida);
          } else {
              console.error(`Índice fora de intervalo: ${indexCotacaoFinal}. Número de cotações disponíveis: ${quotes.length}`);
          }
      }

      
              // Calculos dos retornos do ativo escolhido na renda variável
              const taxaRetornoBruta = ((cotacaoFinal / cotacaoInicial)-1) * 100;
              console.log('Calculo taxa de retorno bruta:', taxaRetornoBruta );
             
              const resultadoTotalRendaVariavelBruto = investimentoInicialRvariavel * (1 + (taxaRetornoBruta / 100));
              console.log('Calculo resultado Total Renda Variavel Bruto', resultadoTotalRendaVariavelBruto);
             
              const rendimentoTotalRendaVariavelBruto =  resultadoTotalRendaVariavelBruto - investimentoInicialRvariavel 
              console.log('Calculo rendimento Total RendaVariavel Bruto ', rendimentoTotalRendaVariavelBruto);
             
             
              //Calcula o IR sobre o valor do Lucro da ação acima de 20 mil e com lucro
              const calculadorIRRendaVariavel = resultadoTotalRendaVariavelBruto  <= 20000 ? parseFloat(0) : 
              (resultadoTotalRendaVariavelBruto > 20000 && rendimentoTotalRendaVariavelBruto > 0 ) ? parseFloat(15/100) :
              (resultadoTotalRendaVariavelBruto > 20000 && rendimentoTotalRendaVariavelBruto < 0 ) ? parseFloat(0)  :
              parseFloat(0)

              
              //Calcula o IRRF (Imposto retido na fonte) sobre o valor da venda da ação acima de 20 mil e com lucro
              const calculadorIRRFRRendaVariavel = parseFloat(0.005/100) 
             
              const IRRendaVariavel = rendimentoTotalRendaVariavelBruto * calculadorIRRendaVariavel
              console.log('Calculo IRRendaVariavel', IRRendaVariavel )
              const IRRFRendaVariavel = resultadoTotalRendaVariavelBruto * calculadorIRRFRRendaVariavel
              console.log('Calculo IIRRFRendaVariavel ', IRRFRendaVariavel)
              const resultadoTotalRendaVariavelLiquido = resultadoTotalRendaVariavelBruto - IRRendaVariavel - IRRFRendaVariavel 
              console.log('Calculo resultado Total Renda Variavel Liquido', resultadoTotalRendaVariavelLiquido)
              const rendimentoTotalRendaVariavelLiquido = rendimentoTotalRendaVariavelBruto - IRRendaVariavel - IRRFRendaVariavel
              console.log('Calculo rendimento Total Renda Variavel Liquido ', rendimentoTotalRendaVariavelLiquido)
              const taxaRetornoRVLiquida =  ((resultadoTotalRendaVariavelLiquido/investimentoInicialRvariavel)-1)*100 
              console.log('taxaRetornoRVLiquida', taxaRetornoRVLiquida)

              //Calculos dos Dividendos
              const numeroAcoesCompradas = investimentoInicialRvariavel/cotacaoInicial
              const dividendosRecebidosTotais = numeroAcoesCompradas * totalDividendos
               // Cálculo do Dividend Yield
              const dividendYield = (totalDividendos / cotacaoInicial) * 100;
              const taxaRetornoDividendoSobInvest = (dividendosRecebidosTotais/investimentoInicialRvariavel)*100 
              const resultadoTotalRendaVariavelComDividendos = resultadoTotalRendaVariavelLiquido + dividendosRecebidosTotais;
              const rendTotalRVLiqDividendos = rendimentoTotalRendaVariavelLiquido + dividendosRecebidosTotais
              const taxaRetornoRVDividendosLiquida = ((resultadoTotalRendaVariavelComDividendos/investimentoInicialRvariavel)-1)*100 
              

              //Hooks dos states dos resultados de renda variável
              setPriceVariavel6(priceVariavel6 + resultadoTotalRendaVariavelLiquido)
              setRendTotalRendaVariavel(rendTotalRendaVariavel + rendimentoTotalRendaVariavelLiquido)
              setInvestTotalRendaVariavel(investTotalRendaVariavel + investimentoInicialRvariavel)
              setIRetornoLiq(iRetornoLiq + taxaRetornoRVLiquida )
              
              //Hooks dos states dos dividendos de renda variável
              setCotInicial(cotInicial + cotacaoInicial)
              setCotFinal(cotFinal + cotacaoFinal)
              setVariacaoRV(variacaoRV + taxaRetornoBruta)
              setNacoesCompradas(nacoesCompradas + numeroAcoesCompradas)
              setPriceDividendosPeriodo(priceDividendosPeriodo + dividendosRecebidosTotais)
              setIdivYield(idivYield + dividendYield)
              setPriceVariavelDividendos(priceVariavelDividendos + resultadoTotalRendaVariavelComDividendos)
              setRendTotalRendaVariavelDividendos(rendTotalRendaVariavelDividendos + rendTotalRVLiqDividendos)
              setIRetornoRVDividendosLiq(iRetornoRVDividendosLiq + taxaRetornoRVDividendosLiquida)
              setTaxaRetornoDivSobInvest(taxaRetornoDivSobInvest + taxaRetornoDividendoSobInvest)
             
              //SetStates dos Hooks passados mensalmentepara o gráfico ChartInvestimentosSimulacaoRendaVariavel
              // Atualiza os estados com os arrays de taxas calculadas
              setTaxaRetornoRVLiquidaArray(taxasLiquidas);
              setTaxaRetornoRVDividendosLiquidaArray(taxasDividendosLiquidas);

              } else { 
              console.error('Nenhum dado de cotação foi retornado.')
              }
            } catch (error) {
              console.error('Erro ao realizar a simulação:', error);
            } finally {
              setLoading(false);
              setLoading2(false);
            }
          };


          };
            //Para desligar o modal e zerar o calculo
              const simulacaoRendaVariavelRestart = () => {
              setPriceVariavel6(0)
              setRendTotalRendaVariavel(0)
              setInvestTotalRendaVariavel(0)
              setIRetornoLiq(0)

              setCotInicial(0)
              setCotFinal(0)
              setVariacaoRV(0)
              setNacoesCompradas(0)
              setPriceDividendosPeriodo(0)
              setIdivYield(0)
              setPriceVariavelDividendos(0)
              setRendTotalRendaVariavelDividendos(0)
              setIRetornoRVDividendosLiq(0)
              setTaxaRetornoDivSobInvest(0)
    
              setScrollingModal3(false)
              setSelectedValueRV("ganho_de_capital");
              setValueRadioCapitalDividendo('ganho_de_capital')

              setRendCDiRV(0)
              //setRendSelicRV (0)
              //setRendIPCARV (0)
            };

            const simulacaoRendaVariavelClose = () => {
              setPriceVariavel6(0)
              setRendTotalRendaVariavel(0)
              setInvestTotalRendaVariavel(0)
              setIRetornoLiq(0)

              setCotInicial(0)
              setCotFinal(0)
              setVariacaoRV(0)
              setNacoesCompradas(0)
              setPriceDividendosPeriodo(0)
              setIdivYield(0)
              setPriceVariavelDividendos(0)
              setRendTotalRendaVariavelDividendos(0)
              setIRetornoRVDividendosLiq(0)
              setTaxaRetornoDivSobInvest(0)
              setSelectedValueRV("ganho_de_capital");
              setValueRadioCapitalDividendo('ganho_de_capital')
              
              setRendCDiRV(0)
              //setRendSelicRV (0)
              //setRendIPCARV (0)
              
              setScrollingModal3(false)
            };


            //Item sobre tipo de RendaVariavel Ganho de Capital e Dividendo Calculo - Montante principal que aparece no modal com mudança do radio
        const mudaPriceRendaVariavel = useCallback(() => {
          if(valueRadioCapitalDividendo === 'ganho_de_capital'){ 
            return(

              <div>
                <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                    <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Montante líquido final</span>
                </Typography>
                <CountUp end={priceVariavel6}
                      className={classes.titlePriceRendaFixa} 
                      style={{color: priceVariavel6 > investTotalRendaVariavel || priceVariavel6 === 0 ? "rgb(76, 175, 80)" : "#f44336" }}
                      formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                      frameStyle={perc => ( {opacity: perc/100} )}
                      decimals={2}
                      decimal=","
                ></CountUp>
              </div>
            ) 
          } else if (valueRadioCapitalDividendo === 'dividendos') { 
            return(
              <div>
                <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                      <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Total de dividendos do período</span>
                </Typography>
                <CountUp end={priceDividendosPeriodo}
                      className={classes.titlePriceRendaFixa}
                      formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                      frameStyle={perc => ( {opacity: perc/100} )}
                      decimals={2}
                      decimal=","
                  ></CountUp>
              </div>
            ) 
          } else { 
            return(
              <div>
                <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                    <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Montante líquido final</span>
                </Typography>
                <CountUp end={priceVariavelDividendos}
                    className={classes.titlePriceRendaFixa} 
                    style={{color: priceVariavelDividendos > investTotalRendaVariavel || priceVariavelDividendos === 0 ? "rgb(76, 175, 80)" : "#f44336" }}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp> 
              </div>
              
            ) 
          }
         },[valueRadioCapitalDividendo, priceVariavel6, priceDividendosPeriodo, priceVariavelDividendos, investTotalRendaVariavel, classes.title2subEmpreParamsInput, classes.titlePriceRendaFixa])
        
      //Item sobre tipo Montante Renda Variavel - Calculo Montante principal que aparece no modal com mudança do radio
       const [countUptipoPriceRV, setCountUptipoPriceRV] = useState(null)


        useEffect( () => {
            setCountUptipoPriceRV(mudaPriceRendaVariavel())                          
        }, [mudaPriceRendaVariavel])




        //Item sobre tipo Renda Variável - Calculo Rentabilidade principal que aparece no modal com mudança do radio
        const mudaRentRendaVariavel = useCallback(() => {
          if(valueRadioCapitalDividendo === 'ganho_de_capital'){ 
            return(
            <div>
              <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                  <span>Investimento total + (Rendimento <span style={{ display: priceVariavel6 > 20000 && rendTotalRendaVariavel > 0 ? "contents" : priceVariavel6 <= 20000 ? "none" : priceVariavel6 > 20000 && rendTotalRendaVariavel < 0 ? "none" : "contents" }}> - IR </span> - IRRF)</span>
              </Typography>
              <div className={classes.calcInvesRend}>
                <CountUp end={investTotalRendaVariavel}
                    style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                    frameStyle={perc => ( {opacity: perc/100} )}
                    decimals={2}
                    decimal=","
                ></CountUp>
                <Typography style={{padding:"0 10px"}}>+</Typography>
                <Badge color={rendTotalRendaVariavel >= 0 ? "success" : "danger"}>
                  <CountUp end={rendTotalRendaVariavel}
                      style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                      formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                      frameStyle={perc => ( {opacity: perc/100} )}
                      decimals={2}
                      decimal=","
                  ></CountUp>
                </Badge>
              </div>   
            </div>
            ) 
          } else if (valueRadioCapitalDividendo === 'dividendos') { 
            return(
              <div>
                <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                      <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Dividend Yield do período</span>
                  </Typography>
                <CountUp end={idivYield}
                      style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                      formattingFn={v => `${parseFloat(v.toFixed(2)).toLocaleString('pt-BR')}%`} 
                      frameStyle={perc => ( {opacity: perc/100} )}
                      decimals={2}
                      decimal=","
                ></CountUp>
              </div>
            ) 
          } else { 
            return(
              
              <div>
                <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                    <span>Investimento total + (Rendimento <span style={{ display: priceVariavel6 > 20000 && rendTotalRendaVariavel > 0 ? "contents" : priceVariavel6 <= 20000 ? "none" : priceVariavel6 > 20000 && rendTotalRendaVariavel < 0 ? "none" : "contents" }}> - IR </span> - IRRF) + Dividendos totais</span>
                </Typography>
              <div className={classes.calcInvesRend}>
                  <CountUp end={investTotalRendaVariavel}
                      style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                      formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                      frameStyle={perc => ( {opacity: perc/100} )}
                      decimals={2}
                      decimal=","
                  ></CountUp>
                  <Typography style={{padding:"0 10px"}}>+</Typography>
                  <Badge color={rendTotalRendaVariavel >= 0 ? "success" : "danger"}>
                    <CountUp end={rendTotalRendaVariavel}
                        style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                        formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                        frameStyle={perc => ( {opacity: perc/100} )}
                        decimals={2}
                        decimal=","
                    ></CountUp>
                  </Badge>
                  <Typography style={{padding:"0 10px"}}>+</Typography>
                  <Badge color="success">
                    <CountUp end={priceDividendosPeriodo}
                        style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                        formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                        frameStyle={perc => ( {opacity: perc/100} )}
                        decimals={2}
                        decimal=","
                    ></CountUp>
                  </Badge>
                </div>   
              </div>
            
            ) 
          }
        }, [classes.calcInvesRend, valueRadioCapitalDividendo, rendTotalRendaVariavel, idivYield, investTotalRendaVariavel, priceDividendosPeriodo, priceVariavel6, classes.title2subEmpreParamsInput])

        //Item sobre tipo de Tesouro - Calculo Rentabilidade que aparece no modal com mudança do radio
        const [countUptipoRentRV, setCountUptipoRentRV] = useState(null)


        useEffect( () => {
            setCountUptipoRentRV(mudaRentRendaVariavel())                          
        }, [mudaRentRendaVariavel])

  
  
  
  //Item sobre tipo de Tesouro - Calculo Taxa principal que aparece no modal com mudança do radio
  const mudaTaxaRV = useCallback(() => {
    if(valueRadioCapitalDividendo === 'ganho_de_capital'){ 
      return(

    <div>
        <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
            <span>Taxa de retorno</span>
        </Typography>
        <div style={{ display:"flex", justifyContent:"center", marginTop:"10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}> Rentabilidade de <span style={{display: "contents", color: rendTotalRendaVariavel >= 0 ? "#4caf50" : "#f44336"}}> {(iRetornoLiq*1).toFixed(2).replace(".", ",")} % a.p. </span> se tivesse investido há {value51} meses atrás. </div>
        <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
    </div>

      ) 
    } else if (valueRadioCapitalDividendo === 'dividendos') { 
      return(
        <div>
          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
              <span>Taxa de retorno</span>
          </Typography>
          <div style={{ display:"flex", justifyContent:"center", marginTop:"10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>Rentabilidade em dividendos de {(taxaRetornoDivSobInvest).toFixed(2).replace(".", ",")}% a.p. sobre o investimento de {(investTotalRendaVariavel).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2, maximumFractionDigits:2})}, se tivesse investido há {value51} meses atrás.</div>
          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
        </div>
      ) 
    } else { 
      return(
    <div>
        <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
            <span>Taxa de retorno com dividendos</span>
        </Typography>
        <div style={{ display:"flex", justifyContent:"center", marginTop:"10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}> Rentabilidade de <span style={{display: "contents", color: iRetornoRVDividendosLiq >= 0 ? "#4caf50" : "#f44336"}}> {(iRetornoRVDividendosLiq*1).toFixed(2).replace(".", ",")} % a.p. </span> se tivesse investido há {value51} meses atrás. </div>
        <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
    </div>
      ) 
    }
   },  [valueRadioCapitalDividendo, classes.title2subEmpreParamsInput, iRetornoLiq, iRetornoRVDividendosLiq, taxaRetornoDivSobInvest, rendTotalRendaVariavel, investTotalRendaVariavel, value51])

  //Item sobre tipo de Tesouro - Calculo Taxa principal que aparece no modal com mudança do radio
  const [countUptipoTaxaRV, setCountUptipoTaxaRV] = useState(null)


  useEffect( () => {
    setCountUptipoTaxaRV(mudaTaxaRV())                          
  }, [mudaTaxaRV])
  
  
        return (     
       <section  className={classes.appcss}>
            <div className={classes.sectionTopConsorcio} >
                <GridContainer className={classes.container3}>
                    <GridItem xs={12} sm={12} md={12} lg={6} className={classes.ipadSimulationCenter}>
                        <div>
                            <Typography  className={classes.titleEmprestimosPrincipal}  color="initial">
                               <span>Investimentos</span><span style={{ color: "#3f3f3f", fontWeight: 'bold'}}> Retail</span>
                            </Typography>
                            <Typography className={classes.titleEmprestimosPrincipal}  color="initial">
                                    que dão Fitti c/ <br className={classes.titleEmprestimosPrincipal2BR}/>
                                    <span className={classes.titleEmprestimosPrincipal2} style={{ color: "#3f3f3f", fontWeight: 'bold'}}> <Typewriter
                                        loop
                                        cursor
                                        cursorStyle='|'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                        words={['você!', 'seu jeito!']}>
                                    </Typewriter></span>
                            </Typography> 
                            <div className={classes.displayTitlesub3}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <div className={classes.displayFundoCinzaEmprestimos3}>
                                                                                  
 <GridContainer style={{marginTop: "-25px"}}> 
      <div style={{maxWidth: "500px"}}>
          <NavPills style={{overflow:"hidden", height:"40px"}}
            color="success"
            active={activeTab} //Aba ativa
            
            tabs={[
              {
                tabButton: (<span onClick={()=>handleChangeTabRVarFix(0)}><RendaFixIcon style={{marginRight: "5px", verticalAlign: "middle"}}></RendaFixIcon>Renda Fixa</span> ),
                tabContent: (
                  <div className={classes.empreParamsInputTotal}>
                
                                <div className={classes.empreParamsInput}>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                          <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Investimento inicial</span>
                                        </Typography>
                                        <TextField 
                                            id="outlined-basic" 
                                            //label="Valor do empréstimo" 
                                            variant="outlined"
                                            //required
                                            placeholder="R$100.000,00"
                                            //helperText={valorMinimoEmprestimo}
                                            color="success"
                                            size="small"
                                            value={value20.numberformat}
                                            onChange={handleChange20}
                                            name="numberformat"
                                            //id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                              
                                            
                                            //InputProps={{startAdornment: <InputAdornment position="start">R$</InputAdornment>, ariaLabel}}
                                            >
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                            <span><AporteMensalIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></AporteMensalIcon>Aportes mensais</span>
                                        </Typography>
                                        <TextField 
                                            id="outlined-basic" 
                                            //label="Valor do imóvel" 
                                            variant="outlined"
                                            //required
                                            placeholder="R$100.000,00"
                                            //helperText={valorMinimoImovel}
                                            color="success"
                                            size="small"
                                            value={value22.numberformat}
                                            onChange={handleChange22}
                                            name="numberformat"
                                            //id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                    
                                            //InputProps={{startAdornment: <InputAdornment position="start">R$</InputAdornment>, ariaLabel}}
                                            >
                                        </TextField>
                                    </GridItem>
                                </div>
                                <GridItem style={{paddingLeft: "46px", paddingRight: "46px"}}> 
                                        <Typography className={classes.title2subEmpreParamsInput2}>
                                            Quanto tempo deixaria seu dinheiro investido?
                                        </Typography>
                                        <PrettoSlider
                                        aria-label="Temperature"
                                        //defaultValue={30}
                                        //getAriaValueText={valuetext}
                                      
                                        valueLabelDisplay="on"
                                        valueLabelColor="success"
                                        getAriaValueText={valueLabelFormat}
                                        valueLabelFormat={valueLabelFormat}
                                        step={null}
                                        marks={marks}
                                        //min={3}
                                        //max={180}
                                        color="success"
                                        defaultValue={value5} 
                                        onChange={handleChange10}
                                              >
                                        </PrettoSlider>
                                </GridItem>                                                
                  </div>
                )
              },


              {
                tabButton: (<span  onClick={()=>handleChangeTabRVarFix(1)}><RendaVarIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></RendaVarIcon>Renda Variável</span> ),
                tabContent: (
                   <div className={classes.empreParamsInputTotal}>
                
                                <div className={classes.empreParamsInput}>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                            <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Investimento inicial</span>
                                        </Typography>
                                        <TextField 
                                            id="outlined-basic" 
                                            //label="Valor do empréstimo" 
                                            variant="outlined"
                                            //required
                                            placeholder="R$100.000,00"
                                            //helperText={valorMinimoEmprestimo}
                                            color="success"
                                            size="small"
                                            value={value201.numberformat}
                                            onChange={handleChange201} // criar um handleChange cpm if no botão principal com useEffect = {setInvestment(Number(e.target.value))}
                                            name="numberformat"
                                            //id="formatted-numberformat-input"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            //InputProps={{startAdornment: <InputAdornment position="start">R$</InputAdornment>, ariaLabel}}
                                            >
                                        </TextField>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Typography className={classes.title2subEmpreParamsInput}>
                                             <span><TickerIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></TickerIcon>Ativo selecionado</span>
                                        </Typography>
                                        <AutoComplete
                                            options={tickerOptions}
                                            sx={{width: '100%'}}
                                            getOptionLabel={(option) => `${option.symbol} - ${option.name}`} // Exibe o rótulo da opção
                                            onInputChange={handleChange203} //Monitora o input do usuário
                                            value={ticker}
                                            onChange={handleChange202} //Armazena apenas o ticker (Symbol)
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                  <Avatar src={option.logoUrl} alt={option.name} sx={{ width: 24, height: 24, marginRight: '10px' }} />
                                                  {`${option.symbol} - ${option.name}`}
                                                </li>
                                            )}
                                            
                                            renderInput={(params) => (<TextField {...params} 
                                                                      label="Selecione o Ticker" 
                                                                      color="success"
                                                                      size="small"
                                                                      id="outlined-basic" 
                                                                      variant="outlined"
                                                                      InputProps={{
                                                                          ...params.InputProps,
                                                                          endAdornment: (
                                                                            <>
                                                                              {loading ? <CircularProgress color="success" size={20} /> : null}
                                                                              {params.InputProps.endAdornment}
                                                                            </>
                                                                          ),
                                                                        }}
                                                                    ></TextField>)}
                                                                    
                                            isOptionEqualToValue={(option, value) => option.symbol === value?.symbol} // Comparação correta
                                            //noOptionsText="Nenhuma opção" // Mensagem quando não há opções
                                            loading={loading} // Ativa o estado de loading no Autocomplete
                                          ></AutoComplete>
                                    </GridItem>
                                </div>
                                <GridItem style={{paddingLeft: "46px", paddingRight: "46px"}}> 
                                      <Typography className={classes.title2subEmpreParamsInput2}>
                                          Se fosse investido a quantos meses atrás:
                                      </Typography>
                                      <PrettoSlider
                                      aria-label="Temperature"
                                      //defaultValue={30}
                                      //getAriaValueText={valuetext}
                                    
                                      valueLabelDisplay="on"
                                      valueLabelColor="success"
                                      getAriaValueText={valueLabelFormat2} //Resolver 
                                      valueLabelFormat={valueLabelFormat2} //Resolver 
                                      step={null}
                                      marks={marks2}
                                      //min={6}
                                      //max={60}
                                      color="success"
                                      defaultValue={value51} 
                                      onChange={handleChange101} //Resolver 
                                            >
                                      </PrettoSlider>
                                </GridItem>  
                        
                              </div>
                            )
                          },

                        ]}

                      
                      ></NavPills>
                    </div>
                </GridContainer>
                        
                                    </div>
                                </GridItem>
                            </div>

                           <div style={{display: "flex", justifyContent: "center", marginTop: "-30px"}}>
                           
                             <div>
                               <LinkScroll to="topo"  smooth={true} duration={1000} offset={-100} onClick={() => scroll.scrollToTop()}>
                                  <Button color="success" round onClick={mudaRendaFixaXRendaVariavel}  className={classes.botaoSimularConsorcio}>
                                      Simule seu investimento
                                  </Button>
                               </LinkScroll>
                                  <Dialog 
                                    //onClick={() => setScrollingModal(false)}
                                    classes={{
                                      root: `${classes3.modalRoot} ${classes3.modalRootExample}`,
                                      paper: classes3.modal
                                    }}
                                    open={scrollingModal}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={simulacaoRendaFixaRestart}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description">
                                    <DialogTitle
                                      id="classic-modal-slide-title"
                                      disableTypography
                                      className={classes3.modalHeader}>
                                      <Button
                                        simple
                                        className={classes3.modalCloseButton}
                                        key="close"
                                        aria-label="Close"
                                        onClick={simulacaoRendaFixaRestart}>
                                        {" "}
                                        <Close className={classes3.modalClose} />
                                      </Button>
                                      <div className={classes3.container2}>
                                            <Animation1>
                                                  <Typography className={classes.titleanimatedbuttons2}>
                                                    Resultado da simulação
                                                  </Typography>
                                            </Animation1>
                                        </div>

                                    </DialogTitle>
                                    <DialogContent
                                      id="classic-modal-slide-description"
                                      className={classes3.modalBody}>

                                    
                                  <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div className={classes.displayFundoCinzaEmprestimos2}>
                                    <NavPills style={{overflow:"hidden", height:"40px"}}
                                            color="success"
                                            active={activeTabRF} //Aba ativa
                                            tabs={[
                                              {
                                                tabButton: (<span onClick={()=>handleChangeTabRVarFixRF(0)}><RendaFixIcon style={{marginRight: "5px", verticalAlign: "middle"}}></RendaFixIcon>CDB e LC</span> ),
                                                tabContent: (

                                                  <div>
                                                    {loading3 ? (
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign:"middle", height:"50vh", width:"100%"}}>
                                                       <CircularProgress color="success" size={50}></CircularProgress>
                                                    </div>
                                                    ) : (
                                                  <div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Montante líquido final:</span>
                                                          </Typography>

                                                                <CountUp end={rfResults.resultadoLiquidoCdb}
                                                                className={classes.titlePriceRendaFixa}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                                ></CountUp>
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Nº de Meses</span>
                                                          </Typography>
                                                        
                                                          <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{value5}</div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Investimento total + (Rendimento - IR)</span>
                                                          </Typography>
                                                          <div className={classes.calcInvesRend}>
                                                            <CountUp end={rfResults.investimentoTotal}
                                                                style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                            ></CountUp>
                                                            <Typography style={{padding:"0 10px"}}>+</Typography>
                                                            <Badge color="success">
                                                              <CountUp end={rfResults.rendimentoLiquidoCdb}
                                                                  style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                  formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                  frameStyle={perc => ( {opacity: perc/100} )}
                                                                  decimals={2}
                                                                  decimal=","
                                                              ></CountUp>
                                                            </Badge>
                                                  
                                                            
                                                          </div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Taxa de juros</span>
                                                            </Typography>
                                                            <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{(rfResults.rentCdi*100).toFixed(0).replace(".", ",")}% do CDI de {(rfResults.Cdi*1).toFixed(2).replace(".", ",")}% a.a ou de {(rfResults.iCdiMes*100).toFixed(2).replace(".", ",")}% a.m, c/ retorno líquido de {rfResults.taxaRetornoCdb.toFixed(2).replace(".", ",")}% a.p.</div>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Rendimento na poupança:</span>
                                                            </Typography>
                                                            <div className={classes.calcInvesRend}>
                                                              <Badge color="success">
                                                                <CountUp end={rfResults.rendimentoTotalPoupanca}
                                                                    style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                    frameStyle={perc => ( {opacity: perc/100} )}
                                                                    decimals={2}
                                                                    decimal=","
                                                                ></CountUp>
                                                              </Badge>
                                                            </div>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                          
                                                    </div>
                                                  )}
                                                  </div>
                                              )

                                              },
                                              {
                                                tabButton: (<span onClick={()=>handleChangeTabRVarFixRF(1)}><RendaFixIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></RendaFixIcon>LCI e LCA</span> ),
                                                tabContent: (
                                                  <div>
                                                    {loading3 ? (
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign:"middle", height:"50vh", width:"100%"}}>
                                                       <CircularProgress color="success" size={50}></CircularProgress>
                                                    </div>
                                                    ) : (
                                                  <div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Montante líquido final:</span>
                                                          </Typography>

                                                                <CountUp end={rfResults.resultadoLC}
                                                                className={classes.titlePriceRendaFixa}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                                ></CountUp>
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Nº de Meses</span>
                                                          </Typography>
                                                        
                                                          <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{value5}</div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Investimento total + Rendimento</span>
                                                          </Typography>
                                                          <div className={classes.calcInvesRend} >
                                                            <CountUp end={rfResults.investimentoTotal}
                                                                style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                            ></CountUp>
                                                            <Typography style={{padding:"0 10px"}} >+</Typography>
                                                            <Badge color="success">
                                                              <CountUp end={rfResults.rendimentoTotalLC}
                                                                  style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                  formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                  frameStyle={perc => ( {opacity: perc/100} )}
                                                                  decimals={2}
                                                                  decimal=","
                                                              ></CountUp>
                                                            </Badge>
                                                          </div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Taxa de juros</span>
                                                            </Typography>
                                                            <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", textAlign:"center", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{(rfResults.rentLC*100).toFixed(0).replace(".", ",")}% do CDI de {(rfResults.Cdi*1).toFixed(2).replace(".", ",")}% a.a ou de {(rfResults.iCdiMes*100).toFixed(2).replace(".", ",")}% a.m, c/ retorno líquido de {rfResults.taxaRetornoLC.toFixed(2).replace(".", ",")}% a.p.</div>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Rendimento na poupança:</span>
                                                            </Typography>
                                                            <div className={classes.calcInvesRend}>
                                                              <Badge color="success">
                                                                <CountUp end={rfResults.rendimentoTotalPoupanca}
                                                                    style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                    frameStyle={perc => ( {opacity: perc/100} )}
                                                                    decimals={2}
                                                                    decimal=","
                                                                ></CountUp>
                                                              </Badge>
                                                            </div>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                  </div>
                                                  )}
                                                </div>
                                                )
                                              },
                                              {
                                                tabButton: (<span onClick={()=>handleChangeTabRVarFixRF(2)}><RendaFixIcon  style={{marginRight: "5px", verticalAlign: "middle"}}></RendaFixIcon>Tesouro</span> ),
                                                tabContent: (
                                                  <div>
                                                    {loading3 ? (
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign:"middle", height:"50vh", width:"100%"}}>
                                                       <CircularProgress color="success" size={50}></CircularProgress>
                                                    </div>
                                                    ) : (
                                                  <div>
                                                      <div>                                            
                                                        <GridItem style={{display:"block", justifyContent:"center", marginTop:"5px"}}>
                                                            <Typography className={classes.title2subEmpreParamsInput}><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>
                                                                Tipos de Tesouro Direto:
                                                            </Typography>
                                                            <RadioGroup value={valueRadioTesouro} onChange={handleChangeRadioTesouro} defaultValue='prefixado' style={{display:"flex", justifyContent:"center"}} row aria-label="Capitalização" name="row-radio-buttons-group">
                                                                <FormControlLabel value="prefixado" {...controlProps('prefixado')} control={<Radio color="success" size="small" />}  label="Prefixado"></FormControlLabel>
                                                                <FormControlLabel value="selic"   {...controlProps('selic')} control={<Radio color="success" size="small" />}  label="Selic"></FormControlLabel>
                                                                <FormControlLabel value="ipca"   {...controlProps('ipca')} control={<Radio color="success" size="small" />}  label="IPCA+"></FormControlLabel>
                                                            </RadioGroup>
                                                        </GridItem>
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Montante líquido final:</span>
                                                          </Typography>
                                                          {countUptipoPrice}
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Nº de Meses</span>
                                                          </Typography>
                                                        
                                                          <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{value5}</div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Investimento total + (Rendimento - IR)</span>
                                                          </Typography>
                                                          <div className={classes.calcInvesRend}>
                                                            <CountUp end={rfResults.investimentoTotal}
                                                                style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                frameStyle={perc => ( {opacity: perc/100} )}
                                                                decimals={2}
                                                                decimal=","
                                                            ></CountUp>
                                                            <Typography style={{padding:"0 10px"}}>+</Typography>
                                                            <Badge color="success">
                                                             {countUptipoRent}
                                                            </Badge>
                                                          </div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Taxa de juros</span>
                                                            </Typography>
                                                            {countUptipoTaxa}
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                       
                                                  </div>
                                                )}
                                              </div>
                                                )
                                              },
                                              {
                                                tabButton: (<span onClick={()=>handleChangeTabRVarFixRF(3)}><QueryStatsRounded  style={{marginRight: "5px", verticalAlign: "middle"}}></QueryStatsRounded>Gráfico</span> ),
                                                tabContent: (
                                                  
                                                  <div>
                                                    {loading3 ? (
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign:"middle", height:"50vh", width:"100%"}}>
                                                       <CircularProgress color="success" size={50}></CircularProgress>
                                                    </div>
                                                    ) : (
                                                  <div>
                                                     
                                                          <div>
                                                              <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                  <span><QueryStatsRounded  style={{marginRight: "5px", verticalAlign: "middle"}}></QueryStatsRounded>Gráfico da simulação:</span>
                                                              </Typography>
                                                              <ChartInvestimentosSimulacaoRendaFixa  rfResults={rfResults} value5={value5} value20={value20} value22={value22}></ChartInvestimentosSimulacaoRendaFixa>
                                                          </div>
                                                   </div>
                                                    )}
                                                </div>
                                                )
                                              },


                                        ]}>
                                      </NavPills>
                                        
                                      
                                
                                      </div>
                                    </GridItem>
                                    </DialogContent>
                                    <DialogActions style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <GridItem className={classes.modalFooterEmpre}>
                                      
                                          <Button onClick={() => {simulacaoRendaFixaRestart(); abraSuaConta()}}   color="success" round>
                                              <span><DoneIcon fontSize="large"  style={{marginRight: "5px", verticalAlign: "middle"}}></DoneIcon> Gostei! Começar a investir!</span>
                                          </Button>
                                          
                                          <Button onClick={simulacaoRendaFixaRestart} color="secondary" round>
                                              <span><RepeatIcon fontSize="large" style={{marginRight: "5px", verticalAlign: "middle"}}></RepeatIcon> Simular novamente</span>
                                          </Button>
                                         
                                        
                                        </GridItem>
                                    </DialogActions>
                                  </Dialog>


                                  <Dialog 
                                    //onClick={() => setScrollingModal(false)}
                                    classes={{
                                      root: `${classes3.modalRoot} ${classes3.modalRootExample}`,
                                      paper: classes3.modal
                                    }}
                                    open={scrollingModal2}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={simulacaoRendaFixaClose}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description">
                                    <DialogTitle
                                      id="classic-modal-slide-title"
                                      disableTypography
                                      className={classes3.modalHeader}>
                                      <Button
                                        simple
                                        className={classes3.modalCloseButton}
                                        key="close"
                                        aria-label="Close"
                                        onClick={simulacaoRendaFixaClose}>
                                        {" "}
                                        <Close className={classes3.modalClose} />
                                      </Button>
                                      <div className={classes3.container2}>
                                            <Animation1>
                                                  <Typography className={classes.titleanimatedbuttons2}>
                                                    Resultado da simulação
                                                  </Typography>
                                            </Animation1>
                                        </div>

                                    </DialogTitle>
                                    <DialogContent
                                      id="classic-modal-slide-description"
                                      className={classes3.modalBody}>

                                    
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div className={classes.displayFundoCinzaEmprestimos2}>
                                          <div>
                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Preencha todos os campos!</span>
                                            </Typography>
                                          </div>
                                        </div>
                                      </GridItem>
                                    </DialogContent>
                              
                                    <DialogActions style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <GridItem className={classes.modalFooterEmpre}>
                                      
                                          <Button onClick={simulacaoRendaFixaClose} color="success" round>
                                              <span><DoneIcon style={{marginRight: "5px", verticalAlign: "middle"}}></DoneIcon>OK!</span>
                                          </Button>
                                        </GridItem>
                                    </DialogActions>
                                  </Dialog>
                      
                                 

                                  <Dialog 
                                    //onClick={() => setScrollingModal(false)}
                                    classes={{
                                      root: `${classes3.modalRoot} ${classes3.modalRootExample}`,
                                      paper: classes3.modal
                                    }}
                                    open={scrollingModal3}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={simulacaoRendaVariavelClose}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description">
                                    <DialogTitle
                                      id="classic-modal-slide-title"
                                      disableTypography
                                      className={classes3.modalHeader}>
                                      <Button
                                        simple
                                        className={classes3.modalCloseButton}
                                        key="close"
                                        aria-label="Close"
                                        onClick={simulacaoRendaVariavelClose}>
                                        {" "}
                                        <Close className={classes3.modalClose} />
                                      </Button>
                                      <div className={classes3.container2}>
                                            <Animation1>
                                                  <Typography className={classes.titleanimatedbuttons2}>
                                                    Resultado da simulação
                                                  </Typography>
                                            </Animation1>
                                        </div>

                                    </DialogTitle>
                                    <DialogContent
                                      id="classic-modal-slide-description"
                                      className={classes3.modalBody}>

                                    
                                  <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div className={classes.displayFundoCinzaEmprestimos2}>
                                    <NavPills style={{overflow:"hidden", height:"40px"}}
                                            color="success"
                                            tabs={[
                                              {
                                                tabButton: (<span><RendaFixIcon style={{marginRight: "5px", verticalAlign: "middle"}}></RendaFixIcon>Ativo selecionado</span> ),
                                                tabContent: (
                                                
                                            <div>
                                                  {loading2 ? (
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign:"middle", height:"50vh", width:"100%"}}>
                                                       <CircularProgress color="success" size={50}></CircularProgress>
                                                    </div>
                                                    ) : (
                                                    <div>
                                                      <div>                                            
                                                        <GridItem style={{display:"block", justifyContent:"center", marginTop:"5px"}}>
                                                            <Typography className={classes.title2subEmpreParamsInput}><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>
                                                                Tipos de ganhos:
                                                            </Typography>
                                                            <RadioGroup value={valueRadioCapitalDividendo} onChange={handleChangeCapitalDividendo} defaultValue='ganho_de_capital' className={classes.radioRv} row aria-label="Variável" name="row-radio-buttons-group">
                                                                <FormControlLabel value="ganho_de_capital" {...controlPropsRV('ganho_de_capital')} control={<Radio color="success" size="small" />}  label="Ganho de Capital"></FormControlLabel>
                                                                <FormControlLabel value="dividendos"   {...controlPropsRV('dividendos')} control={<Radio color="success" size="small" />}  label="Dividendos"></FormControlLabel>
                                                                <FormControlLabel value="capital_dividendo"   {...controlPropsRV('capital_dividendo')} control={<Radio color="success" size="small" />}  label="Capital + Dividendos"></FormControlLabel>
                                                            </RadioGroup>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </GridItem>
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span><PaidIcon style={{marginRight: "5px", verticalAlign: "middle"}}></PaidIcon>Empresa Escolhida:</span>
                                                          </Typography>
                                                          <div style={{display:"flex", justifyContent:"center", marginBottom:"20px"}} >
                                                          <StyledBadge
                                                                overlap="circular"
                                                                anchorOrigin={{
                                                                  vertical: 'bottom',
                                                                  horizontal: 'right',
                                                                }}
                                                                variant="dot"
                                                                isMarketOpen={isMarketOpen} // Passa a prop para o styled
                                                              ><Avatar
                                                                  src={ticker?.logoUrl} 
                                                                  alt={ticker?.name || 'Logo da Empresa'} 
                                                                  sx={{ width: 56, height: 56 }}
                                                              ></Avatar>
                                                          </StyledBadge>
                                                          </div>
                                                          <div style={{ display:"flex", justifyContent:"center", marginTop:"-10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{ticker?.symbol || 'Ticker não encontrado'} - {ticker?.name || 'Logo da Empresa'}</div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>  
                                                      </div>
                                                          {countUptipoPriceRV}
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div> 
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Nº de Meses</span>
                                                          </Typography>
                                                        
                                                          <div style={{ display:"flex", justifyContent:"center", marginTop:"10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{value51}</div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                      </div>
                                                          {countUptipoRentRV}
                                                      <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div> 
                                                      <div>
                                                          {countUptipoTaxaRV}
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Nº de Ações adquiridas</span>
                                                          </Typography>
                                                        
                                                          <div style={{ display:"flex", justifyContent:"center", marginTop:"10px", fontSize: "0.9rem", lineHeight: "1.25", fontWeight: "500", opacity: "1", textTransform: "none", color: "rgb(85, 85, 85)", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}>{(nacoesCompradas*1).toLocaleString('pt-BR', {minimumFractionDigits:0, maximumFractionDigits:0})}</div>
                                                          <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                      </div>
                                                      <div>
                                                          <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                              <span>Cotação inicial | Cotação final | Variação a.p.</span>
                                                          </Typography>
                                                      </div>
                                                      <div className={classes.calcInvesRend}>
                                                            <Badge color="gray">
                                                              <CountUp end={cotInicial}
                                                                  style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                  formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                  frameStyle={perc => ( {opacity: perc/100} )}
                                                                  decimals={2}
                                                                  decimal=","
                                                              ></CountUp>
                                                            </Badge>
                                                            <Typography style={{padding:"0 10px"}}>|</Typography>
                                                            <Badge color="gray">
                                                              <CountUp end={cotFinal}
                                                                  style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                  formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                  frameStyle={perc => ( {opacity: perc/100} )}
                                                                  decimals={2}
                                                                  decimal=","
                                                              ></CountUp>
                                                            </Badge>
                                                            <Typography style={{padding:"0 10px"}}>|</Typography>
                                                            <Badge color={rendTotalRendaVariavel >= 0 ? "success" : "danger"}>
                                                              <CountUp end={variacaoRV}
                                                                  style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                  formattingFn={v => `${parseFloat(v.toFixed(2)).toLocaleString('pt-BR')}%`} 
                                                                  frameStyle={perc => ( {opacity: perc/100} )}
                                                                  decimals={2}
                                                                  decimal=","
                                                              ></CountUp>
                                                            </Badge>
                                                        </div>
                                                        <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                      
                                                        <div>
                                                            <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                <span>Rendimento no CDI:</span>
                                                            </Typography>
                                                            <div className={classes.calcInvesRend}>
                                                              <Badge color="success">
                                                                <CountUp end={rendCDiRV}
                                                                    style={{ display:"flex", justifyContent:"center", fontSize: "0.9rem", fontWeight: "500", opacity: "1", textTransform: "none", color: "#fff", verticalAlign: "unset", fontFamily: "Roboto Slab, sans-serif", letterSpacing: "-0.125px" }}
                                                                    formattingFn={v => parseFloat(v.toFixed(2)).toLocaleString('pt-BR', { currency:'BRL', style:'currency', minimumFractionDigits:2})}
                                                                    frameStyle={perc => ( {opacity: perc/100} )}
                                                                    decimals={2}
                                                                    decimal=","
                                                                ></CountUp>
                                                              </Badge>
                                                            </div>
                                                            <div style={{borderBottom: "1px solid rgba(153, 153, 153, 0.3)", width: "130px", margin: "auto", marginTop:"10px"}}></div>
                                                        </div>
                                                       
                                                </div>
                                                )}
                                                </div>
                                                )
                                              },
                                              
                                              {
                                                tabButton: (<span><QueryStatsRounded  style={{marginRight: "5px", verticalAlign: "middle"}}></QueryStatsRounded>Gráfico</span> ),
                                                tabContent: (
                                                  <div>
                                                     
                                                  {loading2 ? (
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign:"middle", height:"50vh", width:"100%"}}>
                                                       <CircularProgress color="success" size={50}></CircularProgress>
                                                    </div>
                                                    ) : (
                                                          <div>
                                                              <Typography  style={{display:"flex", justifyContent:"center"}}  className={classes.title2subEmpreParamsInput}>
                                                                  <span><QueryStatsRounded  style={{marginRight: "5px", verticalAlign: "middle"}}></QueryStatsRounded>Gráfico da simulação Olha:</span>
                                                              </Typography>
                                                              <ChartInvestimentosSimulacaoRendaVariavel
                                                                  taxaRetornoMes={taxaRetornoRVLiquidaArray}  // Passa o state
                                                                  taxaRetornoDividendosMes={taxaRetornoRVDividendosLiquidaArray}  // Passa o state
                                                                  value51={value51} 
                                                                  value201={value201}
                                                                  rfResults = {rfResults}
                                                                  //cdiChart={cdiChart}  
                                                                  ticker={ticker}>
                                                              </ChartInvestimentosSimulacaoRendaVariavel>
                                                          </div>
                                                        )}
                                                </div>
                                                
                                                )
                                              },


                                        ]}>
                                      </NavPills>
                                        
                                      
                                
                                      </div>
                                    </GridItem>
                                    </DialogContent>
                                    <DialogActions style={{display: "flex", justifyContent: "space-evenly"}}>
                                        <GridItem className={classes.modalFooterEmpre}>
                                          <Button onClick={() => {simulacaoRendaVariavelRestart(); abraSuaConta()}}  color="success" round>
                                              <span><DoneIcon fontSize="large"  style={{marginRight: "5px", verticalAlign: "middle"}}></DoneIcon> Gostei! Começar a investir!</span>
                                          </Button>
                                          <Button onClick={simulacaoRendaVariavelRestart} color="secondary" round>
                                              <span><RepeatIcon fontSize="large" style={{marginRight: "5px", verticalAlign: "middle"}}></RepeatIcon> Simular novamente</span>
                                          </Button>
                                        </GridItem>
                                    </DialogActions>
                                  </Dialog>

                                </div>
                            </div>

                        </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={6} className={classes.animationHomecarEquity}>
                            <AnimationUpDownInvestomentosRetail>
                            </AnimationUpDownInvestomentosRetail>
                        </GridItem>
                    </GridContainer>
                    <div className={classes.arrowanimatedbuttonsHomeCarEquity}>
                        <ArrowAnimatedButton>
                        </ArrowAnimatedButton>
                    </div>
            </div>
             <div name="" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"700px", marginTop:"-20px"}}>
                <TableInvestRetailTradeView>
                </TableInvestRetailTradeView>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"30px", marginBottom:"80px", display: "flex", justifyContent: "center"}}>
                  
                              <Button  className={classes.botaoSimularConsorcio} onClick={abraSuaConta} color="success" round>
                              ABRA SUA CONTA
                              </Button>
               
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "#fff", minHeight:"700px", marginTop:"-50px"}}>
                <TableRetail>
                </TableRetail>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"30px", marginBottom:"80px", display: "flex", justifyContent: "center"}}>
                            <Button  className={classes.botaoSimularConsorcio} onClick={abraSuaConta} color="success" round>
                            ABRA SUA CONTA
                            </Button>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{background: "rgb(242, 243, 248)", minHeight:"700px", marginTop:"-50px", marginBotton:"50px"}}>
            <TableRetail2>
            </TableRetail2>
            <GridContainer className={classes.container}>
                <GridItem xs={12} sm={12} md={12} style={{marginTop:"30px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                          <Button  className={classes.botaoSimularConsorcio} onClick={abraSuaConta} color="success" round>
                          ABRA SUA CONTA
                          </Button>
                </GridItem>
            </GridContainer>
        </div>

           
           
             <div name="" id="section2" style={{background: "#fff", minHeight:"700px"}}>
                    <CardInvestimentosRetail>
                    </CardInvestimentosRetail>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-5px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                            <Button  className={classes.botaoSimularConsorcio} onClick={abraSuaConta} color="success" round>
                            ABRA SUA CONTA
                            </Button>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="" id="section2" style={{minHeight:"550px", background:"#fff"}}>
                <CartoesProdutosInvestimentos>
                </CartoesProdutosInvestimentos>
            </div>
            <div name="" id="section2" style={{background: "#fff", minHeight:"550px"}}>
                  <ProdutosInvestimentosRetail>
                  </ProdutosInvestimentosRetail>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-18px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                            <Button  className={classes.botaoSimularConsorcio} onClick={abraSuaConta} color="success" round>
                            ABRA SUA CONTA
                            </Button>
                    </GridItem>
                </GridContainer>
            </div>
            <div name="ViddeoArea" id="VideoArea" style={{background: "rgb(242, 243, 248)"}}>
                    <VideoAreaInvestimentos>
                    </VideoAreaInvestimentos>
                <GridContainer className={classes.container}>
                    <GridItem xs={12} sm={12} md={12} style={{marginTop:"-49px", marginBottom:"30px", display: "flex", justifyContent: "center"}}>
                            <Button  className={classes.botaoSimularConsorcio} onClick={abraSuaConta} color="success" round>
                            ABRA SUA CONTA
                            </Button>
                    </GridItem>
                </GridContainer>
            </div>
            <div>
                <GridContainer className={classes.container} style={{minHeight:"517px"}}>
                    <AccordionInvestimentos>
                    </AccordionInvestimentos>
                </GridContainer>
            </div>
            <div name="rodape" id="rodape" >
                <Footer3>
                </Footer3>
            </div>
        </section>

  );
}